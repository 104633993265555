import {
  ButtonDS,
  InputDS,
  PageDS,
  Spacer,
  ToastContainer,
  triggerToast,
} from "@qivia/ui";
import { t } from "i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useAppDispatch } from "../../redux/hooks";
import {
  createTankCapacity,
  selectCreateTankCapacityStatus,
} from "./tankCapacitiesSlice";
import { useSelector } from "react-redux";

export function TankCapacities() {
  const dispatch = useAppDispatch();
  const createTankCapacityStatus = useSelector(selectCreateTankCapacityStatus);
  const [model, setModel] = useState("");
  const [modelVersion, setModelVersion] = useState("");
  const [year, setYear] = useState<number | null>(null);
  const [tankCapacity, setTankCapacity] = useState<number | null>(null);
  const [energy, setEnergy] = useState("");
  const [brand, setBrand] = useState("");
  const [cylinder, setCylinder] = useState<number | null>(null);
  const [vehicleBody, setVehicleBody] = useState("");
  const [displayError, setDisplayError] = useState<boolean>(false);

  const isValidSubmission = useMemo(
    () =>
      model !== "" &&
      modelVersion !== "" &&
      year !== null &&
      year > 1900 &&
      year <= new Date().getFullYear() &&
      tankCapacity !== null &&
      tankCapacity >= 0 &&
      energy !== "" &&
      brand !== "" &&
      cylinder !== null &&
      cylinder >= 0 &&
      vehicleBody !== "",
    [
      model,
      modelVersion,
      year,
      tankCapacity,
      energy,
      brand,
      cylinder,
      vehicleBody,
    ],
  );

  const handleSubmit = useCallback(() => {
    if (!isValidSubmission) {
      setDisplayError(true);
    } else {
      void dispatch(
        createTankCapacity({
          model,
          modelVersion,
          year: year!,
          tankCapacity: tankCapacity!,
          energy,
          brand,
          cylinder: cylinder!,
          vehicleBody,
        }),
      );
    }
  }, [
    dispatch,
    model,
    modelVersion,
    year,
    tankCapacity,
    energy,
    brand,
    cylinder,
    vehicleBody,
    isValidSubmission,
  ]);

  useEffect(() => {
    if (createTankCapacityStatus === "failed") {
      triggerToast(t("tankCapacities.create.error") || "", "error");
    } else if (createTankCapacityStatus === "success") {
      triggerToast(t("tankCapacities.create.success") || "", "valid");
      setModel("");
      setModelVersion("");
      setBrand("");
      setCylinder(null);
      setYear(null);
      setEnergy("");
      setVehicleBody("");
      setTankCapacity(null);
      setDisplayError(false);
    }
  }, [createTankCapacityStatus]);

  return (
    <PageDS
      title={t(`tankCapacities.create.title`)}
      toaster={<ToastContainer />}
    >
      <StyledForm>
        <StyledColums>
          <StyledColumn>
            <InputDS
              label={t("tankCapacities.input.model")}
              placeholder={t("tankCapacities.input.model")}
              value={model}
              onChange={(e) => setModel(e.target.value)}
              error={
                (displayError &&
                  model === "" &&
                  t("tankCapacities.input.emptyString.error")) ||
                undefined
              }
              withoutSpacerBottom
              required
            />
            <Spacer y={1} />
            <InputDS
              label={t("tankCapacities.input.modelVersion")}
              placeholder={t("tankCapacities.input.modelVersion")}
              value={modelVersion}
              onChange={(e) => setModelVersion(e.target.value)}
              error={
                (displayError &&
                  modelVersion === "" &&
                  t("tankCapacities.input.emptyString.error")) ||
                undefined
              }
              withoutSpacerBottom
              required
            />
            <Spacer y={1} />
            <InputDS
              label={t("tankCapacities.input.brand")}
              placeholder={t("tankCapacities.input.brand")}
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
              error={
                (displayError &&
                  brand === "" &&
                  t("tankCapacities.input.emptyString.error")) ||
                undefined
              }
              withoutSpacerBottom
              required
            />
            <Spacer y={1} />
            <InputDS
              label={t("tankCapacities.input.cylinder")}
              placeholder={t("tankCapacities.input.cylinder")}
              type="NUMBER"
              value={cylinder || ""}
              onChange={(e) => setCylinder(parseInt(e.target.value))}
              error={
                (displayError && !cylinder
                  ? t("tankCapacities.input.emptyString.error")
                  : displayError &&
                    cylinder! < 0 &&
                    t("tankCapacities.input.numeric.error")) || undefined
              }
              withoutSpacerBottom
              required
            />
          </StyledColumn>
          <Spacer x={1} />
          <StyledColumn>
            <InputDS
              label={t("tankCapacities.input.year")}
              placeholder={t("tankCapacities.input.year")}
              type="NUMBER"
              value={year || ""}
              onChange={(e) => setYear(parseInt(e.target.value))}
              error={
                (displayError && !year
                  ? t("tankCapacities.input.emptyString.error")
                  : displayError &&
                    (year! <= 1900 || year! > new Date().getFullYear()) &&
                    t("tankCapacities.input.year.error")) || undefined
              }
              withoutSpacerBottom
              required
            />
            <Spacer y={1} />
            <InputDS
              label={t("tankCapacities.input.energy")}
              placeholder={t("tankCapacities.input.energy")}
              value={energy}
              onChange={(e) => setEnergy(e.target.value)}
              error={
                (displayError &&
                  energy === "" &&
                  t("tankCapacities.input.emptyString.error")) ||
                undefined
              }
              withoutSpacerBottom
              required
            />
            <Spacer y={1} />
            <InputDS
              label={t("tankCapacities.input.vehicleBody")}
              placeholder={t("tankCapacities.input.vehicleBody")}
              value={vehicleBody}
              onChange={(e) => setVehicleBody(e.target.value)}
              error={
                (displayError &&
                  vehicleBody === "" &&
                  t("tankCapacities.input.emptyString.error")) ||
                undefined
              }
              withoutSpacerBottom
              required
            />
            <Spacer y={1} />
            <InputDS
              label={t("tankCapacities.input.tankCapacity")}
              placeholder={t("tankCapacities.input.tankCapacity")}
              type="NUMBER"
              value={tankCapacity !== null ? tankCapacity : ""}
              onChange={(e) => setTankCapacity(parseInt(e.target.value))}
              error={
                (displayError && !tankCapacity
                  ? t("tankCapacities.input.emptyString.error")
                  : displayError &&
                    tankCapacity! < 0 &&
                    t("tankCapacities.input.numeric.error")) || undefined
              }
              withoutSpacerBottom
              required
            />
          </StyledColumn>
        </StyledColums>
        <Spacer y={2} />
        <ButtonDS
          format="hug"
          buttonType="primary"
          text={t("tankCapacities.button.create")}
          onClick={handleSubmit}
          disabled={!isValidSubmission}
        />
      </StyledForm>
    </PageDS>
  );
}

const StyledForm = styled.div`
  width: 100%;
`;

const StyledColums = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const StyledColumn = styled.div`
  display: flex;
  flex: 0.5;
  flex-direction: column;
  min-width: 250px;
`;
