import { FunctionComponent, PropsWithChildren } from "react";
import styled, { css } from "styled-components";
import { Spacer } from "../Spacer";

type SectionProps = PropsWithChildren<{
  background?: string;
  height?: string;
  horizontalSpacing?: number;
  verticalSpacing?: number;
}>;

export const Section: FunctionComponent<SectionProps> = (props) => (
  <StyledContainer $backgroundColor={props.background} $height={props.height}>
    <Spacer x={props.horizontalSpacing ?? 1} />
    <StyledContent>
      <Spacer y={props.verticalSpacing ?? 0} />
      {props.children}
      <Spacer y={props.verticalSpacing ?? 0} />
    </StyledContent>
    <Spacer x={props.horizontalSpacing ?? 1} />
  </StyledContainer>
);

const StyledContainer = styled.div<{
  $backgroundColor?: string;
  $height?: string;
}>`
  width: 100%;
  height: ${(props) => props.$height ?? "fit-content"};
  display: flex;
  ${(props) =>
    props.$backgroundColor &&
    css`
      background-color: ${props.$backgroundColor};
    `}
`;

const StyledContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
