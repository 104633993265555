import styled from "styled-components";
import { colors } from "../../styles/figmaColors";
import { Spacer } from "./Spacer";
import { ClearRound } from "../materialUi/materialUi";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { typographies } from "../../styles/figmaTypographies";
import { TextCapitalized } from "./text/TextCapitalized";

export interface DialogModalDSProps {
  children: string | JSX.Element | JSX.Element[];
  visible: boolean;
  title?: string;
  subTitle?: string;
  iconTitle?: JSX.Element;
  onClose: () => void;
  bottomButtons?: {
    leftButton: JSX.Element;
    rightButton: JSX.Element;
  };
  bottomButton?: JSX.Element;
  width?: number;
  deactivateClickOutsideSidePanel?: boolean;
}
export const DialogModalDS = (props: DialogModalDSProps) => {
  const outsideSidePanel = useOutsideClick(() => {
    if (props.deactivateClickOutsideSidePanel) {
      return;
    }
    props.onClose && props.onClose();
  });

  return (
    <StyledBackground $visible={props.visible}>
      <StyledContainer ref={outsideSidePanel} $width={props.width || null}>
        <Spacer x={1.5} />
        <StyledColumn>
          <Spacer y={1.5} />
          <StyledModalBar>
            {props.iconTitle && (
              <>
                <StyledIcon>{props.iconTitle}</StyledIcon>
                <Spacer x={0.75} />
              </>
            )}
            <StyledModalTitle>
              <TextCapitalized>
                <StyledTitle>{props.title}</StyledTitle>
              </TextCapitalized>
              {props.title && <Spacer y={0.25} />}
              <TextCapitalized>
                <StyledSubTitle>{props.subTitle}</StyledSubTitle>
              </TextCapitalized>
            </StyledModalTitle>
            <StyledAroundCloseIcon onClick={props.onClose}>
              <ClearRound />
            </StyledAroundCloseIcon>
          </StyledModalBar>
          {(props.title || props.iconTitle) && <Spacer y={1.5} />}
          <StyledFlex>{props.children}</StyledFlex>
          {props.bottomButtons && (
            <>
              <Spacer y={1.5} />
              <StyledBottom>
                {props.bottomButtons.leftButton}
                <Spacer x={0.5} />
                {props.bottomButtons.rightButton}
              </StyledBottom>
            </>
          )}
          {props.bottomButton && !props.bottomButtons && (
            <>
              <Spacer y={1.5} />
              <StyledBottom>{props.bottomButton}</StyledBottom>
            </>
          )}
          <Spacer y={1.5} />
        </StyledColumn>
        <Spacer x={1.5} />
      </StyledContainer>
    </StyledBackground>
  );
};
const StyledBottom = styled.div`
  width: 100%;
  display: flex;
`;

const StyledModalTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledTitle = styled.div`
  ${typographies["Header/H3"]}
  color: ${colors["colors/text/black"]}
`;
const StyledSubTitle = styled.div`
  ${typographies["Body/S"]}
  color: ${colors["colors/text/darkGrey"]}
`;

const StyledModalBar = styled.div`
  display: flex;
  min-height: 3rem;
`;

const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 3rem;
  height: 3rem;
  background-color: ${colors["colors/surfaces/background/background_level2"]};
  border-radius: 0.75rem;
`;

const StyledAroundCloseIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5rem;
  cursor: pointer;
  min-width: 2.5rem;
  height: 2.5rem;
  &:hover {
    background-color: ${colors["colors/surfaces/background/background_level1"]};
  }
  & svg {
    width: 1.75rem;
    height: 1.75rem;
    fill: ${colors["colors/text/lightGrey"]};
  }
`;

const StyledContainer = styled.div<{ $width: number | null }>`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: ${({ $width }) => ($width ? `${$width}rem` : "30.0625rem")};
  max-width: 80vw;
  max-height: 80vh;
  border-radius: 0.5rem;
  background-color: ${colors["colors/surfaces/background/background_level0"]};
  border: solid 0.063rem ${colors["colors/borders/cards/cards"]};
  overflow: auto;
  box-shadow:
    0px 2px 5px 0px rgba(143, 143, 143, 0.15),
    0px 0px 15px 0px rgba(143, 143, 143, 0.15);
`;

const StyledColumn = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
`;

const StyledBackground = styled.div<{ $visible: boolean }>`
  z-index: 10;
  position: fixed;
  display: ${({ $visible }) => ($visible ? "flex" : "none")};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  backdrop-filter: blur(0.47rem);
  box-shadow:
    0px 0px 15px rgba(143, 143, 143, 0.15),
    0px 2px 5px rgba(143, 143, 143, 0.15);
  overflow: auto;
  background: rgba(150, 150, 150, 0.33);
`;

const StyledFlex = styled.div`
  display: flex;
`;
