import styled from "styled-components";
import { colors } from "../../styles/figmaColors";
import { Spacer } from "./Spacer";
import { Key } from "react";
import { CheckOutlined, MaximizeOutlined } from "../materialUi/materialUi";
import { typographies } from "../../styles/figmaTypographies";
import { TextCapitalized } from "./text/TextCapitalized";

export type CheckBoxStateType = "PARTIALLYCHECKED" | "CHECKED" | "UNCHECKED";

export interface CheckBoxProps {
  text?: string | JSX.Element;
  onClick: () => void;
  keyProp?: Key | null;
  state: CheckBoxStateType;
}

export const CheckBox = (props: CheckBoxProps) => {
  const { text, onClick, keyProp, state } = props;

  return (
    <StyledContainer key={keyProp}>
      <StyledCkeckBoxesInput $state={state} onClick={onClick}>
        {state === "PARTIALLYCHECKED" ? (
          <MaximizeOutlined />
        ) : (
          state === "CHECKED" && <CheckOutlined />
        )}
      </StyledCkeckBoxesInput>
      {text && (
        <>
          <Spacer x={0.5} />
          <StyledText>
            <TextCapitalized>{text}</TextCapitalized>
          </StyledText>
        </>
      )}
    </StyledContainer>
  );
};

interface StyledCheckboxArgs {
  $state: CheckBoxStateType;
}

const StyledText = styled.div`
  ${typographies["Body/S"]};
  color: ${colors["colors/text/black"]};
`;

const getAlignItems = ($state: CheckBoxStateType): string => {
  if ($state === "PARTIALLYCHECKED") {
    return "baseline";
  }
  return "center";
};

const fillColor = ($state: CheckBoxStateType): string => {
  if ($state === "PARTIALLYCHECKED") {
    return colors["colors/text/black"];
  } else if ($state === "CHECKED") {
    return colors["colors/text/white"];
  }
  return "transparent";
};

const getBackgroundColor = ($state: CheckBoxStateType): string => {
  if ($state === "CHECKED") {
    return colors["colors/button/primary/default"];
  }
  return "transparent";
};

const getBorderColor = ($state: CheckBoxStateType): string => {
  if ($state === "CHECKED") {
    return colors["colors/button/primary/default"];
  }
  return colors["colors/borders/button/primary"];
};

const StyledCkeckBoxesInput = styled.div<StyledCheckboxArgs>`
  & svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: ${({ $state }) => fillColor($state)};
  }
  background-color: ${({ $state }) => getBackgroundColor($state)};
  border: 0.125rem solid ${({ $state }) => getBorderColor($state)};
  height: 1.125rem;
  width: 1.125rem;
  display: flex;
  transition: 0.4s ease-out;
  cursor: pointer;
  border-radius: 0.25rem;
  justify-content: center;
  align-items: ${({ $state }) => getAlignItems($state)};
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: end;
`;
