import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { QueryStatus, authAxios } from "../../src/utils";
import { RootState } from "../redux/store";
import { QrCodeApi } from "./qrCodeApi";

export interface qrCodesState {
  qrCodeStatus: QueryStatus;
  qrCodeMultipleCreationStatus: QueryStatus;
  qrCodeUploadStatus: QueryStatus;
  qrCodesListStatus: QueryStatus;
  qrCodesList: QrCodeApi[];
}

const initialState: qrCodesState = {
  qrCodeStatus: "idle",
  qrCodeMultipleCreationStatus: "idle",
  qrCodeUploadStatus: "idle",
  qrCodesListStatus: "idle",
  qrCodesList: [],
};

export const qrCodeAsync = createAsyncThunk("qrCodes/call", async () => {
  const axios = authAxios();
  await axios.post(`qr_code`);
});

export const qrCodesMultipleCreationAsync = createAsyncThunk(
  "multipleQrCodes/call",
  async (payload: { numberToGenerate: number }) => {
    const axios = authAxios();
    await axios.post(`qr_code/multiple_creation/${payload.numberToGenerate}`);
  },
);

export const qrCodesUploadAsync = createAsyncThunk(
  "uploadQrCodes/call",
  async (payload: { numberToUpload: number }) => {
    const axios = authAxios();
    await axios.post(`qr_code/upload/${payload.numberToUpload}`);
  },
);

export const qrCodesListAsync = createAsyncThunk(
  "qrCodesList/call",
  async () => {
    const axios = authAxios();
    const response = await axios.get<Array<QrCodeApi>>(`qr_codes`);
    return response.data;
  },
);

export const qrCodesSlice = createSlice({
  name: "qrCodes",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(qrCodeAsync.pending, (state) => {
        state.qrCodeStatus = "processing";
      })
      .addCase(qrCodeAsync.fulfilled, (state) => {
        state.qrCodeStatus = "success";
        state.qrCodesListStatus = "idle";
      })
      .addCase(qrCodeAsync.rejected, (state) => {
        state.qrCodeStatus = "failed";
      })
      .addCase(qrCodesMultipleCreationAsync.pending, (state) => {
        state.qrCodeMultipleCreationStatus = "processing";
      })
      .addCase(qrCodesMultipleCreationAsync.fulfilled, (state) => {
        state.qrCodeMultipleCreationStatus = "success";
        state.qrCodesListStatus = "idle";
      })
      .addCase(qrCodesMultipleCreationAsync.rejected, (state) => {
        state.qrCodeMultipleCreationStatus = "failed";
      })
      .addCase(qrCodesUploadAsync.pending, (state) => {
        state.qrCodeUploadStatus = "processing";
      })
      .addCase(qrCodesUploadAsync.fulfilled, (state) => {
        state.qrCodeUploadStatus = "success";
        state.qrCodesListStatus = "idle";
      })
      .addCase(qrCodesUploadAsync.rejected, (state) => {
        state.qrCodeUploadStatus = "failed";
      })
      .addCase(qrCodesListAsync.pending, (state) => {
        state.qrCodesListStatus = "processing";
      })
      .addCase(qrCodesListAsync.fulfilled, (state, action) => {
        state.qrCodesList = action.payload;
        state.qrCodesListStatus = "success";
      })
      .addCase(qrCodesListAsync.rejected, (state) => {
        state.qrCodesListStatus = "failed";
      });
  },
});

export const selectQrCodeList = (state: RootState) => state.qrCode.qrCodesList;
export const selectQrCodeListStatus = (state: RootState) =>
  state.qrCode.qrCodesListStatus;
export const selectQrCodeCreationStatus = (state: RootState) =>
  state.qrCode.qrCodeStatus;
export const selectQrCodeMultipleCreationStatus = (state: RootState) =>
  state.qrCode.qrCodeMultipleCreationStatus;
export const selectQrCodeUploadStatus = (state: RootState) =>
  state.qrCode.qrCodeUploadStatus;

export default qrCodesSlice.reducer;
