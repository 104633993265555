import { forwardRef } from "react";
import styled from "styled-components";
import { Input, InputProps } from "./Input";
import { colors } from "../../styles/colors";
import { Spacer } from "../Spacer";

export const PhoneInput = forwardRef<HTMLInputElement, InputProps>(
  ({ label, width, ...props }, ref) => {
    return (
      <StyledPhoneInputContainer>
        {label && <StyledLabel> {label} </StyledLabel>}
        <StyledInputContainer>
          <StyledBackground>
            <StyledPhoneInput>🇫🇷 +33</StyledPhoneInput>
            <Spacer x={0.5} />
          </StyledBackground>
          <Input {...props} ref={ref} width={width} />
        </StyledInputContainer>
      </StyledPhoneInputContainer>
    );
  },
);
const StyledLabel = styled.label`
  display: block;
  font-size: 1rem;
  font-weight: 500;
  font-family: Inter;
  color: ${colors.grey};
  border: solid 4px transparent;
  &::first-letter {
    text-transform: uppercase;
  }
`;

const StyledPhoneInputContainer = styled.div`
  width: 100%;
`;

const StyledPhoneInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 5rem;
  border-radius: 0.5rem;
  border: solid 1px;
  border-color: ${colors.ligthGrey};
  font-size: 1rem;
  color: ${colors.grey};
  background-color: "transparent";
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  height: 3.5rem;
`;

const StyledInputContainer = styled.div`
  display: flex;
`;

const StyledBackground = styled.div`
  display: flex;
  border: solid 0.25rem transparent;
`;
