import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { QueryStatus, authAxios } from "../../../src/utils";
import { RootState } from "../../redux/store";
import { ActiveCardsWithoutRfidList, PublicCardsApi } from "./electricApi";
import { GetCompaniesApi } from "../companiesApi";

export interface ElectricState {
  activeCardWithoutRfidList: PublicCardsApi[];
  activeCardWithoutRfidListStatus: QueryStatus;
  updateCardRfidStatus: QueryStatus;
}

const initialState: ElectricState = {
  activeCardWithoutRfidList: [],
  activeCardWithoutRfidListStatus: "idle",
  updateCardRfidStatus: "idle",
};

export const getActiveCardWithourRfidListAsync = createAsyncThunk(
  "getActiveCardWithoutRfidListAsync/call",
  async (companyList: GetCompaniesApi[]) => {
    const axios = authAxios();
    const response = await axios.get<ActiveCardsWithoutRfidList[]>(
      `/electric/active_cards_without_rfid`,
    );
    return response.data.map((c) => {
      let companyName: string = "";

      if (companyList) {
        companyName =
          companyList.find((company) => company.uuid === c.companyUuid)?.name ??
          "";
      }
      return {
        uuid: c.uuid,
        last4Digits: c.last4Digits,
        status: c.cardStatus,
        vehicleInfo: c.registrationNumber
          ? c.identifier
            ? `${c.registrationNumber} / ${c.identifier}`
            : `${c.registrationNumber}`
          : `${c.identifier}`,
        companyName,
      };
    });
  },
);

export const updateCardRfidAsync = createAsyncThunk(
  "updateCardRfidAsync/call",
  async (payload: { uuid: string; rfid: string }) => {
    const axios = authAxios();
    await axios.post(`/electric/update_card_rfid`, {
      cardUuid: payload.uuid,
      rfid: payload.rfid,
    });
  },
);

export const electricSlice = createSlice({
  name: "electric",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getActiveCardWithourRfidListAsync.pending, (state) => {
        state.activeCardWithoutRfidListStatus = "processing";
      })
      .addCase(getActiveCardWithourRfidListAsync.fulfilled, (state, action) => {
        state.activeCardWithoutRfidList = action.payload;
        state.activeCardWithoutRfidListStatus = "success";
        state.updateCardRfidStatus = "idle";
      })
      .addCase(getActiveCardWithourRfidListAsync.rejected, (state) => {
        state.activeCardWithoutRfidListStatus = "failed";
      })
      .addCase(updateCardRfidAsync.pending, (state) => {
        state.updateCardRfidStatus = "processing";
      })
      .addCase(updateCardRfidAsync.fulfilled, (state) => {
        state.updateCardRfidStatus = "success";
      })
      .addCase(updateCardRfidAsync.rejected, (state) => {
        state.updateCardRfidStatus = "failed";
      });
  },
});

export const selectActiveCardWithoutRfidList = (state: RootState) =>
  state.electric.activeCardWithoutRfidList;
export const selectActiveCardWithoutRfidListStatus = (state: RootState) =>
  state.electric.activeCardWithoutRfidListStatus;
export const selectUpdateCardRfidStatus = (state: RootState) =>
  state.electric.updateCardRfidStatus;

export default electricSlice.reducer;
