import { HTMLAttributes } from "react";
import styled from "styled-components";
import { colors } from "../../styles/figmaColors";

export interface IconCircleProps extends HTMLAttributes<HTMLButtonElement> {
  iconColor?: keyof typeof iconColorsProps;
  icon: JSX.Element;
  backgroundColor?: keyof typeof iconColorsProps;
}

export const IconCircle = (props: IconCircleProps) => {
  const { iconColor, icon, backgroundColor } = props;

  return (
    <StyledContainer $backgroundColor={backgroundColor}>
      {icon && <StyledIcon $iconColor={iconColor}>{icon}</StyledIcon>}
    </StyledContainer>
  );
};

interface StyledContainerArgs {
  $iconColor?: keyof typeof iconColorsProps;
  $backgroundColor?: keyof typeof iconColorsProps;
}

const StyledIcon = styled.div<StyledContainerArgs>`
  display: flex;
  align-items: center;
  fill: ${({ $iconColor }) =>
    $iconColor ? iconColorsProps[$iconColor] : iconColorsProps["textBlack"]};
`;

const StyledContainer = styled.div<StyledContainerArgs>`
  display: flex;
  justify-content: center;
  min-width: 2.75rem;
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 6.2rem;
  background : ${({ $backgroundColor }) =>
    $backgroundColor
      ? iconColorsProps[$backgroundColor]
      : colors["colors/surfaces/background/background_level1"]}};
`;

const iconColorsProps = {
  errorNormal: colors["colors/system/error/error_normal"],
  textBlack: colors["colors/text/black"],
  accent500: colors["colors/accent/500"],
  textWhite: colors["colors/text/white"],
};
