export const colors = {
  "colors/borders/button/primary": "#E4E7EC",
  "colors/borders/cards/cards": "#E4E7EC",
  "colors/borders/cells/cells": "#E4E7EC",
  "colors/text/black": "#101828",
  "colors/text/white": "#FFFFFF",
  "colors/text/darkGrey": "#475467",
  "colors/text/lightGrey": "#98A2B3",
  "colors/text/veryLightGrey": "#D0D5DD",
  "colors/button/primary/default": "#101828",
  "colors/button/primary/hover": "#344054",
  "colors/button/primary/disabled": "#667085",
  "colors/button/secondary/default": "#FFFFFF",
  "colors/button/secondary/hover": "#F9FAFB",
  "colors/button/secondary/disabled": "#FFFFFF",
  "colors/button/tertiary/default": "#FFFFFF00",
  "colors/button/tertiary/hover": "#FFFFFF00",
  "colors/button/tertiary/disabled": "#FFFFFF00",
  "colors/button/avatar/grey": "#859294",
  "colors/surfaces/background/background_level0": "#FFFFFF",
  "colors/surfaces/background/background_level1": "#F0F4F3",
  "colors/surfaces/background/background_level2": "#F2F4F7",
  "colors/button/danger/default": "#F97066",
  "colors/button/danger/hover": "#D92D20",
  "colors/accent/900": "#1A353A",
  "colors/accent/600": "#23B993",
  "colors/accent/500": "#3CD3AD",
  "colors/accent/400": "#63DCBD",
  "colors/accent/300": "#C2E9DD",
  "colors/accent/250": "#D8F6EF",
  "colors/system/error/error_ultraLight": "#FEF3F2",
  "colors/accent/200": "#EBFBF7",
  "colors/accent/100": "#F0F4F3",
  "colors/system/error/error_veryLight": "#FEE4E2",
  "colors/system/error/error_light": "#FDA29B",
  "colors/system/error/error_normal": "#F06359",
  "colors/system/error/error_dark": "#B42318",
  "colors/system/error/error_veryDark": "#7A271A",
  "colors/system/success/success_ultraLight": "#E4F2E7",
  "colors/system/success/success_veryLight": "#D1FADF",
  "colors/system/success/success_light": "#6CE9A6",
  "colors/system/success/success_normal": "#12B76A",
  "colors/system/success/success_dark": "#027A48",
  "colors/system/success/success_veryDark": "#05603A",
  "colors/system/warning/warning_ultraLight": "#FFFAEB",
  "colors/system/warning/warning_veryLight": "#FEF0C7",
  "colors/system/warning/warning_light": "#FEC84B",
  "colors/system/warning/warning_normal": "#F79009",
  "colors/system/warning/warning_dark": "#DC6803",
  "colors/system/warning/warning_veryDark": "#93370D",
  "colors/system/informative/informative_ultraLight": "#EFF8FF",
  "colors/system/informative/informative_veryLight": "#D1E9FF",
  "colors/system/informative/informative_light": "#84CAFF",
  "colors/system/informative/informative_normal": "#2E90FA",
  "colors/system/informative/informative_dark": "#175CD3",
  "colors/system/informative/informative_veryDark": "#194185",
  "colors/borders/separator/cells": "#D0D5DD",
  "colors/borders/input/default": "#E4E7EC",
  "colors/borders/input/hover": "#98A2B3",
  "colors/borders/input/error": "#F06359",
};
