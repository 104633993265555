import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { has1Number, has1SpecialCharacter, has8Char } from "../format";
import { Spacer } from "./Spacer";
import { CheckValidGreen, GreyDot } from "../assets/assets";
import { colors } from "../styles/figmaColors";
import { typographies } from "../styles/figmaTypographies";
import { TextCapitalized } from "./text/TextCapitalized";

export type PasswordInput = {
  password: string;
  passwordDoubleCheck: string;
};

export interface PasswordDoubleCheckProps {
  values: PasswordInput;
}

export const PasswordDoubleCheck = (props: PasswordDoubleCheckProps) => {
  const { t } = useTranslation();

  const isSamePassword = (password: string) => {
    if (password === "" || props.values.passwordDoubleCheck === "")
      return false;
    return password === props.values.passwordDoubleCheck;
  };

  return (
    <div>
      {[
        { fncCheck: has8Char, key: "check8Char" },
        { fncCheck: has1SpecialCharacter, key: "checkSpecialChar" },
        { fncCheck: has1Number, key: "check1num" },
        { fncCheck: isSamePassword, key: "checkSamePasswords" },
      ].map(({ fncCheck, key }) => (
        <StyledDiv key={key}>
          <Spacer y={0.75} />
          {fncCheck(props.values.password) ? (
            <>
              <StyledAssetColor>
                <CheckValidGreen />
              </StyledAssetColor>
              <Spacer x={1} />
              <StyledTextValidPassword>
                <TextCapitalized>
                  {t(`passwordForgotten.resetPassword.${key}`) || ""}
                </TextCapitalized>
              </StyledTextValidPassword>
            </>
          ) : (
            <>
              <GreyDot />
              <Spacer x={1} />
              <StyledTextPassword>
                <TextCapitalized>
                  {t(`passwordForgotten.resetPassword.${key}`) || ""}
                </TextCapitalized>
              </StyledTextPassword>
            </>
          )}
          <Spacer y={1.5} />
        </StyledDiv>
      ))}
      <Spacer y={1} />
    </div>
  );
};

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTextPassword = styled.div`
  text-align: justify;
  color: ${colors["colors/text/darkGrey"]};
  ${typographies["Body/S"]}
`;

const StyledTextValidPassword = styled.div`
  text-align: justify;
  color: ${colors["colors/system/success/success_normal"]};
  ${typographies["Body/S"]}
`;

const StyledAssetColor = styled.div`
  & svg path {
    fill: ${colors["colors/system/success/success_normal"]};
  }
`;
