import { PublicCardsListDisplayedRow } from "./electricApi";
import { updateCardRfidAsync } from "./electricSlice";
import { useAppDispatch } from "../../redux/hooks";
import { useTranslation } from "react-i18next";

import { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { ButtonDS, InputDS, ModalDS, Spacer } from "@qivia/ui";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { colors } from "@qivia/ui/src/styles/figmaColors";

export const CardRfidModal = (props: {
  visible: boolean;
  onCloseModal: () => void;
  selectedRow: Partial<PublicCardsListDisplayedRow> | null;
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { onCloseModal, selectedRow, visible } = props;

  const [rfid, setRfid] = useState<string>("");
  const [displayError, setDisplayError] = useState<boolean>(false);

  const hasHexFormat: boolean = useMemo(() => {
    if (!rfid) return false;
    return !!rfid.match(/^[0-9A-F]{8}$/);
  }, [rfid]);

  const resetModal = useCallback(() => {
    setDisplayError(false);
    setRfid("");
    onCloseModal();
  }, [onCloseModal]);

  const clickToSubmit = useCallback(
    (props: { uuid: string; rfid: string }) => {
      setDisplayError(true);
      if (!hasHexFormat) return;
      void dispatch(updateCardRfidAsync(props));
      resetModal();
    },
    [dispatch, hasHexFormat, resetModal],
  );

  return (
    <ModalDS visible={visible} onClose={() => resetModal()} width={40}>
      {selectedRow ? (
        <StyledColumn>
          <StyledTitle>{t("electric.cardRfid.modal.title")}</StyledTitle>
          <Spacer y={1} />
          **{selectedRow.last4Digits}
          <Spacer y={1} />
          {selectedRow.vehicleInfo}
          <Spacer y={1} />
          {selectedRow.companyName}
          <Spacer y={1} />
          <InputDS
            placeholder={t("electric.cardRfid.modal.placeholder")}
            error={
              displayError && (!hasHexFormat || !rfid)
                ? t("electric.cardRfid.modal.input.error")
                : undefined
            }
            value={rfid}
            onChange={(e) => {
              setRfid(e.target.value);
            }}
            onKeyDown={(e) =>
              e.key === "Enter" &&
              selectedRow.uuid &&
              clickToSubmit({ uuid: selectedRow.uuid, rfid })
            }
          />
          <ButtonDS
            text={t("electric.cardRfid.modal.button")}
            format={"fill"}
            buttonType={"primary"}
            disabled={!hasHexFormat}
            onClick={() => {
              selectedRow.uuid &&
                clickToSubmit({ uuid: selectedRow.uuid, rfid });
            }}
            sizeButton={"L"}
          />
        </StyledColumn>
      ) : (
        <></>
      )}
    </ModalDS>
  );
};

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const StyledTitle = styled.div`
  ${typographies["Header/H1"]}
  color: ${colors["colors/text/black"]};
`;
