import styled from "styled-components";
import { colors } from "../../styles/colors";

export interface SecondaryTitleProps {
  text: string;
}

export const SecondaryTitle = (props: SecondaryTitleProps) => {
  return <StyledSecondaryTitle> {props.text} </StyledSecondaryTitle>;
};

const StyledSecondaryTitle = styled.h1`
  font-size: 1.25rem;
  font-weight: 600;
  font-family: Inter;
  color: ${colors.grey};
  &::first-letter {
    text-transform: uppercase;
  }
`;
