import { toast } from "react-toastify";
import { Toast } from "./components/Toast";

import CheckValidIcon from "./assets/checkValid.svg?react";
import AlertIcon from "./assets/alert.svg?react";

type ToastType = "valid" | "error";
export const triggerToast = (text?: string, type?: ToastType) => {
  if (type === "valid") toast(<Toast text={text} icon={<CheckValidIcon />} />);
  if (type === "error") toast(<Toast text={text} icon={<AlertIcon />} />);
};
