import styled from "styled-components";
import { colors } from "../styles/colors";
import { Spacer } from "./Spacer";
import { useTranslation } from "react-i18next";

export interface YesNoButtonProps {
  onClick: () => void;
  isApproved: boolean;
}

export const YesNoButton = (props: YesNoButtonProps) => {
  const { onClick, isApproved } = props;
  const { t } = useTranslation();

  return (
    <StyledContainer $isApproved={isApproved} onClick={onClick}>
      <StyledButton $isApproved={isApproved}>
        <StyledText $isApproved={isApproved}>{t("yesNoButton.yes")}</StyledText>
      </StyledButton>
      <Spacer x={1} />
      <StyledButton $isApproved={!isApproved}>
        <StyledText $isApproved={!isApproved}>{t("yesNoButton.no")}</StyledText>
      </StyledButton>
    </StyledContainer>
  );
};

interface StyledYesNoButtonArgs {
  $isApproved: boolean;
}

const StyledText = styled.div<StyledYesNoButtonArgs>`
  font-family: Inter;
  font-size: 0.9rem;
  font-weight: 500;
  color: ${({ $isApproved }) => ($isApproved ? colors.white : colors.contentTertiary)};
  &::first-letter {
    text-transform: uppercase;
  }
`;

const StyledButton = styled.div<StyledYesNoButtonArgs>`
  background-color: ${({ $isApproved }) =>
    $isApproved ? colors.primary : "transparent"};
  border-color: ${({ $isApproved }) =>
    $isApproved ? colors.primary : colors.ligthGrey} !important;
  border: 0.1rem solid;
  height: 2rem;
  width: 3rem;
  display: flex;
  align-items: center;
  transition: 0.4s ease-out;
  cursor: pointer;
  justify-content: center;
  border-radius: 0.5rem;
`;

const StyledContainer = styled.div<StyledYesNoButtonArgs>`
  display: flex;
  align-items: center;
  width: 7rem;
  height: 2rem;
`;
