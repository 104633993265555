import styled from "styled-components";
import { colors } from "../../styles/figmaColors";
import { Spacer } from "./Spacer";
import { TextCapitalized } from "./text/TextCapitalized";

interface TabProps {
  tabs: TabElemProps[];
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
}
interface TabElemProps {
  title: string;
  name: string;
}

export const TabMenu = (props: TabProps) => {
  return (
    <>
      <StyledFlex>
        {props.tabs.map((tab, index) => {
          const isSelected = tab.name === props.selectedTab;
          return (
            <div key={"tabMenu" + index}>
              <StyledFlex>
                <StyledTabContainer
                  onClick={() => props.setSelectedTab(tab.name)}
                  key={"tab" + index}
                >
                  <StyledText $isSelected={isSelected}>
                    <TextCapitalized>{tab.title}</TextCapitalized>
                  </StyledText>
                  {isSelected && (
                    <>
                      <Spacer y={0.5} />
                      <StyledTabSeparator />
                    </>
                  )}
                </StyledTabContainer>
                <Spacer x={1} />
              </StyledFlex>
            </div>
          );
        })}
      </StyledFlex>
      <StyledBottomSeparator />
    </>
  );
};

const StyledText = styled.div<{ $isSelected: boolean }>`
  color: ${({ $isSelected }) =>
    $isSelected
      ? colors["colors/text/black"]
      : colors["colors/text/lightGrey"]};
  font-weight: ${({ $isSelected }) => ($isSelected ? 600 : 500)};
  font-family: Inter;
`;

const StyledTabSeparator = styled.div`
  width: 100%;
  align-items: center;
  border-bottom: solid ${colors["colors/text/black"]} 0.125rem;
`;

const StyledBottomSeparator = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 0.0625rem solid ${colors["colors/borders/cells/cells"]};
`;

const StyledTabContainer = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
`;

const StyledFlex = styled.div`
  display: flex;
`;
