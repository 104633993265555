import styled from "styled-components";
import { Spacer } from "../Spacer";
import { ElementButton } from "../../../components/ElementButton";
import { colors } from "../../../styles/figmaColors";
import { TextCapitalized } from "../text/TextCapitalized";
import { typographies } from "../../../styles/figmaTypographies";
import { ImportExport } from "../../materialUi/materialUi";

export interface TableHeaderProps {
  text: string;
  sortAction?: () => void;
  hasSmallWidth?: boolean;
}

export const TableHeaderDS = (props: TableHeaderProps) => {
  return (
    <StyledContainer $hasSmallWidth={props.hasSmallWidth || false}>
      <Spacer y={1} />
      <StyledRow>
        <Spacer x={1} />
        <StyledText>
          <TextCapitalized>{props.text}</TextCapitalized>
        </StyledText>
        <Spacer x={0.5} />
        {props.sortAction && (
          <StyledSort>
            <ElementButton
              onClick={() => props.sortAction && props.sortAction()}
              Element={<ImportExport />}
              colorElement={colors["colors/text/darkGrey"]}
            />
          </StyledSort>
        )}
        <Spacer x={1} />
      </StyledRow>
      <Spacer y={1} />
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{ $hasSmallWidth: boolean }>`
  display: flex;
  flex-direction: column;
  min-width: ${({ $hasSmallWidth }) =>
    $hasSmallWidth ? "4.5rem" : "11.875rem"};
  max-width: ${({ $hasSmallWidth }) => ($hasSmallWidth ? "4.5rem" : "")};
  width: 100%;
  justify-content: center;
`;

const StyledRow = styled.div`
  display: flex;
  height: 1rem;
`;

const StyledSort = styled.div`
  display: flex;
  justify-content: flex-center;
  align-items: flex-start;
  & svg {
    width: 1rem;
    height: 1rem;
    fill: ${colors["colors/text/darkGrey"]};
  }
`;

const StyledText = styled.div`
  display: flex;
  ${typographies["Body/M"]};
  color: ${colors["colors/text/darkGrey"]};
  cursor: default;
`;
