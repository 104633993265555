import styled from "styled-components";
import { ButtonDS, ToastContainer, triggerToast } from "@qivia/ui";
import { useCallback, useState } from "react";
import { authAxios } from "../utils";

export const Dashboard = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isDemoEnvironment = useCallback(
    () => ["demo"].includes(import.meta.env["VITE_TARGET_ENV"] as string),
    [],
  );

  const triggerSeeder = useCallback(async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    triggerToast("Seeding demandé", "valid");
    try {
      await authAxios().post("/seed");
    } catch (e) {
      triggerToast("Echec du chargement des données de démo", "error");
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading]);

  return (
    <StyledContainer>
      <ToastContainer />
      <div>
        DASHBOARD
        {isDemoEnvironment() && (
          <ButtonDS
            format={"hug"}
            buttonType={"danger"}
            text={"charger les données de démo"}
            style={{ marginTop: "1rem", marginLeft: "1rem" }}
            disabled={isLoading}
            onClick={() => void triggerSeeder()}
          />
        )}
      </div>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 2rem 0 0 2rem;
`;
