import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { QueryStatus, authAxios } from "../../src/utils";
import { RootState } from "../redux/store";
import { TermsAndPolicyApi, TermsAndPolicyType } from "./termsAndPolicyApi";
import axios from "axios";

export interface termsAndPoliciesState {
  termsAndConditionsStatus: QueryStatus;
  privacyPoliciesStatus: QueryStatus;
  uploadTermsAndConditionsPresignedUrl: string;
  uploadPrivacyPoliciesPresignedUrl: string;
  termsAndPoliciesListStatus: QueryStatus;
  termsAndPoliciesList: TermsAndPolicyApi[];
  uploadtoS3Status: QueryStatus;
}

const initialState: termsAndPoliciesState = {
  termsAndConditionsStatus: "idle",
  privacyPoliciesStatus: "idle",
  uploadTermsAndConditionsPresignedUrl: "",
  uploadPrivacyPoliciesPresignedUrl: "",
  termsAndPoliciesListStatus: "idle",
  termsAndPoliciesList: [],
  uploadtoS3Status: "idle",
};

export const termsAndConditionsAsync = createAsyncThunk(
  "termsAndConditions/call",
  async (payload: { originalFileName: string }) => {
    const axios = authAxios();
    const response = await axios.post<{ presignedUrl: string }>(
      `terms_and_conditions`,
      payload,
    );
    return response.data;
  },
);

export const privacyPoliciesAsync = createAsyncThunk(
  "privacyPolicies/call",
  async (payload: { originalFileName: string }) => {
    const axios = authAxios();
    const response = await axios.post<{ presignedUrl: string }>(
      `privacy_policies`,
      payload,
    );
    return response.data;
  },
);

export const termsAndPoliciesListAsync = createAsyncThunk(
  "termsAndPoliciesList/call",
  async () => {
    const axios = authAxios();
    const response =
      await axios.get<Array<TermsAndPolicyApi>>(`terms_and_conditions`);
    const response2 =
      await axios.get<Array<TermsAndPolicyApi>>(`privacy_policies`);
    const termsAndConditions = response.data.map((payload) => {
      return { ...payload, type: "TERMS_AND_CONDITIONS" as TermsAndPolicyType };
    });
    const privacyPolicies = response2.data.map((payload) => {
      return { ...payload, type: "PRIVATE_POLICY" as TermsAndPolicyType };
    });
    return [...termsAndConditions, ...privacyPolicies];
  },
);

export const uploadDocumentToS3Async = createAsyncThunk(
  "uploadDocument/uploadToS3",
  async (payload: {
    presignedUrl: string;
    fileContent: string | ArrayBuffer | null;
  }) => {
    const body =
      payload.fileContent && typeof payload.fileContent !== "string"
        ? new Uint8Array(payload.fileContent)
        : payload.fileContent
        ? payload.fileContent
        : "";
    await axios.put(payload.presignedUrl, body);
  },
);

export const termsAndConditionsDownloadAsync = createAsyncThunk(
  "downloadTermsAndPolicies/call",
  async (payload: { uuid: string; fileName: string }, thunkAPI) => {
    const axios = authAxios();
    const response = await axios.post<{ presignedUrl: string }>(
      `terms_and_conditions/${payload.uuid}/get_presigned_url`,
    );
    void thunkAPI.dispatch(
      documentDownloadAsync({
        presignedUrl: response.data.presignedUrl,
        fileName: payload.fileName,
      }),
    );
  },
);

export const privacyPoliciesDownloadAsync = createAsyncThunk(
  "downloadTermsAndPolicies/call",
  async (payload: { uuid: string; fileName: string }, thunkAPI) => {
    const axios = authAxios();
    const response = await axios.post<{ presignedUrl: string }>(
      `privacy_policies/${payload.uuid}/get_presigned_url`,
    );
    void thunkAPI.dispatch(
      documentDownloadAsync({
        presignedUrl: response.data.presignedUrl,
        fileName: payload.fileName,
      }),
    );
  },
);

export const documentDownloadAsync = createAsyncThunk(
  "documentDownload/call",
  async (payload: { presignedUrl: string; fileName: string }) => {
    if (payload.presignedUrl && payload.fileName) {
      const response = await axios({
        url: payload.presignedUrl,
        method: "GET",
        responseType: "blob",
      });
      const a = document.createElement("a");
      a.download = payload.fileName || "yourDocument.pdf";
      const url = window.URL.createObjectURL(new Blob([response.data]));
      a.href = url;
      a.click();
    }
  },
);

export const termsAndPoliciesSlice = createSlice({
  name: "termsAndPolicies",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(termsAndConditionsAsync.pending, (state) => {
        state.termsAndConditionsStatus = "processing";
      })
      .addCase(termsAndConditionsAsync.fulfilled, (state, action) => {
        state.termsAndConditionsStatus = "success";
        state.uploadTermsAndConditionsPresignedUrl =
          action.payload.presignedUrl;
        state.termsAndPoliciesListStatus = "idle";
      })
      .addCase(termsAndConditionsAsync.rejected, (state) => {
        state.termsAndConditionsStatus = "failed";
      })
      .addCase(privacyPoliciesAsync.pending, (state) => {
        state.privacyPoliciesStatus = "processing";
      })
      .addCase(privacyPoliciesAsync.fulfilled, (state, action) => {
        state.privacyPoliciesStatus = "success";
        state.uploadPrivacyPoliciesPresignedUrl = action.payload.presignedUrl;
        state.termsAndPoliciesListStatus = "idle";
      })
      .addCase(privacyPoliciesAsync.rejected, (state) => {
        state.privacyPoliciesStatus = "failed";
      })
      .addCase(termsAndPoliciesListAsync.pending, (state) => {
        state.termsAndPoliciesListStatus = "processing";
      })
      .addCase(termsAndPoliciesListAsync.fulfilled, (state, action) => {
        state.termsAndPoliciesList = action.payload;
        state.termsAndPoliciesListStatus = "success";
      })
      .addCase(termsAndPoliciesListAsync.rejected, (state) => {
        state.termsAndPoliciesListStatus = "failed";
      })
      .addCase(uploadDocumentToS3Async.pending, (state) => {
        state.uploadtoS3Status = "processing";
      })
      .addCase(uploadDocumentToS3Async.fulfilled, (state) => {
        state.uploadtoS3Status = "success";
      })
      .addCase(uploadDocumentToS3Async.rejected, (state) => {
        state.uploadtoS3Status = "failed";
      });
  },
});

export const selectTermsAndPolicyList = (state: RootState) =>
  state.termsAndPolicy.termsAndPoliciesList;
export const selectTermsAndPolicyListStatus = (state: RootState) =>
  state.termsAndPolicy.termsAndPoliciesListStatus;

export const selectTermsAndConditionsPresignedUrlStatus = (state: RootState) =>
  state.termsAndPolicy.uploadTermsAndConditionsPresignedUrl;

export const selectPrivatePolicyPresignedUrlStatus = (state: RootState) =>
  state.termsAndPolicy.uploadPrivacyPoliciesPresignedUrl;

export const selectUploadtoS3StatusStatus = (state: RootState) =>
  state.termsAndPolicy.uploadtoS3Status;

export default termsAndPoliciesSlice.reducer;
