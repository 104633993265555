import styled from "styled-components";
import { colors } from "../styles/colors";

export interface ToggleCheckboxProps {
  check: boolean;
  color?: string;
  disabled?: boolean;
  onClick: () => void;
  size?: sizeOptions;
}
export type sizeOptions = "small" | "medium";

export const ToggleCheckbox = (props: ToggleCheckboxProps) => {
  const { disabled, check, color, onClick, size } = props;
  return (
    <StyledContainer
      $inactive={disabled}
      $check={check}
      $color={color}
      $size={size}
      onClick={disabled ? undefined : onClick}
    >
      <StyledRoundSlider $inactive={disabled} $check={check} $size={size} />
    </StyledContainer>
  );
};

interface StyledCheckboxArgs {
  $inactive?: boolean;
  $check: boolean;
  $color?: string;
  $size?: sizeOptions;
}

const StyledRoundSlider = styled.div<StyledCheckboxArgs>`
  background-color: ${({ $check, $inactive }) =>
    $check
      ? colors.white
      : $inactive
      ? colors.contentTertiary33
      : colors.contentTertiary};
  height: ${({ $check, $size }) =>
    $size && $size == "small"
      ? $check
        ? "1.15rem"
        : "1rem"
      : $check
      ? "1.625rem"
      : "1rem"};
  width: ${({ $check, $size }) =>
    $size == "small"
      ? $check
        ? "1.15rem"
        : "1rem"
      : $check
      ? "1.625rem"
      : "1rem"};
  transform: translateX(
    ${({ $check, $size }) =>
      $size == "small"
        ? $check
          ? "1rem"
          : "0.15rem"
        : $check
        ? "1.75rem"
        : "0.5rem"}
  );
  border-radius: ${({ $check }) => ($check ? "1rem" : "0.5rem")};
  transition: 0.4s ease-out;
`;

const StyledContainer = styled.div<StyledCheckboxArgs>`
  background-color: ${({ $check, $inactive, $color }) =>
    $inactive && $check
      ? $color
        ? $color
        : colors.primary33
      : $check
      ? $color
        ? $color
        : colors.primary
      : colors.white};
  border-color: ${({ $check, $inactive }) =>
    $inactive && !$check
      ? colors.contentTertiary33 + "!important"
      : !$check
      ? colors.contentTertiary + "!important"
      : ""};
  border: 0.125rem solid transparent;
  border-radius: 1rem;
  height: ${({ $size }) => ($size === "small" ? "1.5rem" : "1.75rem")};
  width: ${({ $size }) => ($size === "small" ? "2.5rem" : "3.5rem")};
  display: flex;
  align-items: center;
  transition: 0.4s ease-out;
  cursor: ${({ $inactive }) => ($inactive ? "not-allowed" : "pointer")};
`;
