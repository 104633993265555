import styled from "styled-components";
import { colors } from "../styles/colors";
import { Spacer } from "./Spacer";
import Cross from "../assets/cross.svg?react";
import { useOutsideClick } from "../hooks/useOutsideClick";
interface SidePanelProps {
  children?: string | JSX.Element | JSX.Element[];
  visible: boolean;
  onClose?: () => void;
  stickyBottom?: string | JSX.Element | JSX.Element[];
}

export const SidePanel = (props: SidePanelProps) => {
  const outsideSidePanel = useOutsideClick(() => {
    props.onClose && props.onClose();
  });
  return (
    <StyledContainer ref={outsideSidePanel}>
      <Spacer x={0.5} />
      <StyledColumn
        $visible={props.visible}
        onClick={(event) => event.stopPropagation()}
      >
        <Spacer y={0.5} />
        <StyledContent $visible={props.visible}>
          <Spacer y={1.5} />
          <StyledHeader>
            <Spacer x={1.5} />
            <StyledAroundCloseIcon onClick={props.onClose}>
              <Cross />
            </StyledAroundCloseIcon>
            <Spacer x={1.5} />
          </StyledHeader>
          <StyledBody>
            <Spacer x={2} />
            <StyledChildren>
              <Spacer y={1.5} />
              {props.children}
              <Spacer y={1.5} />
              {props.stickyBottom && <Spacer y={2} />}
            </StyledChildren>
            <Spacer x={2} />
          </StyledBody>
        </StyledContent>
        <Spacer y={0.5} />
        {props.stickyBottom && (
          <StyledBottomButton>
            <StyledAbsolute>
              <Spacer y={1.5} />
              <StyledBody>
                <Spacer x={2} />
                {props.stickyBottom}
                <Spacer x={2} />
              </StyledBody>
              <Spacer y={1.5} />
            </StyledAbsolute>
          </StyledBottomButton>
        )}
      </StyledColumn>
      <Spacer x={0.5} />
    </StyledContainer>
  );
};

const StyledBottomButton = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  background-color: transparent;
`;

const StyledAbsolute = styled.div`
  position: absolute;
  z-index: 1;
  width: 25.875rem;
  background-color: transparent;
`;

const StyledContainer = styled.div`
  z-index: 100;
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  font-size: 0.875rem;
`;

const StyledAroundCloseIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5rem;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  &:hover {
    background-color: ${colors.background};
  }
`;
const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const StyledBody = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
const StyledContent = styled.div<{ $visible: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  background-color: white;
  overflow: auto;
  box-shadow:
    0px 0px 15px rgba(143, 143, 143, 0.15),
    0px 2px 5px rgba(143, 143, 143, 0.15);
  border: solid 1px ${colors.silver};
  border: ${({ $visible }) =>
    $visible ? `solid 1px ${colors.silver}` : "none"};
  backdrop-filter: none;
`;

const StyledColumn = styled.div<{ $visible: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: ${({ $visible }) => ($visible ? "25.875rem" : "0rem")};
  pointer-events: auto;
  transition: all 0.25s ease-out;
`;

const StyledChildren = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
