import styled from "styled-components";
import { colors } from "../styles/colors";

interface WeekdayCirclesProps {
  daysOfWeekEnabled: DaysOfWeekType;
  update: (day: keyof DaysOfWeekType) => void;
}

interface DaysOfWeekType {
  enableMonday: boolean;
  enableTuesday: boolean;
  enableWednesday: boolean;
  enableThursday: boolean;
  enableFriday: boolean;
  enableSaturday: boolean;
  enableSunday: boolean;
}

const weekToLetter = {
  enableMonday: "L",
  enableTuesday: "M",
  enableWednesday: "M",
  enableThursday: "J",
  enableFriday: "V",
  enableSaturday: "S",
  enableSunday: "D",
};
const weekLetter = (day: keyof DaysOfWeekType) => {
  return weekToLetter[day];
};

export const WeekdayCircles = (props: WeekdayCirclesProps) => {
  const { daysOfWeekEnabled } = props;

  return (
    <StyledContainer>
      {Object.keys(daysOfWeekEnabled).map((key, index) => (
        <StyledCircle
          $inactive={props.daysOfWeekEnabled[key as keyof DaysOfWeekType]}
          onClick={() => {
            props.update(key as keyof DaysOfWeekType);
          }}
          key={index}
        >
          {weekLetter(key as keyof DaysOfWeekType)}
        </StyledCircle>
      ))}
    </StyledContainer>
  );
};

interface StyledButtonArgs {
  $inactive?: boolean;
}

const StyledCircle = styled.div<StyledButtonArgs>`
  background-color: ${({ $inactive }) =>
    $inactive ? colors.primary : colors.middleGrey};
  width: 2rem;
  height: 2rem;
  border-radius: 1.25rem;
  color: white;
  line-height: 2rem;
  text-align: center;
  font-family: inter;
  font-size: 1rem;
  cursor: pointer;
  align-self: flex-start;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-self: flex-start;
  width: 17rem;
`;
