import { colors } from "../../styles/figmaColors";
import { Spacer } from "./Spacer";
import { typographies } from "../../styles/figmaTypographies";
import styled, { css } from "styled-components";
import { Cross } from "../../assets/assets";

type sizeOptions = "S" | "L";

const chipsOptions = {
  S: {
    typography: typographies["Body/XXS"],
    spacerx: 0.5,
    spacery: 0.25,
  },
  L: {
    typography: typographies["Body/S"],
    spacerx: 0.75,
    spacery: 0.5,
  },
};

export interface ChipsProps {
  text: string;
  leftElement?: JSX.Element;
  size: sizeOptions;
  onCrossClick?: () => unknown;
}

export const Chips = (props: ChipsProps) => {
  const { text, leftElement, size, onCrossClick } = props;
  const options = chipsOptions[size];

  return (
    <StyledChipsContainer>
      <Spacer y={options.spacery} />
      <StyledText $typography={options.typography}>
        <Spacer x={options.spacerx} />
        {leftElement && (
          <>
            {leftElement}
            <Spacer x={0.5} />
          </>
        )}
        {text}
        {onCrossClick && (
          <>
            <Spacer x={0.5} />
            <StyledDeletedCross onClick={props.onCrossClick}>
              <Cross />
            </StyledDeletedCross>
          </>
        )}
        <Spacer x={options.spacerx} />
      </StyledText>
      <Spacer y={options.spacery} />
    </StyledChipsContainer>
  );
};

const StyledChipsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 25rem;
  color: ${colors["colors/text/black"]};
  border: 0.0625rem solid ${colors["colors/borders/cards/cards"]};
  background-color: ${colors["colors/surfaces/background/background_level0"]};
  width: fit-content;
  cursor: pointer;
`;

const StyledText = styled.div<{ $typography: string }>`
  display: flex;
  ${({ $typography }) => css`
    ${$typography}
  `};
  align-items: center;
`;

const StyledDeletedCross = styled.div`
  & svg {
    width: 0.5rem;
    height: 0.5rem;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  &:hover {
    background: ${colors["colors/surfaces/background/background_level1"]};
  }
`;
