import { FunctionComponent, ReactNode } from "react";
import styled from "styled-components";
import { ActionStripe } from "./ActionStripe";

type SectionedActionStripeProps = {
  left: ReactNode;
  right: ReactNode;
};

export const SectionedActionStripe: FunctionComponent<
  SectionedActionStripeProps
> = (props) => {
  return (
    <ActionStripe>
      <StyledContainer>
        <div>{props.left}</div>
        <div>{props.right}</div>
      </StyledContainer>
    </ActionStripe>
  );
};

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
