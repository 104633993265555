import styled from "styled-components";
import { colors } from "../../styles/figmaColors";
import { TextCapitalized } from "./text/TextCapitalized";
import { typographies } from "../../styles/figmaTypographies";
import { Spacer } from "./Spacer";

interface StepFrameProps {
  children: string | JSX.Element | JSX.Element[] | boolean;
  index: number;
  title: string;
  subTitle: string;
}

export const StepFrame = (props: StepFrameProps) => {
  return (
    <StyledContainer>
      <StyledTitle>
        <StyledIcon>{props.index}</StyledIcon>
        <Spacer x={0.625} />
        <TextCapitalized>{props.title}</TextCapitalized>
      </StyledTitle>
      <Spacer y={0.75} />
      <StyledSubTitle>
        <TextCapitalized>{props.subTitle}</TextCapitalized>
      </StyledSubTitle>
      <Spacer y={1.5} />
      <StyledContent>
        <Spacer x={1} />
        {props.children}
        <Spacer x={1} />
      </StyledContent>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  max-width: 39.5rem;
  width: 100%;
  display: flex;
  padding: 3rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow:
    0px 2px 5px 0px rgba(143, 143, 143, 0.15),
    0px 0px 15px 0px rgba(143, 143, 143, 0.15);
  border-radius: 0.75rem;
  border: 0.0625rem solid transparent;
  background:
    linear-gradient(white, white) padding-box,
    linear-gradient(to left, #3cd3ad, #44b2d5) border-box;
  background-color: ${colors["colors/surfaces/background/background_level0"]};
`;

const StyledTitle = styled.div`
  ${typographies["Body/M"]}
  color: ${colors["colors/text/black"]};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const StyledSubTitle = styled.div`
  ${typographies["Body/XS"]}
  color: ${colors["colors/text/black"]};
`;

const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1.875rem;
  height: 1.875rem;
  background-color: ${colors["colors/accent/100"]};
  border-radius: 0.5rem;
`;

const StyledContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
