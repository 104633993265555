import { HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { Spacer } from "./Spacer";
import { colors } from "../../styles/figmaColors";
import { typographies } from "../../styles/figmaTypographies";
import { TextCapitalized } from "./text/TextCapitalized";

type buttonFormatType = "hug" | "fill";
type buttonStatusType = "default" | "disabled";
type singleIconSizeType = "S" | "L";
type buttonSizeType = "S" | "M" | "L";

interface SingleIconType {
  icon: JSX.Element;
  size: singleIconSizeType;
}

export interface ButtonDSProps extends HTMLAttributes<HTMLButtonElement> {
  text?: string;
  height?: string;
  format: buttonFormatType;
  buttonType: keyof typeof buttonTypes;
  disabled?: boolean;
  leftIcon?: JSX.Element | null;
  rightIcon?: JSX.Element | null;
  iconColor?: keyof typeof iconColors;
  singleIcon?: SingleIconType;
  sizeButton?: buttonSizeType;
}

const sizeButtonProps = {
  S: {
    spacerX: 0.75,
    spacerY: 0.5,
    typography: "Body/M",
    spacerSingleIcon: 0.6875,
  },
  M: {
    spacerX: 0.9375,
    spacerY: 0.9375,
    typography: "Body/M",
    spacerSingleIcon: 0.9375,
  },
  L: {
    spacerX: 1.25,
    spacerY: 1,
    typography: "Header/H3",
    spacerSingleIcon: 0.9375,
  },
};

export const ButtonDS = (props: ButtonDSProps) => {
  const {
    text,
    format,
    rightIcon,
    leftIcon,
    iconColor,
    singleIcon,
    buttonType,
    disabled,
    sizeButton,
    height,
    ...buttonProps
  } = props;

  const status = props.disabled ? "disabled" : ("default" as const);

  const buttonData = sizeButtonProps[sizeButton ?? "M"];
  const spacerSingleIcon =
    sizeButtonProps[singleIcon?.size ?? "M"].spacerSingleIcon;

  const typography: string =
    typographies[buttonData.typography as keyof typeof typographies];

  return (
    <StyledButton
      {...buttonProps}
      $format={format}
      $buttonType={buttonType}
      $disabled={disabled}
      $status={status}
      $typography={typography}
      $singleIcon={!!singleIcon}
      height={height}
    >
      <Spacer y={singleIcon ? spacerSingleIcon : buttonData.spacerY} />
      <StyledFlex>
        {text ? (
          <>
            <Spacer x={buttonData.spacerX} />
            {leftIcon && (
              <>
                <StyledIcon
                  $buttonType={buttonType}
                  $status={status}
                  $iconColor={iconColor}
                >
                  {leftIcon}
                </StyledIcon>
                <Spacer x={0.5} />
              </>
            )}
            <TextCapitalized>{text}</TextCapitalized>
            {rightIcon && (
              <>
                <Spacer x={0.5} />
                <StyledIcon
                  $buttonType={buttonType}
                  $status={status}
                  $iconColor={iconColor}
                >
                  {rightIcon}
                </StyledIcon>
              </>
            )}
            <Spacer x={buttonData.spacerX} />
          </>
        ) : (
          <>
            <Spacer x={spacerSingleIcon} />
            {singleIcon && (
              <StyledIcon
                $buttonType={buttonType}
                $status={status}
                $iconColor={iconColor}
              >
                {singleIcon.icon}
              </StyledIcon>
            )}
            <Spacer x={spacerSingleIcon} />
          </>
        )}
      </StyledFlex>
      <Spacer y={singleIcon ? spacerSingleIcon : buttonData.spacerY} />
    </StyledButton>
  );
};

interface StyledButtonArgs {
  $format: buttonFormatType;
  $buttonType: keyof typeof buttonTypes;
  $disabled: boolean | undefined;
  $status: buttonStatusType;
  $typography: string;
  $singleIcon: boolean;
  height?: string;
}

interface StyledIconArgs {
  $buttonType: keyof typeof buttonTypes;
  $iconColor: keyof typeof iconColors | undefined;
  $status: buttonStatusType;
}

const StyledIcon = styled.div<StyledIconArgs>`
  display: flex;
  & svg {
    width: 1rem;
    height: 1rem;
    fill: ${({ $buttonType, $status, $iconColor }) =>
      $iconColor
        ? iconColors[$iconColor]
        : buttonTypes[$buttonType][$status]["color"]};
  }
`;

const StyledFlex = styled.div`
  display: flex;
`;

const StyledButton = styled.button<StyledButtonArgs>`
  ${({ $typography }) => css`
    ${$typography}
  `};
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => (height ? `${height}rem` : "3rem")};
  padding: 0;
  margin: 0;
  display: block;
  border-radius: 0.5rem;
  border: 0.0625rem solid
    ${({ $buttonType, $status }) => buttonTypes[$buttonType][$status]["border"]};
  box-shadow: ${({ $buttonType }) =>
    $buttonType !== "tertiary"
      ? "2px 1px 4px 0px rgba(122, 122, 122, 0.08)"
      : ""};
  width: ${({ $format, $singleIcon }) =>
    $singleIcon ? "fit-content" : $format === "fill" ? "100%" : "inherit"};
  background-color: ${({ $buttonType, $status }) =>
    buttonTypes[$buttonType][$status]["background"]};
  color: ${({ $buttonType, $status }) =>
    buttonTypes[$buttonType][$status]["color"]};
  &:hover {
    cursor: ${({ $disabled }) => (!$disabled ? "pointer" : "not-allowed")};
    background-color: ${({ $buttonType, $disabled }) =>
      !$disabled ? buttonTypes[$buttonType]["hover"]["background"] : ""};
    border-color: ${({ $buttonType, $disabled }) =>
      !$disabled ? buttonTypes[$buttonType]["hover"]["border"] : ""};
    color: ${({ $buttonType, $disabled }) =>
      !$disabled ? buttonTypes[$buttonType]["hover"]["color"] : ""};
  }
  &:focus {
    outline: none;
  }
`;

const buttonTypes = {
  primary: {
    default: {
      background: colors["colors/button/primary/default"],
      border: colors["colors/button/primary/default"],
      color: colors["colors/text/white"],
    },
    hover: {
      background: colors["colors/button/primary/hover"],
      border: colors["colors/button/primary/hover"],
      color: colors["colors/text/white"],
    },
    disabled: {
      background: colors["colors/button/primary/disabled"],
      border: colors["colors/button/primary/disabled"],
      color: colors["colors/text/lightGrey"],
    },
  },
  secondary: {
    default: {
      background: colors["colors/button/secondary/default"],
      border: colors["colors/borders/button/primary"],
      color: colors["colors/text/black"],
    },
    hover: {
      background: colors["colors/button/secondary/hover"],
      border: colors["colors/borders/button/primary"],
      color: colors["colors/text/black"],
    },
    disabled: {
      background: colors["colors/button/secondary/disabled"],
      border: colors["colors/borders/button/primary"],
      color: colors["colors/text/lightGrey"],
    },
  },
  tertiary: {
    default: {
      background: colors["colors/button/tertiary/default"],
      border: colors["colors/button/tertiary/default"],
      color: colors["colors/text/black"],
    },
    hover: {
      background: colors["colors/accent/100"],
      border: colors["colors/accent/100"],
      color: colors["colors/text/black"],
    },
    disabled: {
      background: colors["colors/button/tertiary/default"],
      border: colors["colors/button/tertiary/default"],
      color: colors["colors/text/lightGrey"],
    },
  },
  danger: {
    default: {
      background: colors["colors/button/danger/default"],
      border: colors["colors/button/danger/default"],
      color: colors["colors/text/white"],
    },
    hover: {
      background: colors["colors/button/danger/hover"],
      border: colors["colors/button/danger/hover"],
      color: colors["colors/text/white"],
    },
    disabled: {
      background: "",
      border: "",
      color: "",
    },
  },
};

const iconColors = {
  errorNormal: colors["colors/system/error/error_normal"],
};
