import styled from "styled-components";
import { typographies } from "../../styles/figmaTypographies";
import { colors } from "../../styles/figmaColors";
import { Spacer } from "./Spacer";
import { ChevronRightOutlined } from "../materialUi/materialUi";
import { TextCapitalized } from "./text/TextCapitalized";

interface StepHeaderData {
  title: string;
  name: string;
}

export interface StepHeaderProps {
  sections: StepHeaderData[];
  selectedSection: string;
  setSelectedSection: (section: string) => void;
}

export const StepHeader = (props: StepHeaderProps) => {
  return (
    <StyledContainer>
      <Spacer y={1.5} />
      {props.sections.map((section, index) => {
        return (
          <>
            <StyledTitle
              $isSelected={section.name === props.selectedSection}
              onClick={() => {
                props.setSelectedSection(section.name);
              }}
            >
              <TextCapitalized>{section.title}</TextCapitalized>
            </StyledTitle>
            <Spacer x={0.5} />
            {index !== props.sections.length - 1 && <ChevronRightOutlined />}
            <Spacer x={0.5} />
          </>
        );
      })}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  & svg {
    fill: ${colors["colors/text/veryLightGrey"]};
    width: 1rem;
    height: 1rem;
  }
`;

const StyledTitle = styled.div<{ $isSelected: boolean }>`
  ${typographies["Body/M"]};
  cursor: pointer;
  color: ${({ $isSelected }) =>
    $isSelected ? colors["colors/accent/600"] : colors["colors/text/darkGrey"]};
`;
