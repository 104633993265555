import styled from "styled-components";
import { colors } from "../../styles/colors";

export interface InitialTitleProps {
  text: string;
  withoutMarginBottom?: boolean;
  withoutMarginTop?: boolean;
  size?: keyof typeof sizes;
}

const sizes = {
  xlarge: { $size: 2.75 },
  large: { $size: 2 },
  small: { $size: 1.5 },
};

interface TitleProps {
  $size: number;
  $withoutMarginBottom?: boolean;
  $withoutMarginTop?: boolean;
}

export const Title = (props: InitialTitleProps) => {
  const { withoutMarginBottom, withoutMarginTop } = props;
  return (
    <StyledTitle
      {...sizes[props.size ? props.size : "small"]}
      $withoutMarginBottom={withoutMarginBottom}
      $withoutMarginTop={withoutMarginTop}
    >
      {props.text}
    </StyledTitle>
  );
};

const StyledTitle = styled.h1<TitleProps>`
  font-size: ${({ $size }) => $size}rem;
  font-weight: 700;
  font-family: Inter;
  color: ${colors.black};
  &::first-letter {
    text-transform: uppercase;
  }
  margin-bottom: ${({ $withoutMarginBottom }) =>
    $withoutMarginBottom ? `0rem` : null};
  margin-top: ${({ $withoutMarginTop }) => ($withoutMarginTop ? `0rem` : null)};
`;
