import styled from "styled-components";

export interface TextCapitalizedProps {
  children: string | string[] | JSX.Element | JSX.Element[] | null;
  onClick?: () => void;
}

export const TextCapitalized = (props: TextCapitalizedProps) => {
  return (
    <StyledTextCapitalized onClick={props.onClick}>
      {props.children}
    </StyledTextCapitalized>
  );
};

const StyledTextCapitalized = styled.div`
  &::first-letter {
    text-transform: uppercase;
  }
`;
