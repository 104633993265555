import styled, { css } from "styled-components";
import { Spacer } from "./Spacer";
import { Tooltip } from "react-tooltip";
import { typographies } from "../../styles/figmaTypographies";
import { TextCapitalized } from "./text/TextCapitalized";
import { colors } from "../../styles/figmaColors";
import { HelpFilled } from "../materialUi/materialUi";

type DirectionType = "top" | "right" | "bottom" | "left";

interface TooltipDSProps {
  id: string;
  place: DirectionType;
  title?: string;
  content: string;
}

export const TooltipDS = (props: TooltipDSProps) => {
  const typoTitle = typographies["Header/H6"];
  const typoContent = typographies["Body/XXS"];

  return (
    <>
      <StyledIcon>
        <HelpFilled data-tooltip-id={props.id} />
      </StyledIcon>
      <StyledContainer id={props.id} place={props.place}>
        <Spacer x={1} />
        <StyledColumn>
          <Spacer y={0.75} />
          <TextCapitalized>
            <StyledTooltipContent typo={typoTitle}>
              {props.title}
            </StyledTooltipContent>
          </TextCapitalized>
          {props.title && <Spacer y={0.5} />}
          <TextCapitalized>
            <StyledTooltipContent typo={typoContent}>
              {props.content}
            </StyledTooltipContent>
          </TextCapitalized>
          <Spacer y={0.75} />
        </StyledColumn>
        <Spacer x={1} />
      </StyledContainer>
    </>
  );
};

interface StyledContentArgs {
  typo: string;
}

const StyledIcon = styled.div`
  display: flex;
  & svg {
    fill: ${colors["colors/accent/500"]};
    outline: none;
  }
`;

const StyledContainer = styled(Tooltip)`
  display: flex;
  flex-direction: row;
  border-radius: 0.5rem !important;
  background-color: ${colors["colors/button/primary/default"]} !important;
  max-width: 17rem;
  z-index: 666;
  padding: 0 !important;
  margin: 0 !important;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTooltipContent = styled.div<StyledContentArgs>`
  ${({ typo }) => css`
    ${typo}
  `};
  color: ${colors["colors/text/white"]} !important;
`;
