import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Spacer, PageDS, ToastContainer, TabMenu } from "@qivia/ui";
import { useNavigate, useParams } from "react-router-dom";
import { TransactionsTab } from "./transactions";
import { DataTab } from "./accountData";
import { RejectedAuthorizationsTab } from "./rejectedAuthorizations";

export const CompaniesData = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [selectedTab, setSelectedTab] = useState<string>(
    params.tab ?? "transactions",
  );

  useEffect(() => {
    if (selectedTab) {
      navigate(`/home/companies_data/${selectedTab}`);
    }
  }, [navigate, selectedTab]);

  const tabs = [
    {
      name: "transactions",
      title: t("companiesData.tab.transactions"),
    },
    {
      name: "accountData",
      title: t("companiesData.tab.accountData"),
    },
    {
      name: "authorizations",
      title: t("companiesData.tab.rejectedAuthorizations"),
    },
  ];

  return (
    <PageDS
      title={t(`companiesData.${params.tab}`)}
      toaster={<ToastContainer />}
      topElement={
        <>
          <Spacer y={1} />
          <TabMenu
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </>
      }
    >
      <TransactionsTab />
      <DataTab />
      <RejectedAuthorizationsTab />
    </PageDS>
  );
};
