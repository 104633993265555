import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../redux/store";
import { jwtDecode } from "jwt-decode";
export interface homeState {
  hasToken: boolean | null;
  adminUser: AdminUserPayload | null;
}

const initialState: homeState = {
  hasToken: null,
  adminUser: null,
};

export interface AdminUserPayload {
  azp: string;
  hd: string;
  email: string;
  email_verified: string;
  at_hash: string;
  iat: number;
  exp: number;
  name: string;
  picture: string;
}

export const HomeSlice = createSlice({
  name: "home",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    getToken: (state) => {
      const token = localStorage.getItem("token");
      if (token) {
        let decodedToken;
        try {
          decodedToken = jwtDecode<AdminUserPayload>(token);
        } catch (e) {
          localStorage.removeItem("token");
          state.hasToken = false;
        } finally {
          if (decodedToken) {
            state.adminUser = decodedToken;
            state.hasToken = true;
          } else state.hasToken = false;
        }
      } else {
        state.hasToken = false;
      }
    },
    removeToken: (state) => {
      localStorage.removeItem("token");
      state.hasToken = false;
      state.adminUser = null;
    },
    registerToken: (state, action: PayloadAction<string>) => {
      state.adminUser = jwtDecode<AdminUserPayload>(action.payload);
      localStorage.setItem("token", action.payload);
      state.hasToken = true;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: () => {},
});

export const { getToken, removeToken, registerToken } = HomeSlice.actions;

export const selectAdminUser = (state: RootState) => state.home.adminUser;
export const selecthasToken = (state: RootState) => state.home.hasToken;

export default HomeSlice.reducer;
