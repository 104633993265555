import { colors } from "../../../styles/figmaColors";
import { useTranslation } from "react-i18next";
import { ButtonDS } from "../Button";
import { Spacer } from "../Spacer";
import { TextCapitalized } from "../text/TextCapitalized";
import { styled } from "styled-components";
import { typographies } from "../../../styles/figmaTypographies";

export const EmptyTableComponent = (props: {
  pageName: string;
  cta?: {
    title: string;
    action: () => void;
    width?: number;
    isDisabled?: boolean;
    leftIcon?: JSX.Element | null;
  };
  cta2?: {
    title: string;
    action: () => void;
    width?: number;
    isDisabled?: boolean;
    leftIcon?: JSX.Element | null;
  };
  isSearchResult?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <StyledEmptyTable>
      <Spacer y={5} />
      {!props.isSearchResult ? (
        <>
          <StyledEmptyTableTitle>
            <TextCapitalized>
              {t(`${props.pageName}.page.empty.title`)}
            </TextCapitalized>
          </StyledEmptyTableTitle>
          <Spacer y={0.5} />
          <StyledEmptyTableBody>
            <TextCapitalized>
              {t(`${props.pageName}.page.empty.text`)}
            </TextCapitalized>
          </StyledEmptyTableBody>
          {(props.cta2 || props.cta) && (
            <>
              <Spacer y={2.5} />
              <StyledButtons>
                {props.cta2 && (
                  <>
                    <ButtonDS
                      onClick={
                        !props.cta2.isDisabled ? props.cta2.action : undefined
                      }
                      text={props.cta2.title}
                      format={"hug"}
                      buttonType={"secondary"}
                      leftIcon={props.cta2.leftIcon}
                      disabled={props.cta2.isDisabled}
                    />
                    <Spacer x={0.75} />
                  </>
                )}
                {props.cta && (
                  <ButtonDS
                    onClick={
                      !props.cta.isDisabled ? props.cta.action : undefined
                    }
                    text={props.cta.title}
                    format={"hug"}
                    buttonType={"primary"}
                    leftIcon={props.cta.leftIcon}
                    disabled={props.cta.isDisabled}
                  />
                )}
              </StyledButtons>
            </>
          )}
        </>
      ) : (
        <StyledEmptyTableTitle>
          <TextCapitalized>
            {t(`${props.pageName}.page.emptySearch.text`)}
          </TextCapitalized>
        </StyledEmptyTableTitle>
      )}
      <Spacer y={4} />
    </StyledEmptyTable>
  );
};

const StyledButtons = styled.div`
  display: flex;
`;

const StyledEmptyTableTitle = styled.div`
  display: flex;
  ${typographies["Header/H1"]};
  text-align: center;
  color: ${colors["colors/text/black"]};
`;

const StyledEmptyTable = styled.div`
  display: flex;
  cursor: default;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const StyledEmptyTableBody = styled.div`
  display: flex;
  ${typographies["Paragraph/P1"]};
  text-align: center;
  color: ${colors["colors/text/black"]};
`;
