import styled from "styled-components";
import { Spacer } from "../Spacer";
import {
  DriverAppCanceledLogo,
  DriverAppExpiredLogo,
  DriverAppSuccessLogo,
} from "../../../assets/assets";
import { colors } from "../../../styles/figmaColors";
import { TextCapitalized } from "../text/TextCapitalized";
import { typographies } from "../../../styles/figmaTypographies";
import { ButtonDS } from "../Button";

type PageType = "SUCCESS" | "EXPIRED" | "LOADING" | "CANCELED";

interface PageProps {
  title?: string;
  subTitle?: string;
  type: PageType;
  cta?: {
    title: string;
    action: () => void;
    width?: number;
    leftIcon?: JSX.Element | null;
  };
}

const logoTypeProps = {
  SUCCESS: {
    logo: <DriverAppSuccessLogo />,
  },
  EXPIRED: {
    logo: <DriverAppExpiredLogo />,
  },
  LOADING: {
    logo: <div className="dot-spin" />,
  },
  CANCELED: {
    logo: <DriverAppCanceledLogo />,
  },
};

export const DriverLogoPage = (props: PageProps) => {
  const logo = logoTypeProps[props.type].logo;
  return (
    <StyledPage>
      <Spacer y={1.5} />
      {logo}
      {props.title && (
        <>
          <Spacer y={1.5} />
          <StyledTitle>
            <Spacer x={1.5} />
            <TextCapitalized>{props.title}</TextCapitalized>
            <Spacer x={1.5} />
          </StyledTitle>
        </>
      )}
      {props.subTitle && (
        <>
          <Spacer y={1} />
          <StyledBody>
            <Spacer x={1.5} />
            <TextCapitalized>{props.subTitle}</TextCapitalized>
            <Spacer x={1.5} />
          </StyledBody>
        </>
      )}
      {props.cta && (
        <>
          <Spacer y={1.5} />
          <div>
            <ButtonDS
              onClick={props.cta.action}
              text={props.cta.title}
              format={"hug"}
              buttonType={"secondary"}
              leftIcon={props.cta.leftIcon}
            />
          </div>
        </>
      )}
      <Spacer y={1.5} />
    </StyledPage>
  );
};

const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: ${colors["colors/surfaces/background/background_level0"]};
  .dot-spin {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: transparent;
    color: transparent;
    box-shadow:
      0 -18px 0 0 #63d195,
      12.727926px -12.727926px 0 0 #63d195,
      18px 0 0 0 #63d195,
      12.727926px 12.727926px 0 0 #efe74e,
      0 18px 0 0 #efe74e,
      -12.727926px 12.727926px 0 0 #efe74e,
      -18px 0 0 0 #efe74e,
      -12.727926px -12.727926px 0 0 #efe74e;
    animation: dot-spin 1.5s infinite linear;
  }

  @keyframes dot-spin {
    0%,
    100% {
      box-shadow:
        0 -18px 0 0 #63d195,
        12.727926px -12.727926px 0 0 #63d195,
        18px 0 0 0 #63d195,
        12.727926px 12.727926px 0 -5px #efe74e,
        0 18px 0 -5px #efe74e,
        -12.727926px 12.727926px 0 -5px #efe74e,
        -18px 0 0 -5px #efe74e,
        -12.727926px -12.727926px 0 -5px #efe74e;
    }
    12.5% {
      box-shadow:
        0 -18px 0 -5px #efe74e,
        12.727926px -12.727926px 0 0 #63d195,
        18px 0 0 0 #63d195,
        12.727926px 12.727926px 0 0 #63d195,
        0 18px 0 -5px #efe74e,
        -12.727926px 12.727926px 0 -5px #efe74e,
        -18px 0 0 -5px #efe74e,
        -12.727926px -12.727926px 0 -5px #efe74e;
    }
    25% {
      box-shadow:
        0 -18px 0 -5px #efe74e,
        12.727926px -12.727926px 0 -5px #efe74e,
        18px 0 0 0 #63d195,
        12.727926px 12.727926px 0 0 #63d195,
        0 18px 0 0 #63d195,
        -12.727926px 12.727926px 0 -5px #efe74e,
        -18px 0 0 -5px #efe74e,
        -12.727926px -12.727926px 0 -5px #efe74e;
    }
    37.5% {
      box-shadow:
        0 -18px 0 -5px #efe74e,
        12.727926px -12.727926px 0 -5px #efe74e,
        18px 0 0 -5px #efe74e,
        12.727926px 12.727926px 0 0 #63d195,
        0 18px 0 0 #63d195,
        -12.727926px 12.727926px 0 0 #63d195,
        -18px 0 0 -5px #efe74e,
        -12.727926px -12.727926px 0 -5px #efe74e;
    }
    50% {
      box-shadow:
        0 -18px 0 -5px #efe74e,
        12.727926px -12.727926px 0 -5px #efe74e,
        18px 0 0 -5px #efe74e,
        12.727926px 12.727926px 0 -5px #efe74e,
        0 18px 0 0 #63d195,
        -12.727926px 12.727926px 0 0 #63d195,
        -18px 0 0 0 #63d195,
        -12.727926px -12.727926px 0 -5px #efe74e;
    }
    62.5% {
      box-shadow:
        0 -18px 0 -5px #efe74e,
        12.727926px -12.727926px 0 -5px #efe74e,
        18px 0 0 -5px #efe74e,
        12.727926px 12.727926px 0 -5px #efe74e,
        0 18px 0 -5px #efe74e,
        -12.727926px 12.727926px 0 0 #63d195,
        -18px 0 0 0 #63d195,
        -12.727926px -12.727926px 0 0 #63d195;
    }
    75% {
      box-shadow:
        0 -18px 0 0 #63d195,
        12.727926px -12.727926px 0 -5px #efe74e,
        18px 0 0 -5px #efe74e,
        12.727926px 12.727926px 0 -5px #efe74e,
        0 18px 0 -5px #efe74e,
        -12.727926px 12.727926px 0 -5px #efe74e,
        -18px 0 0 0 #63d195,
        -12.727926px -12.727926px 0 0 #63d195;
    }
    87.5% {
      box-shadow:
        0 -18px 0 0 #63d195,
        12.727926px -12.727926px 0 0 #63d195,
        18px 0 0 -5px #efe74e,
        12.727926px 12.727926px 0 -5px #efe74e,
        0 18px 0 -5px #efe74e,
        -12.727926px 12.727926px 0 -5px #efe74e,
        -18px 0 0 -5px #efe74e,
        -12.727926px -12.727926px 0 0 #63d195;
    }
  }
`;

const StyledTitle = styled.div`
  line-heigth: 3rem;
  font-size: 2rem;
  font-family: Inter;
  font-weight: 600;
  display: flex;
  text-align: center;
`; //no typo

const StyledBody = styled.div`
  ${typographies["Paragraph/P1"]};
  display: flex;
  text-align: center;
  color: ${colors["colors/text/darkGrey"]};
`;
