import styled from "styled-components";
import { typographies } from "../../styles/figmaTypographies";
import { TextCapitalized } from "./text/TextCapitalized";
import { colors } from "../../styles/figmaColors";
import { Spacer } from "./Spacer";
import { StepStatus } from "./StepStatus";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

interface StepStatesProps {
  title: string;
  text: string;
  selectedStep: number;
  numberOfSteps: number;
}

export const StepStates = (props: StepStatesProps) => {
  const { t } = useTranslation();

  const computeStatus = useCallback(
    (step: number) => {
      if (props.selectedStep === step) return "CURRENT";
      if (props.selectedStep < step) return "TODO";
      return "DONE";
    },
    [props],
  );

  const steps = [];
  for (let i = 1; i <= props.numberOfSteps; i++) {
    steps.push(
      <>
        {i > 1 && <Spacer x={2.875} />}
        <StepStatus
          key={i}
          type={computeStatus(i)}
          index={i}
          text={t(props.text + `${i}`)}
        />
      </>,
    );
  }

  return (
    <StyledContainer>
      <TextCapitalized>{props.title}</TextCapitalized>
      <Spacer y={1.5} />
      <StyledStepData>{steps}</StyledStepData>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${typographies["Header/H3"]};
  color: ${colors["colors/text/black"]};
  width: 100%;
`;

const StyledStepData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
