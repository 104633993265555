import { FunctionComponent, PropsWithChildren } from "react";
import styled from "styled-components";
import { Spacer } from "../Spacer";

type ActionStripeProps = PropsWithChildren;

export const ActionStripe: FunctionComponent<ActionStripeProps> = (props) => (
  <StyledVerticalContainer>
    <Spacer y={1.5} />
    <StyledHorizontalContainer>
      <Spacer x={1} />
      {props.children}
      <Spacer x={1} />
    </StyledHorizontalContainer>
    <Spacer y={1.5} />
  </StyledVerticalContainer>
);

const StyledHorizontalContainer = styled.div`
  height: fit-content;
  display: flex;
`;

const StyledVerticalContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
`;
