import styled from "styled-components";
import { colors } from "../../styles/colors";
import { Spacer } from "../Spacer";
import { ReactNode } from "react";

export interface TabElemProps {
  icon?: ReactNode;
  text: string;
  isSelected: boolean;
  onClick: () => void;
}

interface ElemProps {
  $color: string;
  $fontWeight: number;
}

export const TabElem = (props: TabElemProps) => {
  const elemProps = props.isSelected
    ? {
        $color: colors.black,
        $fontWeight: 600,
      }
    : {
        $color: colors.contentTertiary,
        $fontWeight: 500,
      };

  return (
    <>
      <StyledContainer onClick={props.onClick}>
        <StyledTab>
          <StyledIconContainer {...elemProps}>{props.icon}</StyledIconContainer>
          {props.icon && <Spacer x={0.25} />}
          <StyledText {...elemProps}>{props.text}</StyledText>
        </StyledTab>
        <Spacer y={0.5} />
        {props.isSelected && (
          <>
            <StyledSeparator />
          </>
        )}
      </StyledContainer>
    </>
  );
};

const StyledSeparator = styled.div`
  display: inline-block;
  width: 100%;
  align-items: center;
  border-bottom: solid #333333 2px;
`;

const StyledTab = styled.div`
  display: flex;
  align-items: center;
`;

const StyledContainer = styled.div`
  display: flex;
  max-width: fit-content;
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;
`;

const StyledIconContainer = styled.div<ElemProps>`
  font-weight: medium;
  & svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  & svg path {
    fill: ${({ $color }) => $color};
  }
`;

const StyledText = styled.div<ElemProps>`
  color: ${({ $color }) => $color};
  font-size: 14;
  font-weight: ${({ $fontWeight }) => $fontWeight};
  font-family: Inter;
  &::first-letter {
    text-transform: uppercase;
  }
`;
