import {
  ButtonDS,
  InputDS,
  SelectDS,
  Spacer,
  TextCapitalized,
  triggerToast,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import styled from "styled-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  companiesListAsync,
  selectCompaniesList,
  selectCompaniesListStatus,
} from "../companiesSlice";
import {
  driverListAsync,
  selectDriversList,
  selectDriversListStatus,
  selectUpdateDriverPhoneStatus,
  updateDriverPhoneAsync,
} from "./driversSlice";
import { useParams } from "react-router-dom";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";

export const UpdatePhoneDriverTab = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();

  const [newDriverLastName, setNewDriverLastName] = useState<string>("");
  const [newDriverFirstName, setNewDriverFirstName] = useState<string>("");
  const companiesList = useAppSelector(selectCompaniesList);
  const companiesListStatus = useAppSelector(selectCompaniesListStatus);
  const driversList = useAppSelector(selectDriversList);
  const driversListStatus = useAppSelector(selectDriversListStatus);
  const updateDriverStatus = useAppSelector(selectUpdateDriverPhoneStatus);

  const [companySelected, setCompanySelected] = useState<string>("");
  const [driverSelected, setDriverSelected] = useState<string>("");
  const [displayError, setDisplayError] = useState<boolean>(false);

  const isValidSubmission = useMemo(
    () =>
      companySelected !== "" &&
      driverSelected !== "" &&
      newDriverFirstName !== "" &&
      newDriverLastName !== "",
    [driverSelected, companySelected, newDriverFirstName, newDriverLastName],
  );

  const optionCompany = useMemo(
    () =>
      companiesList.map((company) => {
        return {
          label: company.name,
          value: company.uuid,
        };
      }),
    [companiesList],
  );

  const valueOptionCompany = useMemo(
    () => optionCompany.find((option) => option.value === companySelected),
    [companySelected, optionCompany],
  );

  const optionDriver = useMemo(
    () =>
      driversList.map((driver) => ({
        label: `${driver.lastName} ${driver.firstName} / ${driver.phone}`,
        value: driver.uuid,
      })),
    [driversList],
  );

  const valueOptionDriver = useMemo(
    () => optionDriver.find((option) => option.value === driverSelected),
    [driverSelected, optionDriver],
  );

  useEffect(() => {
    if (updateDriverStatus === "success") {
      triggerToast(t("drivers.update.success") || "", "valid");
    } else if (updateDriverStatus === "failed") {
      triggerToast(t("drivers.update.failure") || "", "error");
    }
  }, [updateDriverStatus, t]);

  useEffect(() => {
    if (companiesListStatus === "idle") {
      void dispatch(companiesListAsync());
    }
  }, [companiesListStatus, dispatch]);

  useEffect(() => {
    if (companySelected && driversListStatus === "idle") {
      void dispatch(driverListAsync({ companyUuid: companySelected }));
    }
  }, [companySelected, dispatch, driversListStatus]);

  const clickToSubmit = useCallback(() => {
    if (!isValidSubmission) {
      setDisplayError(true);
    } else {
      setDisplayError(false);
      void dispatch(
        updateDriverPhoneAsync({
          oldDriver: driverSelected,
          newDriverFirstName,
          newDriverLastName,
        }),
      );
      setNewDriverLastName("");
      setNewDriverFirstName("");
      setDriverSelected("");
    }
  }, [
    driverSelected,
    newDriverFirstName,
    newDriverLastName,
    dispatch,
    isValidSubmission,
  ]);

  if (params.tab !== "updatePhone") {
    return;
  }

  return (
    <StyledContainer>
      <Spacer y={1} />
      <StyledHeader>
        <SelectDS
          label={t("drivers.select.company")}
          value={valueOptionCompany}
          options={optionCompany}
          onChange={(selectedOption) =>
            setCompanySelected(selectedOption ? selectedOption.value : "")
          }
          error={
            (displayError &&
              companySelected === "" &&
              t("drivers.select.company.error")) ||
            undefined
          }
          allWidth
        />
        {companySelected !== "" && (
          <>
            <SelectDS
              label={t("drivers.select.driver")}
              value={valueOptionDriver}
              options={optionDriver}
              onChange={(selectedOption) =>
                setDriverSelected(selectedOption ? selectedOption.value : "")
              }
              error={
                (displayError &&
                  driverSelected === "" &&
                  t("drivers.select.company.error")) ||
                undefined
              }
              allWidth
            />
          </>
        )}
      </StyledHeader>
      <Spacer y={3} />
      {companySelected && driverSelected && (
        <>
          <StyledTitle>
            <TextCapitalized>{t("drivers.title.newDriver")}</TextCapitalized>
          </StyledTitle>
          <InputDS
            label={t("drivers.input.lastName")}
            placeholder={t("drivers.input.lastName")}
            value={newDriverLastName}
            onChange={(e) => setNewDriverLastName(e.target.value)}
            withoutSpacerBottom
          />
          <Spacer y={1} />
          <InputDS
            label={t("drivers.input.firstName")}
            placeholder={t("drivers.input.firstName")}
            value={newDriverFirstName}
            onChange={(e) => setNewDriverFirstName(e.target.value)}
            withoutSpacerBottom
          />
          <Spacer y={2} />
          <ButtonDS
            format="hug"
            buttonType="primary"
            text={t("drivers.button.update")}
            onClick={clickToSubmit}
            disabled={!isValidSubmission}
          />
        </>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 30rem;
`;

const StyledTitle = styled.div`
  display: flex;
  flex-direction: row;
  ${typographies["Header/H2"]};
  align-items: center;
  justify-content: space-between;
  min-height: 3rem;
  width: 100%;
`;
