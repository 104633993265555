import styled from "styled-components";
import { Spacer } from "./Spacer";
import { SelectDS } from "./Select";

interface SelectFuelProps {
  onChange: (value: string | null) => void;
  value: string | null;
  disabled?: boolean;
  error?: boolean;
}

interface Option<T extends string> {
  readonly value: T;
  readonly label: string;
  readonly disabled?: boolean;
}

export const SelectFuel = (props: SelectFuelProps) => {
  const handleChange = (selectedOption: Option<string> | null) => {
    props.onChange(selectedOption?.value ?? null);
  };

  const options: Option<string>[] = [];
  [
    "SP95",
    "SP95_E10",
    "SP98",
    "Diesel_B7",
    "Diesel_B10",
    "Diesel_B30",
    "GPL",
    "Electric",
    "E85",
    "ED95",
    "GNV",
    "GNC",
    "GNL",
    "AdBlue",
  ].map((fuel) => {
    options.push({ value: fuel, label: fuel });
  });
  const valueOption = options.find((option) => option.value === props.value);

  return (
    <SelectContainer>
      <SelectDS
        onChange={handleChange}
        options={options}
        value={valueOption}
        disabled={props.disabled}
        label=""
        withoutErrorMessage
        error={props.error ? "true" : ""}
        allWidth={true}
      />
      <Spacer y={1.5} />
    </SelectContainer>
  );
};

const SelectContainer = styled.div`
  width: 100%;
  height: 2.5rem;
`;
