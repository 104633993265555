import { colors } from "../../styles/colors";
import styled from "styled-components";
import { Spacer } from "../Spacer";
import { useTranslation } from "react-i18next";

type Status = "ACCEPTED" | "REJECTED" | "SUSPICIOUS" | "NO_SUPPORTING_DOCUMENT";

const statusProps = {
  ACCEPTED: {
    backgroundColor: colors.lightGreen,
  },
  REJECTED: {
    backgroundColor: colors.paleRed,
  },
  SUSPICIOUS: {
    backgroundColor: colors.paleOrange,
  },
  NO_SUPPORTING_DOCUMENT: {
    backgroundColor: colors.paleOrange,
  },
};

interface categoryLabelProps {
  status: Status;
}
export const TableLabelTransaction = (props: categoryLabelProps) => {
  const vehicleStatus = statusProps[props.status];
  const { t } = useTranslation();

  return (
    <StyledContainer $backgroundColor={vehicleStatus.backgroundColor}>
      <Spacer x={0.5} />
      <StyledTextContainer>
        {t(`transactions.status.${props.status === "NO_SUPPORTING_DOCUMENT" ? "noSupportingDocument" : props.status.toLowerCase()}`)}
      </StyledTextContainer>
      <Spacer x={0.5} />
    </StyledContainer>
  );
};

const StyledTextContainer = styled.div`
  font-family: Inter;
  font-size: 1rem;
  height: 1.5rem;
  line-height: 1.5rem;
  &::first-letter {
    text-transform: uppercase;
  }
  cursor: default;
`;

const StyledContainer = styled.div<{ $backgroundColor: string }>`
  display: flex;
  border-radius: 0.5rem;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  height: 1.7rem;
  align-items: center;
  width: max-content;
`;
