import { FunctionComponent, ReactNode } from "react";
import styled from "styled-components";

type SectionRowProps = {
  label: ReactNode;
  value: ReactNode;
};

export const SectionRow: FunctionComponent<SectionRowProps> = (props) => {
  return (
    <StyledRow>
      <div>{props.label}</div>
      <div>{props.value}</div>
    </StyledRow>
  );
};

const StyledRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
