import styled from "styled-components";

interface SpacerProps {
  x?: number;
  y?: number;
}

export const Spacer = (props: SpacerProps) => (
  <StyledDiv {...props}></StyledDiv>
);

const StyledDiv = styled.div<SpacerProps>`
  width: ${({ x }) => (x ? x.toString() : "0")}rem;
  height: ${({ y }) => (y ? y.toString() : "0")}rem;
  flex-shrink: 0;
`;
