import { Spacer, ToastContainer, PageDS, TabMenu } from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CardRfidTab } from "./cardRfidTab";

export const Electric = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const params = useParams();
  const [selectedTab, setSelectedTab] = useState<string>(
    params.tab ?? "card_rfid",
  );

  const tabs = [
    {
      name: "card_rfid",
      title: t("electric.tab.cardRfid"),
    },
  ];

  useEffect(() => {
    if (selectedTab) {
      navigate(`/home/electric/${selectedTab}`);
    }
  }, [navigate, selectedTab]);

  return (
    <PageDS
      title={t("electric.title")}
      toaster={<ToastContainer />}
      topElement={
        <>
          <Spacer y={1} />
          <TabMenu
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </>
      }
    >
      <Spacer y={1} />
      <CardRfidTab />
    </PageDS>
  );
};
