import styled from "styled-components";
import { colors } from "../../styles/colors";
import { FormEvent } from "react";
import { capitalize } from "../../format";
import { Spacer } from "../Spacer";

export interface TitleInputProps {
  value: string;
  error?: string;
  onChange: (e: string) => void;
  placeholder?: string;
}

export const TitleInput = (props: TitleInputProps) => {
  const change = (e: FormEvent<HTMLInputElement>) => {
    const text = (e.target as HTMLInputElement).value;

    return props.onChange(text);
  };
  return (
    <StyledTitleInputContainer>
      <StyledContainer>
        <StyledTitleInput
          placeholder={props.placeholder && capitalize(props.placeholder)}
          value={props.value}
          onChange={change}
        />
      </StyledContainer>
      {props.error && (
        <>
          <StyledLine />
          <Spacer y={0.25} />
          <StyledError>{props.error}</StyledError>
        </>
      )}
    </StyledTitleInputContainer>
  );
};

const StyledTitleInput = styled.input`
  display: block;
  width: 100%;
  color: ${colors.black};
  background-color: transparent;
  &:focus {
    outline: none;
  }
  border: 0px;
  border-radius: 0.5rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 3.3rem;
  font-size: 1rem;
  color: ${colors.grey};
  background-color: transparent;
`;

const StyledError = styled.div`
  color: ${colors.alertMessage};
  width: 20rem;
  font-size: 1rem;
  &::first-letter {
    text-transform: uppercase;
  }
  height: 100%;
`;

const StyledTitleInputContainer = styled.div`
  width: 100%;
`;

const StyledLine = styled.div`
  width: 20rem;
  border-bottom: 1px solid ${colors.alertMessage};
`;
