import {
  GridRenderCellParams,
  GridValidRowModel,
  GridTreeNodeWithRender,
  GridColumnTypes,
} from "@mui/x-data-grid";
import { GRID_AGGREGATION_ROOT_FOOTER_ROW_ID } from "@mui/x-data-grid-premium";
import styled from "styled-components";
import { ReactNode } from "react";
import { colors } from "../../../styles/figmaColors";
import { typographies } from "../../../styles/figmaTypographies";

const hasBadNumberValue = (value: number) => {
  return !value || isNaN(value);
};

export const renderCellWithLabel = (
  params: GridRenderCellParams<
    GridValidRowModel,
    unknown,
    unknown,
    GridTreeNodeWithRender
  >,
  label: string,
  formattedValue?: JSX.Element,
) => {
  return params.id !== GRID_AGGREGATION_ROOT_FOOTER_ROW_ID ? (
    <>{formattedValue ?? params.formattedValue}</>
  ) : (
    <StyledColumn>
      <StyledValue>
        {label === "Moyenne" && !hasBadNumberValue(Number(params.value))
          ? Number(params.value).toFixed(2)
          : label !== "Moyenne" && params.value
            ? (params.value as string).toString()
            : ""}
      </StyledValue>
      <StyledLabel>
        {(label === "Moyenne" && !hasBadNumberValue(Number(params.value))) ||
        (label !== "Moyenne" && params.value)
          ? label
          : ""}
      </StyledLabel>
    </StyledColumn>
  );
};

export const defaultRenderCell: {
  [index in keyof Partial<GridColumnTypes>]: {
    renderCell: (
      params: GridRenderCellParams<
        GridValidRowModel,
        unknown,
        unknown,
        GridTreeNodeWithRender
      >,
    ) => ReactNode;
  };
} = {
  date: {
    renderCell: (p) =>
      p.id !== GRID_AGGREGATION_ROOT_FOOTER_ROW_ID && <>{p.formattedValue}</>,
  },
  number: {
    renderCell: (p) => renderCellWithLabel(p, "Moyenne"),
  },
  string: {
    renderCell: (p) => renderCellWithLabel(p, "Nb d'éléments"),
  },
};

// eslint-disable-next-line react-refresh/only-export-components
const StyledLabel = styled.div`
  color: ${colors["colors/text/darkGrey"]};
  ${typographies["Body/XXS"]};
  height: 2rem;
`;

// eslint-disable-next-line react-refresh/only-export-components
const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

// eslint-disable-next-line react-refresh/only-export-components
const StyledValue = styled.div`
  height: 2.2rem;
`;
