import { ElementInitial, sizeOptionsInitialElement } from "./ElementInitial";

export interface ElementInitialProps {
  text: JSX.Element | string;
  size: sizeOptionsInitialElement;
  clickable?: boolean;
  color: string;
  backgroundColor: string;
  isHover?: boolean;
}

export const ElementInitialCircle = (props: ElementInitialProps) => {
  return (
    <ElementInitial
      text={props.text}
      size={props.size}
      clickable={props.clickable}
      color={props.color}
      backgroundColor={props.backgroundColor}
      isHover={props.isHover}
      format={"circle"}
    ></ElementInitial>
  );
};
