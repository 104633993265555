import {
  ButtonDS,
  dateFormatterDayMonthYear,
  DownloadOutlined,
  SelectDS,
  Spacer,
  TableDS,
  TextCapitalized,
  triggerToast,
  unreachable,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import styled from "styled-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  getAsfInvoicesListAsync,
  selectCreateAsfInvoiceStatus,
  selectAsfInvoiceExportLink,
  createAsfInvoiceAsync,
  selectAsfInvoicesList,
  asfInvoiceExportAsync,
  asfInvoiceDownloadAsync,
} from "./asfInvoiceSlice";

import {
  companiesListAsync,
  selectCompaniesList,
  selectCompaniesListStatus,
} from "../companiesSlice";
import { useParams } from "react-router-dom";
import {
  AsfInvoicesListDisplayed,
  AsfInvoicesListDisplayedRow,
} from "./asfInvoiceApi";

export const AsfInvoiceTab = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const companiesList = useAppSelector(selectCompaniesList);
  const companiesListStatus = useAppSelector(selectCompaniesListStatus);
  const createInvoiceStatus = useAppSelector(selectCreateAsfInvoiceStatus);
  const asfInvoicesList = useAppSelector(selectAsfInvoicesList);
  const asfInvoiceExportLink = useAppSelector(selectAsfInvoiceExportLink);
  const [companySelected, setCompanySelected] = useState<string>("");
  const [displayError, setDisplayError] = useState<boolean>(false);

  const [beginningDateSelected, setBeginningDateSelected] = useState("");
  const [endDateSelected, setEndDateSelected] = useState("");

  const isValidSubmission = useMemo(
    () =>
      companySelected !== "" &&
      beginningDateSelected !== "" &&
      endDateSelected !== "",
    [companySelected, beginningDateSelected, endDateSelected],
  );

  const optionCompany = useMemo(
    () =>
      companiesList.map((company) => {
        return {
          value: company.uuid,
          label: company.name,
        };
      }),
    [companiesList],
  );

  const valueOptionCompany = useMemo(
    () => optionCompany.find((option) => option.value === companySelected),
    [optionCompany, companySelected],
  );

  useEffect(() => {
    if (companiesListStatus === "idle") {
      void dispatch(companiesListAsync());
    }
  }, [dispatch, companiesListStatus]);

  useEffect(() => {
    if (companySelected) {
      void dispatch(getAsfInvoicesListAsync({ company: companySelected }));
    }
  }, [companySelected, dispatch, createInvoiceStatus]);

  useEffect(() => {
    if (createInvoiceStatus === "success") {
      triggerToast(t("asfInvoice.creation.success") || "", "valid");
    } else if (createInvoiceStatus === "failed") {
      triggerToast(t("asfInvoice.creation.failure") || "", "error");
    }
  }, [createInvoiceStatus, t, dispatch]);

  useEffect(() => {
    if (asfInvoiceExportLink.presignedUrl && asfInvoiceExportLink.fileName) {
      void dispatch(asfInvoiceDownloadAsync(asfInvoiceExportLink));
    }
  }, [asfInvoiceExportLink, dispatch]);

  const clickToSubmit = useCallback(() => {
    if (!isValidSubmission) {
      setDisplayError(true);
    } else {
      setDisplayError(false);

      void dispatch(
        createAsfInvoiceAsync({
          companyUuid: companySelected,
          beginningDate: new Date(beginningDateSelected)
            .toISOString()
            .split("T")[0],
          endDate: new Date(endDateSelected).toISOString().split("T")[0],
        }),
      );
    }
  }, [
    beginningDateSelected,
    companySelected,
    dispatch,
    endDateSelected,
    isValidSubmission,
  ]);

  const selectCompany = useCallback((companyUuid: string) => {
    setCompanySelected(companyUuid);
  }, []);

  if (params.tab !== "asfInvoices") {
    return;
  }

  const headers = {
    uuid: {
      text: t("asfInvoice.column.asfInvoice"),
    },
    companyUuid: {
      text: t("asfInvoice.column.company"),
    },
    beginningDate: {
      text: t("asfInvoice.column.beginningDate"),
    },
    endDate: {
      text: t("asfInvoice.column.endDate"),
    },
    transactionsAmountTtc: {
      text: t("asfInvoice.column.transactionsAmountTtc"),
    },
    transactionsNumber: {
      text: t("asfInvoice.column.transactionsNumber"),
    },
    number: {
      text: t("asfInvoice.column.number"),
    },
    createdAt: {
      text: t("asfInvoice.column.createdAt"),
    },
    bucketFileName: {
      text: t("asfInvoice.column.download"),
    },
  };

  const render =
    (row: AsfInvoicesListDisplayedRow) =>
    (key: keyof AsfInvoicesListDisplayed) => {
      switch (key) {
        case "bucketFileName":
          return row[key] ? (
            <ButtonDS
              sizeButton={"S"}
              format={"hug"}
              buttonType={"secondary"}
              singleIcon={{ icon: <DownloadOutlined />, size: "S" }}
              onClick={() => {
                void dispatch(
                  asfInvoiceExportAsync({
                    bucketFileName: row[key] ?? "",
                    number: row["number"] ?? "",
                  }),
                );
              }}
            />
          ) : (
            ""
          );
        case "companyUuid":
          return valueOptionCompany?.label;
        case "beginningDate":
        case "endDate":
        case "createdAt":
          return dateFormatterDayMonthYear(new Date(row[key]));
        case "transactionsAmountTtc":
          return row[key] + " €";
        case "uuid":
        case "transactionsNumber":
        case "number":
          return row[key];
      }
      unreachable(key);
    };

  return (
    <StyledContainer>
      <Spacer y={1} />
      <StyledHeader>
        <TextCapitalized>{t("asfInvoice.select.company")}</TextCapitalized>
        <SelectDS
          label={""}
          value={valueOptionCompany}
          options={optionCompany}
          onChange={(selectedOption) =>
            selectCompany(selectedOption ? selectedOption.value : "")
          }
          error={
            (displayError &&
              companySelected === "" &&
              t("asfInvoice.select.company.error")) ||
            undefined
          }
          allWidth
        />
        <TextCapitalized>
          {t("asfInvoice.select.beginningDate")}
        </TextCapitalized>
        <input
          type="date"
          min="2024-01-01"
          value={beginningDateSelected}
          onChange={(e) => {
            setBeginningDateSelected(e.target.value);
            if (e.target.value > endDateSelected) {
              setEndDateSelected("");
            }
          }}
        />
        <Spacer y={1} />
        <TextCapitalized>{t("asfInvoice.select.endDate")}</TextCapitalized>
        <input
          type="date"
          min={beginningDateSelected}
          value={endDateSelected}
          onChange={(e) => setEndDateSelected(e.target.value)}
        />
        <Spacer y={1} />
        <ButtonDS
          format="hug"
          buttonType="primary"
          text={t("asfInvoice.button.create")}
          onClick={clickToSubmit}
          disabled={!isValidSubmission}
        />
      </StyledHeader>
      <Spacer y={2} />
      <StyledTable>
        <TableDS<keyof AsfInvoicesListDisplayed, AsfInvoicesListDisplayed>
          data={asfInvoicesList}
          headers={headers}
          render={render}
        />
      </StyledTable>
      <Spacer y={3} />
    </StyledContainer>
  );
};

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 30rem;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledTable = styled.div`
  height: 100%;
  overflow: auto;
`;
