import styled from "styled-components";
import { FormEvent, useState } from "react";
import { capitalize } from "../../format";
import { Spacer } from "../Spacer";
import { colors } from "../../styles/colors";

export interface AmountInputProps {
  value: number | "";
  onChange: (e: number | "") => void;
  error?: string;
  placeholder?: string;
}

export const AmountInput = (props: AmountInputProps) => {
  const [isInputFocus, setIsInputFocus] = useState<boolean>(false);
  const [isInputNull, setIsInputEmpty] = useState<boolean>(true);
  const change = (e: FormEvent<HTMLInputElement>) => {
    const regex = /^([0-9]){0,5}$/;
    const number = (e.target as HTMLInputElement).value;

    if (!number || number === "") {
      setIsInputEmpty(true);
      return props.onChange("");
    }
    if (number.match(regex)) {
      setIsInputEmpty(false);
      return props.onChange(parseInt(number));
    }
    return props.onChange(props.value);
  };
  return (
    <StyledBackground
      $isError={props.error !== undefined && isInputNull}
      $isInputFocus={isInputFocus}
    >
      <StyledAmount
        $isInputFocus={isInputFocus}
        $isError={props.error !== undefined && isInputNull}
      >
        <StyledAmountInput
          placeholder={props.placeholder && capitalize(props.placeholder)}
          value={props.value}
          onChange={change}
          onFocus={() => {
            setIsInputFocus(true);
          }}
          onBlur={() => {
            setIsInputFocus(false);
          }}
        />
        <StyledAmountSymbol>€</StyledAmountSymbol>
        <Spacer x={1} />
      </StyledAmount>
      {/* {props.error && isInputNull && (
          <div>
            <Spacer y={0.5} />
            <StyledErrorMessage>{props.error}</StyledErrorMessage>
          </div>
        )} */}
    </StyledBackground>
  );
};

// const StyledErrorMessage = styled.div`
//   color: ${colors.alertMessage};
//   font-size: 1rem;
//   line-height: 1rem;
//   &::first-letter {
//     text-transform: uppercase;
//   }
// `;

const StyledAmountInput = styled.input`
  display: block;
  width: 5.5rem;
  font-size: 1rem;
  line-height: 100%;
  color: ${colors.grey};
  background-color: transparent;
  &:focus {
    outline: none;
  }
  border: 0px;
  border-radius: 0.5rem;
  padding-left: 1rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
`;

const StyledAmount = styled.div<{
  $isInputFocus: boolean;
  $isError: boolean;
}>`
  display: flex;
  align-items: center;
  width: 7rem;
  height: 3.3rem;
  font-size: 1rem;
  color: ${colors.grey};
  background-color: ${({ $isError }) =>
    $isError ? colors.alert20 : "transparent"};
  border: 1px solid;
  border-color: ${({ $isError, $isInputFocus }) =>
    $isError
      ? colors.alert80
      : $isInputFocus
        ? colors.primary
        : colors.ligthGrey};
  border-radius: 0.5rem;
`;

const StyledBackground = styled.div<{
  $isInputFocus: boolean;
  $isError: boolean;
}>`
  display: flex;
  flex-direction: column;
  transition: all 0.4s ease-out;
  border: solid 0.25rem;
  border-color: transparent;
  border-radius: 0.75rem;
  border-color: ${({ $isError, $isInputFocus }) =>
    !$isError && $isInputFocus && colors.primary33};
  &:hover {
    transition: all 0.4s ease-out;
    border-color: ${({ $isError }) => !$isError && colors.primary33};
  }
`;

const StyledAmountSymbol = styled.div`
  color: ${colors.grey};
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
`;
