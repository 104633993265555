import styled from "styled-components";
import { Spacer } from "./Spacer";
import { colors } from "../styles/colors";

export interface CardProps {
  title: string;
  Element?:
    | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    | JSX.Element;
  TooltipElement?: JSX.Element;
  onClick: () => void;
}

export const Card = (props: CardProps) => {
  const { title, Element, TooltipElement, onClick } = props;
  return (
    <StyledCard onClick={onClick}>
      <StyledContainer>
        <Spacer x={2} />
        <StyledTitleContainer>
          <StyledTitle>{title}</StyledTitle>
          <Spacer x={0.5} />
          {TooltipElement && <div>{TooltipElement}</div>}
        </StyledTitleContainer>
        <Spacer x={2} />
        {Element && (
          <StyledElement>
            <>{Element}</>
          </StyledElement>
        )}
        <Spacer x={2} />
      </StyledContainer>
    </StyledCard>
  );
};

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Inter;
  justify-content: center;
  width: 100%;
  height: 5rem;
  font-weight: 600;
  border: 1px solid ${colors.ligthGrey};
  border-radius: 0.5rem;
  background-color: ${colors.white};
  box-shadow: 0px 4px 8px 0px rgba(83, 86, 84, 0.05);
  &:hover {
    box-shadow: 0px 4px 16px 0px rgba(51, 51, 51, 0.15);
    cursor: pointer;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const StyledTitle = styled.div`
  &::first-letter {
    text-transform: uppercase;
  }
`;

const StyledElement = styled.div`
  display: flex;
  align-self: center;
`;
