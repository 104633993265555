import styled from "styled-components";
import { FormEvent, useCallback } from "react";
import { Spacer } from "../Spacer";
import { typographies } from "../../../styles/figmaTypographies";
import { colors } from "../../../styles/figmaColors";
import { capitalize } from "../../../format";
import { TextCapitalized } from "../text/TextCapitalized";

interface TitleInputProps {
  value: string;
  error?: string;
  onChange: (e: string) => void;
  placeholder?: string;
}

export const TitleInputDS = (props: TitleInputProps) => {
  const { onChange } = props;
  const change = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      const text = (e.target as HTMLInputElement).value;
      return onChange(text);
    },
    [onChange],
  );
  return (
    <>
      <StyledTitleInput
        placeholder={props.placeholder && capitalize(props.placeholder)}
        value={props.value}
        onChange={change}
      />
      {props.error && (
        <StyledError>
          <StyledLine />
          <Spacer y={0.25} />
          <TextCapitalized>{props.error}</TextCapitalized>
        </StyledError>
      )}
    </>
  );
};

const StyledTitleInput = styled.input`
  display: flex;
  width: 100%;
  background-color: transparent;
  &:focus {
    outline: none;
  }
  border: 0px;
  ${typographies["Header/H1"]}
  color: ${colors["colors/text/black"]};
`;

const StyledError = styled.div`
  color: ${colors["colors/system/error/error_normal"]};
  ${typographies["Body/S"]}
  width: 20rem;
  position: absolute;
`;

const StyledLine = styled.div`
  width: 20rem;
  border-bottom: 0.0625rem solid ${colors["colors/system/error/error_normal"]};
`;
