import {
  Assets,
  Button,
  NumericInput,
  Page,
  Spacer,
  TableDS,
  TextCapitalized,
  ToastContainer,
  dateFormatterDayMonth,
  triggerToast,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  qrCodeAsync,
  qrCodesListAsync,
  qrCodesMultipleCreationAsync,
  qrCodesUploadAsync,
  selectQrCodeCreationStatus,
  selectQrCodeList,
  selectQrCodeListStatus,
  selectQrCodeMultipleCreationStatus,
  selectQrCodeUploadStatus,
} from "./qrCodeSlice";
import { QrCodeDisplayed } from "./qrCodeApi";
import { unreachable } from "../utils";
import { useEffect, useState } from "react";
import styled from "styled-components";

export const QrCode = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const qrCodesList = useAppSelector(selectQrCodeList);
  const qrCodesListStatus = useAppSelector(selectQrCodeListStatus);
  const qrCodesCreationStatus = useAppSelector(selectQrCodeCreationStatus);
  const qrCodesMultipleCreationStatus = useAppSelector(
    selectQrCodeMultipleCreationStatus,
  );
  const qrCodesUploadStatus = useAppSelector(selectQrCodeUploadStatus);
  const [numberToGenerate, setNumberToGenerate] = useState<number | "">(0);
  const [numberToUpload, setNumberToUpload] = useState<number | "">(0);
  const numberToUploadAvailable = qrCodesList.filter(
    (qrCode) => qrCode.zipFolder === null,
  ).length;

  if (qrCodesListStatus === "idle") {
    void dispatch(qrCodesListAsync());
  }

  useEffect(() => {
    if (qrCodesCreationStatus === "success") {
      triggerToast(t("qrCode.creation.success") || "", "valid");
    } else if (qrCodesCreationStatus === "failed") {
      triggerToast(t("qrCode.creation.failure") || "", "error");
    }
  }, [qrCodesCreationStatus, t]);

  useEffect(() => {
    if (qrCodesMultipleCreationStatus === "success") {
      triggerToast(t("qrCode.multiple.creation.success") || "", "valid");
    } else if (qrCodesMultipleCreationStatus === "failed") {
      triggerToast(t("qrCode.multiple.creation.failure") || "", "error");
    }
  }, [qrCodesMultipleCreationStatus, t]);

  useEffect(() => {
    if (qrCodesUploadStatus === "success") {
      triggerToast(t("qrCode.upload.success") || "", "valid");
    } else if (qrCodesUploadStatus === "failed") {
      triggerToast(t("qrCode.upload.failure") || "", "error");
    }
  }, [qrCodesUploadStatus, t]);

  const headers = {
    identifier: {
      iconSrc: <Assets.CircleNotification />,
      text: t("qrCode.page.column.identifier"),
    },
    archiveFileName: {
      iconSrc: <Assets.VerifiedUser />,
      text: t("qrCode.page.column.archiveFileName"),
    },
    vehicle: {
      iconSrc: <Assets.Car />,
      text: t("qrCode.page.column.vehicle"),
    },
    date: {
      iconSrc: <Assets.Calendar />,
      text: t("qrCode.page.column.date"),
    },
    zipFolder: {
      iconSrc: <Assets.DownloadGrey />,
      text: t("qrCode.page.column.zipFolder"),
    },
  };

  const render = (row: QrCodeDisplayed) => (key: keyof QrCodeDisplayed) => {
    switch (key) {
      case "date":
        return dateFormatterDayMonth(new Date(row[key]));
      case "identifier":
      case "archiveFileName":
      case "zipFolder":
      case "vehicle":
        return row[key];
    }
    unreachable(key);
  };

  return (
    <Page
      title={t("qrCode.title")}
      pageName={"qrCode"}
      toaster={<ToastContainer />}
    >
      <TextCapitalized>
        {t("qrCode.total.number")}
        {" " + qrCodesList.length.toString()}
      </TextCapitalized>
      <Spacer y={2} />
      <StyledHeader>
        <Button
          title={t("qrCode.button.generation")}
          width={14}
          onClick={() => void dispatch(qrCodeAsync())}
        />
        <Spacer x={5} />
        <div>
          <Button
            title={t("qrCode.button.generation.multiple", {
              number: numberToGenerate === "" ? 0 : numberToGenerate,
            })}
            width={12}
            onClick={() =>
              numberToGenerate !== "" &&
              numberToGenerate !== 0 &&
              void dispatch(qrCodesMultipleCreationAsync({ numberToGenerate }))
            }
            disabled={numberToGenerate === "" || numberToGenerate === 0}
          />
          <Spacer y={1} />
          <TextCapitalized>
            {t("qrCode.input.generation.number")}
          </TextCapitalized>
          <NumericInput
            value={numberToGenerate}
            update={(value) =>
              setNumberToGenerate(value !== "" && value > 20000 ? 20000 : value)
            }
          />
        </div>
        <Spacer x={5} />
        <div>
          <Button
            title={t("qrCode.button.upload.multiple", {
              number: numberToUpload === "" ? 0 : numberToUpload,
              numberToUploadAvailable,
            })}
            width={26}
            onClick={() =>
              numberToUpload !== "" &&
              numberToUpload !== 0 &&
              void dispatch(qrCodesUploadAsync({ numberToUpload }))
            }
            disabled={numberToUpload === "" || numberToUpload === 0}
          />
          <Spacer y={1} />
          <TextCapitalized>{t("qrCode.input.upload.number")}</TextCapitalized>
          <NumericInput
            value={numberToUpload}
            update={(value) =>
              setNumberToUpload(
                value !== "" && value > numberToUploadAvailable
                  ? numberToUploadAvailable
                  : value,
              )
            }
          />
        </div>
      </StyledHeader>
      <Spacer y={1} />
      <TableDS<keyof QrCodeDisplayed, QrCodeDisplayed>
        data={qrCodesList}
        headers={headers}
        render={render}
      />
    </Page>
  );
};

const StyledHeader = styled.div`
  display: flex;
  width: 100%;
`;
