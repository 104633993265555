import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  getToken,
  removeToken,
  selectAdminUser,
  selecthasToken,
} from "./homeSlice";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  Menu,
  colors,
  Assets,
  MonetizationOnOutlined,
  SearchOutlined,
  StorefrontOutlined,
  SwitchAccountOutlined,
  PropaneTankOutlined,
} from "@qivia/ui";
import useAuth from "./useAuth";

export const Home = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const adminUser = useAppSelector(selectAdminUser);
  const hasToken = useAppSelector(selecthasToken);
  const location = useLocation();
  useAuth();

  const initialSelectedRoute = location.pathname.split("/")[2]
    ? location.pathname.split("/")[2]
    : "";
  const [selectedRoute, setSelectedRoute] = useState<string | undefined>(
    initialSelectedRoute,
  );

  const env = import.meta.env["VITE_TARGET_ENV"] as string;
  type EnvType = "production" | "staging" | "demo" | "localhost";
  const colorHeaderDict = {
    production: {
      color: colors.red,
    },
    staging: {
      color: colors.blue,
    },
    demo: {
      color: colors.paleGreen,
    },
    localhost: {
      color: colors.contentSecondary,
    },
  };

  useEffect(() => {
    if (!adminUser) {
      if (hasToken === false) {
        navigate("/login");
      }
      dispatch(getToken());
    }
  }, [adminUser, dispatch, hasToken, navigate]);

  const logout = () => {
    dispatch(removeToken());
    navigate("/login");
  };

  const onChangeRoute = (route: string) => {
    setSelectedRoute(route);
    navigate(route);
  };

  const props = {
    sections: [
      {
        route: "",
        iconSrc: <Assets.HomeDashboard />,
        title: t("home.menu.home"),
      },
      {
        route: "supporting_documents",
        iconSrc: (
          <StyledIcon>
            <SearchOutlined />
          </StyledIcon>
        ),
        title: t("home.menu.supportingDocuments"),
      },
      {
        route: "qrCode",
        iconSrc: <Assets.QrCode />,
        title: t("home.menu.qrCode"),
      },
      {
        route: "companies_data",
        iconSrc: (
          <StyledIcon>
            <StorefrontOutlined />
          </StyledIcon>
        ),
        title: t("home.menu.companiesData"),
      },
      {
        route: "tank_capacities",
        iconSrc: (
          <StyledIcon>
            <PropaneTankOutlined />
          </StyledIcon>
        ),
        title: t("home.menu.tankCapacities"),
      },
      {
        route: "drivers",
        iconSrc: (
          <StyledIcon>
            <SwitchAccountOutlined />
          </StyledIcon>
        ),
        title: t("home.menu.drivers"),
      },
      {
        route: "billings",
        iconSrc: (
          <StyledIcon>
            <MonetizationOnOutlined />
          </StyledIcon>
        ),
        title: t("home.menu.billings"),
      },
      {
        route: "statements",
        iconSrc: <Assets.Billing />,
        title: t("home.menu.statement"),
      },
      {
        route: "electric",
        iconSrc: <Assets.ElectricBolt />,
        title: t("home.menu.electric"),
      },
      {
        route: "zipFolder",
        iconSrc: <Assets.Folder />,
        title: t("home.menu.zipFolder"),
      },
      {
        route: "termsAndPolicy",
        iconSrc: <Assets.DownloadSolid />,
        title: t("home.menu.termsAndPolicy"),
      },
    ],
    route: "users",
    onChangeRoute: onChangeRoute,
    logout: logout,
    isQg: true,
  };

  const colorHeader =
    colorHeaderDict[env as EnvType]?.color || colors.contentSecondary;

  return (
    <StyledContainer>
      <StyledHeader
        color={colorHeader}
        isInProduction={(env as EnvType) === "production"}
      >
        {t(`home.header.warning.${env as EnvType}`)} {adminUser?.name}
      </StyledHeader>
      <StyledContent>
        <Menu {...props} selectedRoute={selectedRoute} />
        <Outlet />
      </StyledContent>
    </StyledContainer>
  );
};
const StyledContent = styled.div`
  display: flex;
  flex-direction: row;
  height: 96%;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
`;

const StyledIcon = styled.div`
  & svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

const StyledHeader = styled.div<{ color: string; isInProduction: boolean }>`
  display: flex;
  background-color: ${({ color }) => color};
  color: white;
  height: 4%;
  justify-content: center;
  align-items: center;
  ${({ isInProduction }) =>
    isInProduction &&
    `animation-duration: 6s;
  animation-name: clignoter;
  animation-iteration-count: infinite;
  transition: none;
  @keyframes clignoter {
    0% {
      opacity: 1;
    }
    40% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }`}
`;
