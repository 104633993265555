import styled from "styled-components";
import { colors } from "../../styles/figmaColors";
import { Key } from "react";

export interface RadioProps {
  onClick?: () => void;
  keyProp?: Key | null;
  isSelected: boolean;
}

export const Radio = (props: RadioProps) => {
  const { onClick, keyProp, isSelected } = props;

  return (
    <StyledRadio
      key={keyProp}
      $isSelected={isSelected}
      onClick={onClick}
    ></StyledRadio>
  );
};

interface StyledRadioArgs {
  $isSelected: boolean;
}

const getBorder = ($isSelected: boolean): string => {
  if ($isSelected) {
    return `0.3rem solid ${colors["colors/button/primary/default"]}`;
  }
  return `0.1625rem solid ${colors["colors/borders/button/primary"]}`;
};

const StyledRadio = styled.div<StyledRadioArgs>`
  height: 1.3rem;
  width: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.3rem;
  transition: 0.2s ease-out;
  border: ${({ $isSelected }) => getBorder($isSelected)};
  cursor: pointer;
`;
