import styled from "styled-components";
import { TextCapitalized } from "./text/TextCapitalized";

type ElementInitialProps = {
  text: JSX.Element | string;
  size: sizeOptionsInitialElement;
  clickable?: boolean;
  color: string;
  backgroundColor: string;
  isHover?: boolean;
  format: formatTypeInitialElement;
};

export type sizeOptionsInitialElement = "S" | "M" | "L" | "XL" | "ML";
export type formatTypeInitialElement = "circle" | "square";

const options: {
  [key in sizeOptionsInitialElement]: { fontSize: number; diameter: number };
} = {
  S: { fontSize: 0.75, diameter: 1.5 },
  M: { fontSize: 1, diameter: 2 },
  L: { fontSize: 2.5, diameter: 4.5 },
  XL: { fontSize: 2.06, diameter: 6.625 },
  ML: { fontSize: 1.2, diameter: 2.5 },
};

export const ElementInitial = (props: ElementInitialProps) => {
  return (
    <StyledElement
      $fontSize={options[props.size].fontSize}
      $diameter={options[props.size].diameter}
      $clickable={props.clickable}
      $isHover={props.isHover}
      $color={props.color}
      $backgroundColor={props.backgroundColor}
      $format={props.format}
    >
      <TextCapitalized>{props.text}</TextCapitalized>
    </StyledElement>
  );
};

interface ElementProps {
  $fontSize: number;
  $diameter: number;
  $clickable?: boolean;
  $isHover?: boolean;
  $backgroundColor: string;
  $color: string;
  $format: formatTypeInitialElement;
}

const getBorderRadius = (
  $format: formatTypeInitialElement,
  $diameter: number,
): number => {
  if ($format === "square") {
    return 0.5;
  } else return $diameter / 2;
};

const StyledElement = styled.div<ElementProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ $diameter }) => $diameter}rem;
  height: ${({ $diameter }) => $diameter}rem;
  border-radius: ${({ $diameter, $format }) =>
    getBorderRadius($format, $diameter)}rem;
  line-height: ${({ $diameter }) => $diameter}rem;
  font-size: ${({ $fontSize }) => $fontSize}rem;
  text-align: center;
  font-family: inter;
  font-weight: 350;
  cursor: ${({ $clickable }) => ($clickable ? "pointer" : "default")};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  color: ${({ $color }) => $color};
  ${({ $isHover, $color }) => $isHover && `border: 0.0625rem solid ${$color}`};
`;
