export const checkFuelType = (fuel: string, transactionFuel: string) => {
  switch (transactionFuel) {
    case "fuel":
    case "hybrideFuel":
      if (
        fuel === "SP95" ||
        fuel === "SP95_E10" ||
        fuel === "SP98" ||
        fuel === "E85" ||
        fuel === "ED95" ||
        fuel === "AdBlue"
      )
        return true;
      else return false;
    case "diesel":
    case "hybrideDiesel":
      if (
        fuel === "Diesel_B7" ||
        fuel === "Diesel_B10" ||
        fuel === "Diesel_B30" ||
        fuel === "AdBlue"
      )
        return true;
      else return false;
    case "GPL":
      if (fuel === "GPL" || fuel === "GNL") return true;
      else return false;
    case "GNV":
      if (fuel === "GNV" || fuel === "GNC") return true;
      else return false;
    case "electric":
      if (fuel === "Electric") return true;
      else return false;
    default:
      return true;
  }
};
