import styled from "styled-components";
import { colors } from "../../styles/colors";

export interface InitialTextClickableProps {
  children: string | string[] | JSX.Element | JSX.Element[] | null;
  onClick?: () => void;
  underline?: boolean;
  capitalized?: boolean;
}

export const TextClickable = (props: InitialTextClickableProps) => {
  return (
    <StyledTextClickable
      $underline={props.underline}
      $capitalized={props.capitalized}
      onClick={props.onClick}
    >
      {props.children}
    </StyledTextClickable>
  );
};

interface TextClickableProps {
  $underline?: boolean;
  $capitalized?: boolean;
}

const StyledTextClickable = styled.div<TextClickableProps>`
  color: ${colors.primary};
  cursor: pointer;
  text-decoration-line: ${({ $underline }) => ($underline ? `underline` : "")};
  ${({ $capitalized }) =>
    $capitalized
      ? `&::first-letter {
    text-transform: uppercase;
  }`
      : ""};
`;
