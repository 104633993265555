import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { colors } from "../../styles/figmaColors";
import { Spacer } from "./Spacer";
import { typographies } from "../../styles/figmaTypographies";
import { TextCapitalized } from "./text/TextCapitalized";
import { CloudUploadOutlined } from "../materialUi/materialUi";
import { ButtonDS } from "./Button";
import { FileInputButton } from "../../components/input/FileInputButton";
import { useCallback } from "react";

interface DragAndDropProps {
  handleFileChange: (file: File | null) => void;
  body: string;
  width?: number;
  hasExtensionError?: boolean;
}

const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
  event.stopPropagation();
  event.preventDefault();
};

export const DragAndDrop = (props: DragAndDropProps) => {
  const { t } = useTranslation();
  const { handleFileChange } = props;

  const onFileDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.stopPropagation();
      event.preventDefault();
      const list = event.dataTransfer.files[0];
      handleFileChange(list);
    },
    [handleFileChange],
  );

  return (
    <StyledDragAndDropContainer
      onDrop={onFileDrop}
      onDragOver={handleDragOver}
      $width={props.width}
    >
      <Spacer x={1} />
      <StyledColumn>
        <Spacer y={1} />
        <div>
          <CloudUploadOutlined />
        </div>
        <Spacer y={0.5} />
        <StyledContent>
          <StyledTitle>
            <TextCapitalized>{t("dragAndDrop.title")}</TextCapitalized>
          </StyledTitle>
          <Spacer y={0.25} />
          <TextCapitalized>{props.body}</TextCapitalized>
          <Spacer y={1} />
          {props.hasExtensionError && (
            <StyledError>
              <TextCapitalized>
                {t("dragAndDrop.extentionError")}
              </TextCapitalized>
              <Spacer y={1} />
            </StyledError>
          )}
          <FileInputButton handleFile={handleFileChange}>
            <ButtonDS
              format="hug"
              buttonType={"secondary"}
              text={t("dragAndDrop.button")}
            />
          </FileInputButton>
        </StyledContent>
        <Spacer y={1} />
      </StyledColumn>
      <Spacer x={1} />
    </StyledDragAndDropContainer>
  );
};

const StyledTitle = styled.div`
  display: flex;
  ${typographies["Body/M"]};
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const StyledContent = styled.div`
  max-width: 18.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledError = styled.div`
  ${typographies["Body/S"]};
  color: ${colors["colors/system/error/error_normal"]};
  text-align: center;
`;

const StyledDragAndDropContainer = styled.div<{ $width?: number }>`
  display: flex;
  ${typographies["Body/S"]};
  color: ${colors["colors/text/darkGrey"]};
  border: 0.125rem dashed ${colors["colors/borders/separator/cells"]};
  width: ${({ $width }) => ($width ? `${$width}rem` : " 33.5rem")};
  border-radius: 0.75rem;
`;
