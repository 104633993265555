import styled from "styled-components";
import Copy from "../assets/copySolid.svg?react";
import { colors } from "../styles/colors";
import { Spacer } from "./Spacer";
import { ElementButton } from "./ElementButton";

export interface DisplayElemToCopyProps {
  title: string;
  children?: string | JSX.Element | JSX.Element[];
  elementToCopy?: string;
  onClick: () => void;
}

export const DisplayElemToCopy = (props: DisplayElemToCopyProps) => {
  const valueStringToCopy = props.elementToCopy
    ? props.elementToCopy
    : props.children
      ? String(props.children)
      : "";

  return (
    <StyledDisplayElemToCopy>
      <Spacer x={1} />
      <StyledTextElement>
        <Spacer y={1} />
        <StyledTitle>{props.title} </StyledTitle>
        <Spacer y={0.25} />
        {props.children}
        <Spacer y={1} />
      </StyledTextElement>
      <Spacer x={2} />
      <StyledCopy>
        <ElementButton
          onClick={() => {
            navigator.clipboard
              .writeText(valueStringToCopy)
              .then(() => {})
              .catch((e) => {
                throw e;
              });
            props.onClick();
          }}
          Element={<Copy />}
        />
      </StyledCopy>
      <Spacer x={1} />
    </StyledDisplayElemToCopy>
  );
};

const StyledTitle = styled.div`
  font-weight: 600;
  font-size: 1rem;
  color: ${colors.black};
  &::first-letter {
    text-transform: uppercase;
  }
`;

const StyledTextElement = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  color: ${colors.contentTertiary};
  width: 100%;
`;

const StyledCopy = styled.div`
  cursor: pointer;
  &:hover {
    & svg path {
      fill: ${colors.disabled};
    }
  }
`;

const StyledDisplayElemToCopy = styled.div`
  display: flex;
  box-sizing: border-box;
  border: 1px solid ${colors.ligthGrey};
  border-radius: 0.5rem;
  justify-content: space-between;
  align-items: center;
  width: 36rem;
`;
