import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { QueryStatus, authAxios } from "../../src/utils";
import { RootState } from "../redux/store";
import {
  ExportZipFolderPayload,
  ExportZipFolder,
  ZipFolderApi,
} from "./zipFolderApi";
import axios from "axios";

export interface ZipFoldersState {
  zipFoldersExportStatus: QueryStatus;
  zipFoldersListStatus: QueryStatus;
  zipFoldersList: ZipFolderApi[];
  zipFolderExportLink: ExportZipFolderPayload;
  zipFolderDownloadStatus: QueryStatus;
}

const initialState: ZipFoldersState = {
  zipFoldersExportStatus: "idle",
  zipFoldersListStatus: "idle",
  zipFoldersList: [],
  zipFolderExportLink: {
    presignedUrl: null,
    fileName: null,
  },
  zipFolderDownloadStatus: "idle",
};

export const zipFoldersListAsync = createAsyncThunk(
  "zipFoldersList/call",
  async () => {
    const axios = authAxios();
    const response = await axios.get<Array<ZipFolderApi>>(`zip_folders`);
    return response.data.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );
  },
);

export const zipFolderExportAsync = createAsyncThunk(
  "zipFolderExport/call",
  async (payload: ExportZipFolder) => {
    const axios = authAxios();
    const response = await axios.post<{ presignedUrl: string | null }>(
      `zip_folder/${payload.uuid}/link`,
    );
    return {
      presignedUrl: response.data.presignedUrl,
      fileName: payload.fileName,
    };
  },
);

export const zipFolderDownloadAsync = createAsyncThunk(
  "zipFolderDownload/call",
  async (zipFolderExportLink: ExportZipFolderPayload) => {
    if (zipFolderExportLink.presignedUrl && zipFolderExportLink.fileName) {
      const response = await axios({
        url: zipFolderExportLink.presignedUrl,
        method: "GET",
        responseType: "blob",
      });
      const a = document.createElement("a");
      a.download = zipFolderExportLink.fileName || "yourZipFolderFile.zip";
      const url = window.URL.createObjectURL(new Blob([response.data]));
      a.href = url;
      a.click();
    }
  },
);

export const zipFoldersSlice = createSlice({
  name: "zipFolders",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(zipFolderExportAsync.pending, (state) => {
        state.zipFoldersExportStatus = "processing";
      })
      .addCase(zipFolderExportAsync.fulfilled, (state, action) => {
        state.zipFolderExportLink = action.payload;
        state.zipFoldersExportStatus = "success";
        state.zipFoldersListStatus = "idle";
      })
      .addCase(zipFolderExportAsync.rejected, (state) => {
        state.zipFoldersExportStatus = "failed";
      })
      .addCase(zipFoldersListAsync.pending, (state) => {
        state.zipFoldersListStatus = "processing";
      })
      .addCase(zipFoldersListAsync.fulfilled, (state, action) => {
        state.zipFoldersList = action.payload;
        state.zipFoldersListStatus = "success";
      })
      .addCase(zipFoldersListAsync.rejected, (state) => {
        state.zipFoldersListStatus = "failed";
      })
      .addCase(zipFolderDownloadAsync.pending, (state) => {
        state.zipFolderDownloadStatus = "processing";
      })
      .addCase(zipFolderDownloadAsync.fulfilled, (state) => {
        state.zipFolderExportLink.presignedUrl = null;
        state.zipFolderExportLink.fileName = null;
        state.zipFolderDownloadStatus = "success";
        state.zipFoldersExportStatus = "idle";
      })
      .addCase(zipFolderDownloadAsync.rejected, (state) => {
        state.zipFolderDownloadStatus = "failed";
      });
  },
});

export const selectZipFolderList = (state: RootState) =>
  state.zipFolder.zipFoldersList;
export const selectZipFolderListStatus = (state: RootState) =>
  state.zipFolder.zipFoldersListStatus;
export const selectZipFoldersExportStatus = (state: RootState) =>
  state.zipFolder.zipFoldersExportStatus;
export const selectZipFoldersExportLink = (state: RootState) =>
  state.zipFolder.zipFolderExportLink;
export const selectZipFolderDownloadStatus = (state: RootState) =>
  state.zipFolder.zipFolderDownloadStatus;

export default zipFoldersSlice.reducer;
