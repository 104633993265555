import { HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { Spacer } from "./Spacer";
import { typographies } from "../../styles/figmaTypographies";
import { TextCapitalized } from "./text/TextCapitalized";
import { IconCircle } from "./IconCircle";

export interface MenuRowProps extends HTMLAttributes<HTMLButtonElement> {
  title: string;
  body: string;
  icon: JSX.Element;
  rightButton?: JSX.Element;
  additionalElement?: JSX.Element;
}

export const MenuRow = (props: MenuRowProps) => {
  const { title, body, icon, rightButton, additionalElement } = props;
  const typoTitle = typographies["Body/M"];
  const typoBody = typographies["Body/S"];

  return (
    <StyledContainer $additionalElement={additionalElement}>
      <div>
        <IconCircle icon={icon} />
      </div>
      <Spacer x={1} />
      <StyledContent>
        <StyledText $typo={typoTitle}>
          <TextCapitalized>{title}</TextCapitalized>
        </StyledText>
        <Spacer y={0.0625} />
        <StyledBody>
          <StyledText $typo={typoBody}>
            <TextCapitalized>{body}</TextCapitalized>
          </StyledText>
          <Spacer x={0.625} />
          {additionalElement}
        </StyledBody>
      </StyledContent>
      <Spacer x={1} />
      <StyledRightButton>{rightButton}</StyledRightButton>
    </StyledContainer>
  );
};

interface StyledContentArgs {
  $typo?: string;
}

interface StyledContainerArgs {
  $additionalElement?: JSX.Element;
}

const StyledBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1rem;
  width: max-content;
`;

const StyledContainer = styled.div<StyledContainerArgs>`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  height: ${({ $additionalElement }) => ($additionalElement ? 3 : 2.75)}rem;
`;

const StyledText = styled.div<StyledContentArgs>`
  ${({ $typo }) => css`
    ${$typo}
  `};
  display: flex;
  word-break: break-all;
`;

const StyledContent = styled.div<StyledContentArgs>`
  display: flex;
  width: 100%;
  flex-direction: column;
  word-break: break-all;
  ${({ $typo }) => css`
    ${$typo}
  `};
`;

const StyledRightButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
`;
