import styled from "styled-components";
import { colors } from "../styles/colors";
import { Spacer } from "./Spacer";

export interface StepFormElementProps {
  title: string;
  text: string;
  element: JSX.Element;
}

export const StepFormElement = (props: StepFormElementProps) => {
  return (
    <StyledStepFormElement>
      <StyledText>
        <StyledTitle>{props.title}</StyledTitle>
        <Spacer y={0.5} />
        <StyledBody>{props.text}</StyledBody>
      </StyledText>
      <Spacer x={9} />
      <StyledElement>{props.element}</StyledElement>
    </StyledStepFormElement>
  );
};

const StyledText = styled.div`
  display: flex;
  max-width: 30rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 135%;
  color: ${colors.disabled};
  &::first-letter {
    text-transform: uppercase;
  }
  flex-direction: column;
`;

const StyledTitle = styled.div`
  color: ${colors.grey};
  font-weight: 500;
  font-size: 1rem;
  &::first-letter {
    text-transform: uppercase;
  }
`;

const StyledBody = styled.div`
  &::first-letter {
    text-transform: uppercase;
  }
`;

const StyledElement = styled.div`
    display-flex;
    justify-content: flex-end;
`;

const StyledStepFormElement = styled.div`
  display: flex;
  max-width: 45rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
