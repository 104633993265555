import { colors } from "../../styles/colors";
import styled from "styled-components";
import Pin from "../../assets/pin.svg?react";
import { Spacer } from "../Spacer";
import { useTranslation } from "react-i18next";

export type Status = "ACTIVE" | "IN_OPPOSITION" | "TO_ACTIVATE" | "LOCKED";

const statusProps = {
  ACTIVE: {
    backgroundColor: colors.lightGreen,
    pinColor: colors.primary,
  },
  IN_OPPOSITION: {
    backgroundColor: colors.lightRed,
    pinColor: colors.red,
  },
  TO_ACTIVATE: {
    backgroundColor: colors.paleBlue50,
    pinColor: colors.blue,
  },
  LOCKED: {
    backgroundColor: colors.background,
    pinColor: colors.black,
  },
};

export interface TableLabelProps {
  status: Status;
}
export const TableLabelCard = (props: TableLabelProps) => {
  const cardStatus = statusProps[props.status];
  const { t } = useTranslation();

  return (
    <StyledContainer $backgroundColor={cardStatus.backgroundColor}>
      {cardStatus.pinColor && (
        <StyledPinContainer $color={cardStatus.pinColor}>
          <Spacer x={0.5} />
          <Pin />
        </StyledPinContainer>
      )}
      <Spacer x={0.5} />
      <StyledTextContainer>
        {t(
          `cards.status.${
            props.status === "TO_ACTIVATE"
              ? "toActivate"
              : props.status === "IN_OPPOSITION"
                ? "inOpposition"
                : props.status.toLowerCase()
          }`,
        )}
      </StyledTextContainer>
      <Spacer x={0.5} />
    </StyledContainer>
  );
};

const StyledPinContainer = styled.div<{ $color: string | null }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5rem;
  & circle {
    fill: ${({ $color }) => $color};
  }
`;
const StyledTextContainer = styled.div`
  font-family: Inter;
  font-size: 0.875rem;
  height: 1.5rem;
  line-height: 1.5rem;
  &::first-letter {
    text-transform: uppercase;
  }
  cursor: default;
  font-weight: 400;
`;

const StyledContainer = styled.div<{ $backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  color: ${({ $backgroundColor }) =>
    $backgroundColor === colors.red ? "white" : "black"};
  height: 1.5rem;
  width: max-content;
`;
