import styled from "styled-components";
import { Spacer } from "./Spacer";
import { MouseEvent } from "react";
import { TextCapitalized } from "./text/TextCapitalized";
import { typographies } from "../../styles/figmaTypographies";
import { ClearOutlined } from "../materialUi/materialUi";
import { ButtonDS } from "./Button";
import { colors } from "../../styles/figmaColors";
import { Tag } from "./Tag/Tag";

interface ModalWithLeftPartProps {
  children: string | JSX.Element | JSX.Element[];
  visible: boolean;
  title: string;
  tagText?: string;
  onClose: () => void;
  leftPart: {
    button: JSX.Element;
    icon: JSX.Element;
  };
}
export const ModalWithLeftPartDS = (props: ModalWithLeftPartProps) => {
  const clickOnModal = (targetElement: string) => {
    if (
      targetElement === "backgroundModal" ||
      targetElement === "backgroundModalWithLeftPart"
    ) {
      props.onClose && props.onClose();
    }
  };

  return (
    <StyledBackground
      $visible={props.visible}
      id="backgroundModalWithLeftPart"
      onClick={(e: MouseEvent<HTMLElement>) =>
        clickOnModal((e.target as HTMLElement)?.id)
      }
    >
      <Spacer x={3} />
      <StyledColumn>
        <Spacer y={4} />
        <StyledContainer>
          <StyledLeftModal>
            <Spacer x={2} />
            <StyledColumn>
              <Spacer y={3} />
              {props.tagText && (
                <StyledTag>
                  <Tag
                    text={props.tagText}
                    borderColor={colors["colors/borders/cells/cells"]}
                    backgroundColor={colors["colors/text/white"]}
                  />
                </StyledTag>
              )}
              <Spacer y={2} />
              <StyledIcon>{props.leftPart.icon}</StyledIcon>
              <StyledLeftPartButton>
                {props.leftPart.button}
              </StyledLeftPartButton>
              <Spacer y={2} />
            </StyledColumn>
            <Spacer x={2} />
          </StyledLeftModal>
          <Spacer x={4} />
          <StyledRightModal>
            <Spacer y={2.5} />
            <StyledTitle>
              <StyledAllWidth>
                <TextCapitalized>{props.title}</TextCapitalized>
              </StyledAllWidth>
              <Spacer x={1.5} />
              <ButtonDS
                format="fill"
                buttonType="secondary"
                singleIcon={{ icon: <ClearOutlined />, size: "L" }}
                onClick={props.onClose}
              />
            </StyledTitle>
            <Spacer y={2.5} />
            <StyledLine />
            <Spacer y={1.5} />
            {props.children}
            <Spacer y={1.5} />
          </StyledRightModal>
          <Spacer x={3} />
        </StyledContainer>
        <Spacer y={4} />
      </StyledColumn>
      <Spacer x={3} />
    </StyledBackground>
  );
};

const StyledLine = styled.div`
  display: flex;
  border-top: 0.0625rem solid ${colors["colors/borders/cells/cells"]};
  width: 100%;
`;

const StyledTitle = styled.div`
  display: flex;
  flex-direction: row;
  ${typographies["Header/H1"]};
  colors: ${colors["colors/text/black"]};
  align-items: center;
  justify-content: space-between;
  min-height: 3rem;
  width: 100%;
`;

const StyledAllWidth = styled.div`
  width: 100%;
`;

const StyledRightModal = styled.div`
  display: flex;
  width: 100%;
  align-content: space-between;
  flex-direction: column;
  overflow: auto;
`;

const StyledLeftModal = styled.div`
  display: flex;
  min-width: 23rem;
  background-color: ${colors["colors/surfaces/background/background_level1"]};
`;

const StyledLeftPartButton = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
`;

const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: 20rem;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  width: 70rem;
  border-radius: 0.5rem;
  background-color: white;
  border: solid 0.0625rem ${colors["colors/borders/cells/cells"]};
  flex-direction: row;
  justify-content: center;
  overflow: auto;
  height: 34rem;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTag = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledBackground = styled.div<{ $visible: boolean }>`
  z-index: 10;
  position: fixed;
  display: ${({ $visible }) => ($visible ? "flex" : "none")};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  backdrop-filter: blur(0.25rem);
  box-shadow:
    0px 0px 15px rgba(143, 143, 143, 0.15),
    0px 2px 5px rgba(143, 143, 143, 0.15);
  overflow: auto;
`;
