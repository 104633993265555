import styled from "styled-components";
import { ToastContainer as RawToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactNode } from "react";
import { Spacer } from "./Spacer";
import { TextCapitalized } from "./text/TextCapitalized";
import { typographies } from "../../styles/figmaTypographies";
import { colors } from "../../styles/figmaColors";
import { CheckOutlined, CloseOutlined } from "../materialUi/materialUi";

export interface ToastProps {
  type: ToastType;
  text: string;
}

export type ToastType = "ERROR" | "SUCCESS";

type ToastArgsType = {
  icon: ReactNode;
  color: string;
  iconBackgroundColor: string;
};

const ToastArgs = {
  ERROR: {
    icon: <CloseOutlined />,
    color: colors["colors/system/error/error_normal"],
    iconBackgroundColor: colors["colors/system/error/error_ultraLight"],
  },
  SUCCESS: {
    icon: <CheckOutlined />,
    color: colors["colors/system/success/success_normal"],
    iconBackgroundColor: colors["colors/system/success/success_ultraLight"],
  },
};

export const ToastDS = (props: ToastProps) => {
  const toast: ToastArgsType = ToastArgs[props.type];

  return (
    <StyledContainer>
      <Spacer y={0.5} />
      <StyledRow>
        <StyledIcon
          $color={toast.color}
          $iconBackgroundColor={toast.iconBackgroundColor}
        >
          {toast.icon}
        </StyledIcon>
        <Spacer x={0.5} />
        <TextCapitalized>
          <StyledText>{props.text}</StyledText>
        </TextCapitalized>
        <Spacer x={5.5} />
      </StyledRow>
      <Spacer y={0.5} />
    </StyledContainer>
  );
};

export const ToastContainerDS = () => {
  return (
    <StyledRawToastContainer
      position="top-center"
      autoClose={6000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      limit={1}
      closeButton={true}
    />
  );
};

const StyledRawToastContainer = styled(RawToastContainer)`
  width: fit-content;
  .Toastify__close-button {
    height: 100%;
    display: flex;
    align-self: center;
    justify-content: center;
    & svg {
      width: 1.5rem;
      height: 1.5rem;
      fill: ${colors["colors/text/lightGrey"]};
    }
    &:hover {
      & svg {
        fill: ${colors["colors/text/veryLightGrey"]};
      }
    }
    transition: 0s;
    border-right: 0.5rem solid transparent;
    border-left: 0.5rem solid transparent;
  }
  .Toastify__toast-icon {
    display: none;
  }
  .Toastify__progress-bar--success {
    background: ${colors["colors/system/success/success_normal"]};
  }
  .Toastify__progress-bar--error {
    background: ${colors["colors/system/error/error_normal"]};
  }
  .Toastify__toast-theme--light {
    color: ${colors["colors/text/black"]};
    ${typographies["Body/XL"]}
  }
`;

const StyledContainer = styled.div`
  display: flex;
  cursor: default;
  flex-direction: column;
`;
const StyledRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const StyledIcon = styled.div<{
  $color: string;
  $iconBackgroundColor: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  fill: ${({ $color }) => $color};
  background-color: ${({ $iconBackgroundColor }) => $iconBackgroundColor};
  min-width: 2rem;
  min-height: 2rem;
  border-radius: 2rem;
  & svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const StyledText = styled.div`
  ${typographies["Body/XL"]}
  color: ${colors["colors/text/black"]};
  width: max-content;
`;
