import { createTheme, ThemeProvider } from "@mui/material";
import { frFR as frFRGrid } from "@mui/x-data-grid/locales";
import { frFR as frFRDatePicker } from "@mui/x-date-pickers-pro/locales";
import { colors } from "../styles/figmaColors";
import { PropsWithChildren } from "react";

const theme = createTheme(
  {
    palette: {
      primary: { main: colors["colors/button/primary/default"] },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "capitalize",
            borderRadius: "0.5rem",
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            height: "100%",
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            height: "100%",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            height: "100%",
          },
        },
      },
      MuiPopper: {
        defaultProps: {
          sx: {
            "&.MuiDataGrid-panel .MuiPaper-root": {
              inset: "-5rem auto auto 33rem " /* haut droit bas gauche */,
              position: "fixed",
            },
            position: "fixed !important",
          },
        },
      },
    },
    spacing: 10,
    typography: {
      fontFamily: "Inter",
    },
  },
  frFRGrid,
  frFRDatePicker,
);

export default function MaterialUiThemeProvider({
  children,
}: PropsWithChildren) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
