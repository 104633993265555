import styled from "styled-components";
import { SecondaryTitle } from "./text/SecondaryTitle";
import { Button } from "./Button";

export interface SectionProps {
  title: string;
  cta?: {
    title: string;
    action: () => void;
  };
  children?: string | JSX.Element | JSX.Element[];
}

export const Section = (props: SectionProps) => {
  return (
    <StyledContainer>
      <StyledTitleContainer>
        <SecondaryTitle text={props.title} />
      </StyledTitleContainer>
      <StyledChildrenContainer>
        {props.children}

        {props.cta && (
          <Button title={props.cta.title} onClick={props.cta.action} />
        )}
      </StyledChildrenContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
`;

const StyledChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 458px;
`;
const StyledTitleContainer = styled.div`
  min-width: 21.875rem;
`;
