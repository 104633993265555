export const colors = {
  alert20: "#FE949420",
  alert80: "#FE949480",
  alertMessage: "#EB4A4A",
  tertiary: "#FEE58A",
  tertiary33: "#FEE58A33",
  primary: "#3CD3AD",
  primary10: "#3DCDAD10",
  primary33: "#3DCDAD33",
  primary50: "#3DCDAD50",
  primary80: "#3DCDAD80",
  primaryDisabled: "#9DE9D6",
  paleGreen: "#B8EADD",
  lightGreen: "#EBFBF7",
  ligthGrey: "#B4BEC054",
  grey: "#535654",
  middleGrey: "#D9D9D9",
  black: "#333333",
  white: "#FFFFFF",
  background: "#F5F8F7",
  silver: "#b4bec040",
  contentSecondary: "#535654",
  contentTertiary: "#757779",
  contentTertiary33: "#75777933",
  disabled: "#8F8F8F",
  disabled80: "#8F8F8F80",
  deactivated: "#F0F4F3",
  red: "#E7625C",
  paleRed: "#FED7D2",
  yellow: "#FFF0C8",
  orange: "#FFAA00",
  paleOrange: "#FFE3C2",
  blue: "#1BB0D6",
  paleBlue50: "#C2F0FC50",
  lightRed: "#FDF5FA",
};
