import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import styled from "styled-components";
import {
  AdminUserPayload,
  getToken,
  registerToken,
  selectAdminUser,
} from "./homeSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const AuthGoogle = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const adminUser = useAppSelector(selectAdminUser);
  const [errorAuth, setErrorAuth] = useState<boolean>(false);

  useEffect(() => {
    if (!adminUser) {
      dispatch(getToken());
    } else {
      navigate("/home");
    }
  }, [adminUser, dispatch, navigate]);

  useEffect(() => {
    const handleCredentialResponse = (res: { credential: string }) => {
      if (res) {
        const currentUser: AdminUserPayload = jwtDecode(res.credential);
        if (
          currentUser.hd === "flitcard.com" ||
          currentUser.hd === "qivia.fr"
        ) {
          dispatch(registerToken(res.credential));
        } else {
          setErrorAuth(true);
        }
      }
    };

    google.accounts.id.initialize({
      client_id: import.meta.env["VITE_GOOGLE_CLIENT_ID"] as string,
      callback: handleCredentialResponse,
      allowed_parent_origin: "http://localhost:3002",
      ux_mode: "popup",
    });

    google.accounts.id.renderButton(
      document.getElementById("loginGoogle") as HTMLElement,
      {
        theme: "outline",
        size: "large",
        type: "standard",
      },
    );
    google.accounts.id.prompt();
  }, [adminUser, dispatch]);

  return (
    <StyledContainer>
      <br />
      <div>{t("authGoogle.title")}</div>
      <br />
      <div id="loginGoogle" />
      <br />
      {errorAuth && t("authGoogle.error")}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
