import { colors } from "../../styles/colors";
import styled from "styled-components";
import { Spacer } from "../Spacer";
import QuestionIcon from "../../assets/blackQuestionmark.svg?react";
import { TooltipComponent } from "../Tooltip";
import { useTranslation } from "react-i18next";

type Status = "ENABLED" | "CREATED" | "DEACTIVATED" | "ERROR";

const statusProps = {
  ENABLED: {
    backgroundColor: colors.lightGreen,
    hasTooltip: false,
  },
  CREATED: {
    backgroundColor: colors.yellow,
    hasTooltip: true,
  },
  DEACTIVATED: {
    backgroundColor: colors.background,
    hasTooltip: false,
  },
  ERROR: {
    backgroundColor: colors.red,
    hasTooltip: true,
  },
};

export interface TableLabelDriverProps {
  status: Status;
}

export const TableLabelDriver = (props: TableLabelDriverProps) => {
  const driverStatus = statusProps[props.status];
  const { t } = useTranslation();

  return (
    <StyledContainer $backgroundColor={driverStatus.backgroundColor}>
      <Spacer x={0.5} />
      <StyledTextContainer>
        {t(`drivers.status.${props.status.toLowerCase()}`)}
      </StyledTextContainer>
      {driverStatus.hasTooltip && (
        <StyledQuestionIcon>
          <Spacer x={0.5} />
          <QuestionIcon data-tooltip-id={`drivers.status.${props.status}`} />
          <TooltipComponent
            id={`drivers.status.${props.status}`}
            place={"bottom"}
            title={
              t(`drivers.status.${props.status.toLowerCase()}.tooltip.title`) ||
              ""
            }
            content={
              t(
                `drivers.status.${props.status.toLowerCase()}.tooltip.content`,
              ) || ""
            }
          />
        </StyledQuestionIcon>
      )}
      <Spacer x={0.5} />
    </StyledContainer>
  );
};
const StyledQuestionIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const StyledTextContainer = styled.div`
  font-family: Inter;
  font-size: 0.875rem;
  height: 1.5rem;
  line-height: 1.5rem;
  &::first-letter {
    text-transform: uppercase;
  }
  font-weight: 400;
  cursor: default;
`;

const StyledContainer = styled.div<{ $backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  color: ${({ $backgroundColor }) =>
    $backgroundColor === colors.red ? "white" : "black"};
  height: 1.5rem;
  width: max-content;
`;
