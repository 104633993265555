import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { Button } from "../Button";
import { Spacer } from "../Spacer";
import { Support } from "../Support";
import { Title } from "../text/Title";
import { SearchBar } from "../SearchBar";
import { SidePanel } from "../SidePanel";
import { MouseEvent } from "react";
import { useState } from "react";

export interface PageProps<T extends object> {
  title: string;
  cta?: {
    title: string;
    action: () => void;
    width?: number;
    disabled?: boolean;
  };
  cta2?: {
    title: string;
    action: () => void;
    width?: number;
  };
  pageName?: string;
  isEmptyTable?: boolean;
  children?: string | JSX.Element | JSX.Element[];
  toaster?: JSX.Element;
  searchBar?: {
    values: T[];
    setFilterValues: (filteredValues: T[]) => void;
    keysToTranslate?: Array<keyof T>;
    findKeysToTranslate?: (key: keyof T, value: string) => string;
  };
  sidePanel?: {
    isSidePanelDisplayed: boolean;
    onCloseSidePanel: () => void;
    sidePanelChildren: string | JSX.Element | JSX.Element[];
    sidePanelStickyBottom: string | JSX.Element | JSX.Element[];
  };
}

export const Page = <T extends object>(props: PageProps<T>) => {
  const { t } = useTranslation();
  const [isSearchResult, setIsSearchResult] = useState<boolean>(false);
  const setSearchValues = (values: T[]) => {
    props.searchBar && props.searchBar.setFilterValues(values);
    setIsSearchResult(values.length === 0);
  };
  const clickOnPage = (targetElement: string) => {
    if (targetElement !== "sidePanelButton") {
      props.sidePanel && props.sidePanel.onCloseSidePanel();
    }
  };

  return (
    <StyledFrame
      onClick={(e: MouseEvent<HTMLElement>) => {
        clickOnPage((e.target as HTMLElement)?.id);
      }}
    >
      <Spacer x={2.5} />
      <StyledContent>
        {props.toaster}
        <Spacer y={1.75} />
        <StyledTitle>
          <Title text={props.title} />
          {props.searchBar &&
            (!props.isEmptyTable || (props.isEmptyTable && isSearchResult)) && (
              <SearchBar
                setFilterValues={setSearchValues}
                placeholder={t("home.searchbar")}
                values={props.searchBar.values}
                keysToTranslate={props.searchBar.keysToTranslate}
                findKeysToTranslate={props.searchBar.findKeysToTranslate}
              />
            )}
        </StyledTitle>
        {props.isEmptyTable && props.pageName ? (
          <StyledEmptyTable>
            {props.children}
            <Spacer y={9} />
            <StyledEmptyTableTitle>
              {isSearchResult
                ? t(`${props.pageName}.page.emptySearch.text`)
                : t(`${props.pageName}.page.empty.title`)}
            </StyledEmptyTableTitle>
            <Spacer y={0.5} />
            <StyledEmptyTableBody>
              {!isSearchResult && t(`${props.pageName}.page.empty.text`)}
            </StyledEmptyTableBody>
            <Spacer y={2.5} />
            <StyledMultipleCta>
              {props.cta && !isSearchResult && (
                <Button
                  title={props.cta.title}
                  onClick={props.cta.action}
                  width={props.cta.width || 11}
                  disabled={props.cta.disabled}
                />
              )}
              <Spacer x={2} />
              {props.cta2 && (
                <Button
                  title={props.cta2.title}
                  onClick={props.cta2.action}
                  width={props.cta2.width}
                  withoutBackground
                  borderColor={colors.black}
                />
              )}
            </StyledMultipleCta>
            <Spacer y={4} />
          </StyledEmptyTable>
        ) : (
          <>
            {props.cta && <Spacer y={1.75} />}
            <StyledCta>
              {props.cta && (
                <>
                  <Button
                    title={props.cta.title}
                    onClick={props.cta.action}
                    width={props.cta.width || 11}
                  />
                  <Spacer x={1.75} />
                </>
              )}
              {props.cta2 && (
                <>
                  <Button
                    title={props.cta2.title}
                    onClick={props.cta2.action}
                    width={props.cta2.width}
                    withoutBackground
                    borderColor={colors.primary80}
                  />
                  <Spacer x={1.75} />
                </>
              )}
            </StyledCta>
            <Spacer y={1.75} />
            {props.children}
          </>
        )}
        {props.sidePanel && (
          <SidePanel
            visible={props.sidePanel.isSidePanelDisplayed}
            onClose={props.sidePanel.onCloseSidePanel}
            stickyBottom={props.sidePanel.sidePanelStickyBottom}
          >
            {props.sidePanel.sidePanelChildren}
          </SidePanel>
        )}
        <Spacer y={2.5} />
      </StyledContent>
      <StyledSupport>
        <Support />
      </StyledSupport>
      <Spacer x={2.5} />
    </StyledFrame>
  );
};

const StyledTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StyledSupport = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const StyledCta = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const StyledFrame = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 0.75rem 0 0 0.75rem;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
`;

const StyledEmptyTable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledEmptyTableTitle = styled.div`
  font-weight: 700;
  font-size: 1.75rem;
  &::first-letter {
    text-transform: uppercase;
  }
  font-family: "Inter";
  text-align: center;
  color: ${colors.black};
`;

const StyledEmptyTableBody = styled.div`
  font-weight: 400;
  font-size: 1rem;
  &::first-letter {
    text-transform: uppercase;
  }
  padding-top: 0.5rem;
  font-family: "Inter";
  text-align: center;
  color: ${colors.contentTertiary};
`;

const StyledMultipleCta = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
