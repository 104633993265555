import { useParams } from "react-router-dom";
import {
  PublicCardsListDisplayed,
  PublicCardsListDisplayedRow,
} from "./electricApi";
import {
  getActiveCardWithourRfidListAsync,
  selectActiveCardWithoutRfidList,
  selectActiveCardWithoutRfidListStatus,
  selectUpdateCardRfidStatus,
} from "./electricSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useTranslation } from "react-i18next";
import {
  companiesListAsync,
  selectCompaniesList,
  selectCompaniesListStatus,
} from "../companiesSlice";
import { useCallback, useEffect, useState } from "react";
import { LittleCardAsset } from "@qivia/ui/src/assets/assets";
import styled from "styled-components";
import { Spacer, triggerToast, unreachable, VirtualTableDS } from "@qivia/ui";

import { CardRfidModal } from "./cardRfidModal";

export const CardRfidTab = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const params = useParams();
  const companyList = useAppSelector(selectCompaniesList);
  const companiesListStatus = useAppSelector(selectCompaniesListStatus);
  const cardUpdatedStatus = useAppSelector(selectUpdateCardRfidStatus);
  const activeCardsWithoutRfidList = useAppSelector(
    selectActiveCardWithoutRfidList,
  );
  const activeCardsWithoutRfidListStatus = useAppSelector(
    selectActiveCardWithoutRfidListStatus,
  );

  const [listDisplayed, setListDisplayed] = useState<
    PublicCardsListDisplayedRow[]
  >(activeCardsWithoutRfidList);

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] =
    useState<Partial<PublicCardsListDisplayedRow> | null>(null);

  const onCloseModal = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const render =
    (row: PublicCardsListDisplayedRow) =>
    (key: keyof PublicCardsListDisplayed) => {
      switch (key) {
        case "status":
        case "vehicleInfo":
        case "companyName":
          return row[key];
        case "last4Digits":
          return (
            <StyledRow>
              <LittleCardAsset />
              <Spacer x={1} />
              **{row[key]}
            </StyledRow>
          );
      }
      unreachable(key);
    };

  const display = (props: PublicCardsListDisplayedRow) => {
    setIsModalVisible(true);
    setSelectedRow(props);
  };

  useEffect(() => {
    if (companiesListStatus === "idle") {
      void dispatch(companiesListAsync());
    }
  }, [companiesListStatus, dispatch]);

  useEffect(() => {
    if (cardUpdatedStatus === "success") {
      void dispatch(getActiveCardWithourRfidListAsync(companyList));
      triggerToast(t("electric.cardRfid.success") || "", "valid");
    } else if (cardUpdatedStatus === "failed") {
      triggerToast(t("electric.cardRfid.failure") || "", "error");
    }
  }, [dispatch, cardUpdatedStatus, companyList, t]);

  useEffect(() => {
    if (activeCardsWithoutRfidListStatus === "idle" && companyList.length > 0) {
      void dispatch(getActiveCardWithourRfidListAsync(companyList));
    }
  }, [
    dispatch,
    companiesListStatus,
    activeCardsWithoutRfidListStatus,
    companyList,
  ]);

  useEffect(() => {
    setListDisplayed(activeCardsWithoutRfidList);
  }, [activeCardsWithoutRfidList]);

  const searchBarProps = {
    values: activeCardsWithoutRfidList,
    setFilterValues: setListDisplayed,
    keysToIgnore: ["uuid"],
  };

  const headers = {
    last4Digits: {
      text: t("electric.cardRfid.last4digits"),
    },
    vehicleInfo: {
      text: t("electric.cardRfid.vehicle"),
    },
    companyName: {
      text: t("electric.cardRfid.company"),
    },
    status: {
      text: t("electric.cardRfid.status"),
    },
  };

  if (params.tab !== "card_rfid") {
    return;
  }

  return (
    <>
      <CardRfidModal
        visible={isModalVisible}
        onCloseModal={() => onCloseModal()}
        selectedRow={selectedRow}
      />
      <VirtualTableDS<keyof PublicCardsListDisplayed, PublicCardsListDisplayed>
        data={listDisplayed}
        headers={headers}
        render={render}
        searchBar={searchBarProps}
        onClickRow={display}
      />
    </>
  );
};

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
