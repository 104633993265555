import styled from "styled-components";
import { colors } from "../../styles/figmaColors";
import { Spacer } from "./Spacer";
import {
  ChevronLeftOutlined,
  ChevronRightOutlined,
} from "../materialUi/materialUi";

export interface EllipseMenuProps {
  sections: string[];
  selectedSection: number;
  setSelectedSection: (section: number) => void;
}

export const EllipseMenu = (props: EllipseMenuProps) => {
  return (
    <>
      <Spacer y={1.25} />
      <StyledEllipsesRow>
        <ChevronLeftOutlined
          onClick={() =>
            props.selectedSection > 0 &&
            props.setSelectedSection(props.selectedSection - 1)
          }
        />
        <Spacer x={0.75} />
        {props.sections.map((section, index) => {
          return (
            <>
              <Spacer x={0.25} />
              <StyledEllipse
                key={"ellipse" + index}
                onClick={() => props.setSelectedSection(index)}
                $isCurrent={props.selectedSection === index}
              />
              <Spacer x={0.25} />
            </>
          );
        })}
        <Spacer x={0.75} />
        <ChevronRightOutlined
          onClick={() =>
            props.selectedSection < props.sections.length - 1 &&
            props.setSelectedSection(props.selectedSection + 1)
          }
        />
      </StyledEllipsesRow>
      <Spacer y={0.75} />
    </>
  );
};

const StyledEllipse = styled.div<{ $isCurrent: boolean }>`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.5rem;
  background-color: ${({ $isCurrent }) =>
    $isCurrent
      ? colors["colors/text/black"]
      : colors["colors/surfaces/background/background_level1"]};
  cursor: pointer;
`;

const StyledEllipsesRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: 1rem;
    height: 1rem;
    cursor: pointer;
  }
`;
