import { jwtDecode } from "jwt-decode";
import { useCallback, useEffect } from "react";
import {
  AdminUserPayload,
  registerToken,
  removeToken,
  selectAdminUser,
} from "./homeSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useNavigate } from "react-router-dom";

export default function useAuth() {
  const adminUser = useAppSelector(selectAdminUser);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleCredentialResponse = useCallback(
    (res: { credential: string }) => {
      if (res) {
        const currentUser: AdminUserPayload = jwtDecode(res.credential);
        if (
          currentUser.hd === "flitcard.com" ||
          currentUser.hd === "qivia.fr"
        ) {
          dispatch(registerToken(res.credential));
        } else {
          navigate("/login");
        }
      }
    },
    [dispatch, navigate],
  );

  const refreshSession = useCallback(() => {
    // if new Date() > token expiration date - 10min, we refresh the token
    if (adminUser && new Date((adminUser.exp - 10 * 60) * 1000) <= new Date()) {
      google.accounts.id.initialize({
        client_id: import.meta.env["VITE_GOOGLE_CLIENT_ID"] as string,
        callback: handleCredentialResponse,
        auto_select: true,
        cancel_on_tap_outside: false,
      });
      google.accounts.id.prompt();
    }
  }, [adminUser, handleCredentialResponse]);

  useEffect(() => {
    let timeOut: NodeJS.Timeout;
    if (adminUser) {
      const expTime = adminUser.exp * 1000 - 10 * 60 * 1000;
      const currentTime = Date.now();
      const delay = expTime - currentTime;
      if (delay >= 0) {
        timeOut = setTimeout(() => refreshSession(), delay);
      } else {
        refreshSession();
      }
    }

    return () => clearTimeout(timeOut);
  });

  // Update redux store when detecting a change of the localStorage's token value
  window.addEventListener("storage", (event) => {
    if (event.key === "token") {
      const token = localStorage.getItem("token");
      try {
        if (!token) {
          throw new Error("no token detected");
        } else {
          jwtDecode(token); // throw an error if invalid format or expired
          dispatch(registerToken(token));
        }
      } catch (e) {
        navigate("/login`");
        dispatch(removeToken());
        return;
      }
    }
  });

  return {
    refreshSession,
  };
}
