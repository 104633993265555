import { TextCapitalized } from "../text/TextCapitalized";
import { ElementInitialCircle } from "../ElementInitialCircle";
import { colors } from "../../../styles/figmaColors";
import styled from "styled-components";
import { sizeOptionsInitialElement } from "../ElementInitial";

type AvatarColor =
  | "red"
  | "pink"
  | "purpleDark"
  | "mint"
  | "yellow"
  | "purpleLight"
  | "blue"
  | "orange"
  | "green"
  | "grey"
  | "darkGrey";

const colorDict: {
  [key in AvatarColor]: { backgroundColor: string; textColor: string };
} = {
  red: {
    backgroundColor: "#F6E7F4",
    textColor: "#C3755C",
  },
  pink: {
    backgroundColor: "#F6E7F4",
    textColor: "#C35CB3",
  },
  purpleDark: {
    backgroundColor: "#ECE7F6",
    textColor: "#7D5CC3",
  },
  mint: {
    backgroundColor: "#E7F6F3",
    textColor: "#5CC3B1",
  },
  yellow: {
    backgroundColor: "#F6F5E7",
    textColor: "#C3BF5C",
  },
  purpleLight: {
    backgroundColor: "#F2E7F6",
    textColor: "#A95CC3",
  },
  blue: {
    backgroundColor: "#E7EFF6",
    textColor: "#5C92C3",
  },
  orange: {
    backgroundColor: "#F4EFE0",
    textColor: "#C3A65C",
  },
  green: {
    backgroundColor: "#E7F6EA",
    textColor: "#5CC373",
  },
  grey: {
    backgroundColor: "#E7E9EA",
    textColor: colors["colors/text/white"],
  },
  darkGrey: {
    backgroundColor: colors["colors/button/avatar/grey"],
    textColor: colors["colors/text/white"],
  },
};

type AvatarProps = {
  color: AvatarColor;
  firstName?: string;
  lastName?: string;
  size: sizeOptionsInitialElement;
  isHover?: boolean;
  clickable?: boolean;
};

export const Avatar = (props: AvatarProps) => {
  const { color, firstName, lastName, size, isHover, clickable } = props;
  let initialFirst;
  let initialLast;
  if (firstName) {
    initialFirst = firstName.charAt(0).toUpperCase();
  } else initialFirst = "";
  if (lastName) {
    initialLast = lastName.charAt(0).toUpperCase();
  } else initialLast = "";

  return (
    <ElementInitialCircle
      backgroundColor={colorDict[color].backgroundColor}
      color={colorDict[color].textColor}
      size={size}
      isHover={isHover}
      clickable={clickable}
      text={
        <StyledFlex>
          <TextCapitalized>{initialFirst}</TextCapitalized>
          <TextCapitalized>{initialLast}</TextCapitalized>
        </StyledFlex>
      }
    />
  );
};

const StyledFlex = styled.div`
  display: flex;
`;
