import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { QueryStatus, authAxios } from "../../../src/utils";
import {
  ExportBankStatement,
  ExportStatement,
  GenerateStatementApi,
  GetBankStatementsApi,
  GetStatementsApi,
} from "./statementApi";
import { RootState } from "../../redux/store";
import axios from "axios";

export interface StatementsState {
  statementStatus: QueryStatus;
  statementsListStatus: QueryStatus;
  statementsList: GetStatementsApi[];
  bankStatementsListStatus: QueryStatus;
  bankStatementsList: GetBankStatementsApi[];
  statementsMultipleStatus: QueryStatus;
  statementExportStatus: QueryStatus;
  statementExportLink: ExportStatement;
  bankStatementExportStatus: QueryStatus;
  bankStatementExportLink: ExportBankStatement;
  statementDownloadStatus: QueryStatus;
  bankStatementDownloadStatus: QueryStatus;
}

const initialState: StatementsState = {
  statementStatus: "idle",
  statementsListStatus: "idle",
  statementsList: [],
  bankStatementsListStatus: "idle",
  bankStatementsList: [],
  statementsMultipleStatus: "idle",
  statementExportStatus: "idle",
  statementExportLink: {
    presignedUrl: null,
    fileName: null,
  },
  bankStatementExportStatus: "idle",
  bankStatementExportLink: {
    presignedUrl: null,
    fileName: null,
  },
  statementDownloadStatus: "idle",
  bankStatementDownloadStatus: "idle",
};

export const statementAsync = createAsyncThunk(
  "statements/call",
  async (payload: GenerateStatementApi) => {
    const axios = authAxios();
    await axios.post(`statement_generate`, payload);
  },
);

export const statementsMultipleAsync = createAsyncThunk(
  "statementsMultiple/call",
  async () => {
    const axios = authAxios();
    await axios.post(`statements_multiple_generate`);
  },
);

export const statementsListAsync = createAsyncThunk(
  "statementsList/call",
  async () => {
    const axios = authAxios();
    const response = await axios.get<GetStatementsApi[]>(`statements`);
    return response.data.sort((a, b) => {
      if (a.companyName.toLowerCase() < b.companyName.toLowerCase()) {
        return -1;
      } else if (a.companyName.toLowerCase() > b.companyName.toLowerCase()) {
        return 1;
      } else {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      }
    });
  },
);

export const bankStatementsListAsync = createAsyncThunk(
  "bankStatementsList/call",
  async () => {
    const axios = authAxios();
    const response = await axios.get<GetBankStatementsApi[]>(`bank_statements`);
    return response.data.sort((a, b) => {
      if (a.companyName.toLowerCase() < b.companyName.toLowerCase()) {
        return -1;
      } else if (a.companyName.toLowerCase() > b.companyName.toLowerCase()) {
        return 1;
      } else {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      }
    });
  },
);

export const statementExportAsync = createAsyncThunk(
  "statementExport/call",
  async (payload: { bucketFileName: string; fileName: string }) => {
    const axios = authAxios();
    const response = await axios.get<{ presignedUrl: string }>(
      `/statement/${payload.bucketFileName}/export`,
    );
    return {
      presignedUrl: response.data.presignedUrl,
      fileName: payload.fileName,
    };
  },
);

export const bankStatementExportAsync = createAsyncThunk(
  "bankStatementExport/call",
  async (payload: { bucketFileName: string; fileName: string }) => {
    const axios = authAxios();
    const response = await axios.get<{ presignedUrl: string }>(
      `/bank_statement/${payload.bucketFileName}/export`,
    );
    return {
      presignedUrl: response.data.presignedUrl,
      fileName: payload.fileName,
    };
  },
);

export const statementDownloadAsync = createAsyncThunk(
  "statementDownload/call",
  async (statementExportLink: ExportStatement) => {
    if (statementExportLink.presignedUrl && statementExportLink.fileName) {
      const response = await axios({
        url: statementExportLink.presignedUrl,
        method: "GET",
        responseType: "blob",
      });

      const a = document.createElement("a");
      a.download = statementExportLink.fileName || "yourStatementFile.xlsx";
      const url = window.URL.createObjectURL(new Blob([response.data]));
      a.href = url;
      a.click();
    }
  },
);

export const bankStatementDownloadAsync = createAsyncThunk(
  "bankStatementDownload/call",
  async (bankStatementExportLink: ExportBankStatement) => {
    if (
      bankStatementExportLink.presignedUrl &&
      bankStatementExportLink.fileName
    ) {
      const response = await axios({
        url: bankStatementExportLink.presignedUrl,
        method: "GET",
        responseType: "blob",
      });

      const a = document.createElement("a");
      a.download =
        bankStatementExportLink.fileName || "yourBankStatementFile.pdf";
      const url = window.URL.createObjectURL(new Blob([response.data]));
      a.href = url;
      a.click();
    }
  },
);

export const statementsSlice = createSlice({
  name: "statements",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(statementAsync.pending, (state) => {
        state.statementStatus = "processing";
      })
      .addCase(statementAsync.fulfilled, (state) => {
        state.statementStatus = "success";
      })
      .addCase(statementAsync.rejected, (state) => {
        state.statementStatus = "failed";
      })
      .addCase(statementsMultipleAsync.pending, (state) => {
        state.statementsMultipleStatus = "processing";
      })
      .addCase(statementsMultipleAsync.fulfilled, (state) => {
        state.statementsMultipleStatus = "success";
      })
      .addCase(statementsMultipleAsync.rejected, (state) => {
        state.statementsMultipleStatus = "failed";
      })
      .addCase(statementsListAsync.pending, (state) => {
        state.statementsListStatus = "processing";
      })
      .addCase(statementsListAsync.fulfilled, (state, action) => {
        state.statementsList = action.payload;
        state.statementsListStatus = "success";
        state.statementStatus = "idle";
      })
      .addCase(statementsListAsync.rejected, (state) => {
        state.statementsListStatus = "failed";
      })
      .addCase(bankStatementsListAsync.pending, (state) => {
        state.bankStatementsListStatus = "processing";
      })
      .addCase(bankStatementsListAsync.fulfilled, (state, action) => {
        state.bankStatementsList = action.payload;
        state.bankStatementsListStatus = "success";
      })
      .addCase(bankStatementsListAsync.rejected, (state) => {
        state.bankStatementsListStatus = "failed";
      })
      .addCase(statementExportAsync.pending, (state) => {
        state.statementExportStatus = "processing";
      })
      .addCase(statementExportAsync.fulfilled, (state, action) => {
        state.statementExportLink = action.payload;
        state.statementExportStatus = "success";
      })
      .addCase(statementExportAsync.rejected, (state) => {
        state.statementExportStatus = "failed";
      })
      .addCase(bankStatementExportAsync.pending, (state) => {
        state.bankStatementExportStatus = "processing";
      })
      .addCase(bankStatementExportAsync.fulfilled, (state, action) => {
        state.bankStatementExportLink = action.payload;
        state.bankStatementExportStatus = "success";
      })
      .addCase(bankStatementExportAsync.rejected, (state) => {
        state.bankStatementExportStatus = "failed";
      })
      .addCase(statementDownloadAsync.pending, (state) => {
        state.statementDownloadStatus = "processing";
      })
      .addCase(statementDownloadAsync.fulfilled, (state) => {
        state.statementExportLink.presignedUrl = null;
        state.statementExportLink.fileName = null;
        state.statementDownloadStatus = "success";
        state.statementExportStatus = "idle";
      })
      .addCase(statementDownloadAsync.rejected, (state) => {
        state.statementDownloadStatus = "failed";
      })
      .addCase(bankStatementDownloadAsync.pending, (state) => {
        state.bankStatementDownloadStatus = "processing";
      })
      .addCase(bankStatementDownloadAsync.fulfilled, (state) => {
        state.bankStatementExportLink.presignedUrl = null;
        state.bankStatementExportLink.fileName = null;
        state.bankStatementDownloadStatus = "success";
        state.bankStatementExportStatus = "idle";
      })
      .addCase(bankStatementDownloadAsync.rejected, (state) => {
        state.bankStatementDownloadStatus = "failed";
      });
  },
});

export const selectStatementsList = (state: RootState) =>
  state.statement.statementsList;
export const selectStatementsListStatus = (state: RootState) =>
  state.statement.statementsListStatus;
export const selectBankStatementsList = (state: RootState) =>
  state.statement.bankStatementsList;
export const selectBankStatementsListStatus = (state: RootState) =>
  state.statement.bankStatementsListStatus;
export const selectStatementCreationStatus = (state: RootState) =>
  state.statement.statementStatus;
export const selectStatementsMultipleCreationStatus = (state: RootState) =>
  state.statement.statementsMultipleStatus;
export const selectStatementExportStatus = (state: RootState) =>
  state.statement.statementExportStatus;
export const selectStatementExportLink = (state: RootState) =>
  state.statement.statementExportLink;
export const selectBankStatementExportStatus = (state: RootState) =>
  state.statement.bankStatementExportStatus;
export const selectBankStatementExportLink = (state: RootState) =>
  state.statement.bankStatementExportLink;

export default statementsSlice.reducer;
