import styled from "styled-components";
import Select, { SelectInstance } from "react-select";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { capitalize } from "../../format";
import { colors } from "../../styles/figmaColors";
import { Spacer } from "./Spacer";
import { typographies } from "../../styles/figmaTypographies";
import { TextCapitalized } from "./text/TextCapitalized";

interface Option<T extends string> {
  readonly value: T;
  readonly label: string;
  readonly disabled?: boolean;
}

interface SelectDSProps<T extends string> {
  label: string;
  options: Option<T>[];
  placeholder?: string;
  onChange: (selectedOption: Option<T> | null) => void;
  value: Option<T> | undefined | null;
  error?: string;
  width?: number;
  allWidth?: boolean;
  withoutSpacerBottom?: boolean;
  disabled?: boolean;
  isMandatory?: boolean;
  withoutErrorMessage?: boolean;
}

export const SelectDS = <T extends string>(props: SelectDSProps<T>) => {
  const { t } = useTranslation();
  const typoContent = typographies["Body/S"];
  const selectRef = useRef<SelectInstance<Option<T>>>(null);

  const [isInputFocus, setIsInputFocus] = useState<boolean>(false);

  const isWidthSet = !!(props.allWidth || props.width);

  const handleChange = (selectedOption: Option<T> | null) => {
    props.onChange(selectedOption);
  };

  const capitalizeOptions = (options: Option<T>[]) => {
    const capitalizedOptions = options.map((option) => {
      const opt = {
        ...option,
        label: option.label?.charAt(0).toUpperCase() + option.label?.slice(1),
      };
      return opt;
    });
    return capitalizedOptions;
  };

  const capitalizeValueOption = (option: Option<T>) => {
    const capitalized = {
      ...option,
      label: option.label?.charAt(0).toUpperCase() + option.label?.slice(1),
    };
    return capitalized;
  };

  const [menuIsOpen, setMenuIsOpen] = useState<boolean>();
  const [longestOptionWidth, setLongestOptionWidth] = useState<number>();
  const [isCalculatingWidth, setIsCalculatingWidth] = useState<boolean>(false);

  useEffect(() => {
    if (!longestOptionWidth && !isCalculatingWidth) {
      setTimeout(() => {
        setIsCalculatingWidth(true);
        selectRef?.current?.openMenu("first");
        selectRef?.current?.getMenuListRef(null);
        setMenuIsOpen(true);
      }, 1);
    }
  }, [longestOptionWidth, isCalculatingWidth]);

  const onMenuOpen = () => {
    if (!longestOptionWidth && isCalculatingWidth) {
      setTimeout(() => {
        const width =
          selectRef?.current?.menuListRef?.getBoundingClientRect().width;
        setLongestOptionWidth(width ? width / 16 : undefined);
        setIsCalculatingWidth(false);
        selectRef?.current?.onMenuClose();
        setMenuIsOpen(undefined);
      }, 1);
    }
  };

  const customStyles = {
    menu: () => ({
      paddingTop: "0.5rem",
      borderRadius: "0.5rem",
      height: isCalculatingWidth ? 0 : "100%",
      visibility: isCalculatingWidth
        ? ("hidden" as const)
        : ("visible" as const),
      justifyContent: "flex-start",
      width: "auto",
    }),
    menuList: () => ({
      display: "flex",
      flexDirection: "column" as const,
      alignItems: "center",
      borderRadius: "0.5rem",
      border: `0.0625rem solid ${colors["colors/borders/input/default"]}`,
      padding: "0.5rem",
      backgroundColor: `${colors["colors/surfaces/background/background_level0"]}`,
      maxHeight: "15rem",
      overflow: "auto",
      width: isWidthSet ? "100%" : "fit-content",
    }),
    option: (
      provided: unknown,
      state: { isFocused: boolean; isDisabled: boolean },
    ) => ({
      display: "flex",
      alignItems: "center",
      backgroundColor:
        !state.isDisabled && state.isFocused
          ? `${colors["colors/surfaces/background/background_level1"]}`
          : "",
      typoContent,
      fontSize: "0.875rem",
      borderLeft: `0.5rem solid transparent`,
      borderRight: `0.5rem solid transparent`,
      borderTop: `1rem solid transparent`,
      borderBottom: `1rem solid transparent`,
      borderRadius: "0.5rem",
      color: state.isDisabled
        ? `${colors["colors/text/veryLightGrey"]}`
        : `${colors["colors/text/black"]}`,
      cursor: state.isDisabled ? "not-allowed" : "pointer",
      height: "1rem",
      width: "100%",
      whiteSpace: "nowrap",
    }),
    control: () => ({
      display: "flex",
      height: "2rem",
      fontSize: "1rem",
      borderRadius: "0.25rem",
      outline: "none",
    }),
    container: (
      provided: unknown,
      state: { isFocused: boolean; isDisabled: boolean },
    ) => ({
      width: "100%",
      height: "2rem",
      borderRadius: "0.25rem",
      fontSize: "1rem",
      border: `0.0625rem solid ${
        props.error
          ? colors["colors/system/error/error_normal"]
          : state.isFocused
            ? colors["colors/accent/500"]
            : colors["colors/borders/input/default"]
      }`,
      "&:hover": {
        border: `0.0625rem solid ${
          props.error
            ? colors["colors/system/error/error_normal"]
            : state.isDisabled
              ? colors["colors/borders/input/default"]
              : !state.isFocused
                ? colors["colors/borders/input/hover"]
                : colors["colors/accent/500"]
        }`,
      },
      backgroundColor: state.isDisabled
        ? colors["colors/surfaces/background/background_level1"]
        : colors["colors/surfaces/background/background_level0"],
    }),
    singleValue: (provided: unknown, state: { isDisabled: boolean }) => ({
      typoContent,
      fontSize: "0.875rem",
      color: `${
        state.isDisabled
          ? colors["colors/text/lightGrey"]
          : colors["colors/text/black"]
      }`,
      width: "100%",
    }),
    valueContainer: (provided: unknown, state: { isDisabled: boolean }) => ({
      display: "flex",
      alignItems: "center",
      borderLeft: `0.5rem solid transparent`,
      borderRight: `0.5rem solid transparent`,
      borderTop: `0.25rem solid transparent`,
      borderBottom: `0.25rem solid transparent`,
      cursor: state.isDisabled ? "not-allowed" : "pointer",
      width:
        !isWidthSet && longestOptionWidth ? longestOptionWidth + "rem" : "100%",
      whiteSpace: "nowrap",
    }),
    dropdownIndicator: (
      provided: unknown,
      state: { isFocused: boolean; isDisabled: boolean },
    ) => ({
      display: "flex",
      width: "100%",
      alignItems: "center",
      border: `0.75rem solid transparent`,
      cursor: state.isDisabled ? "not-allowed" : "pointer",
      color: `${
        state.isDisabled
          ? colors["colors/text/lightGrey"]
          : colors["colors/text/black"]
      }`,
      transform: state.isFocused && !state.isDisabled ? "rotate(0.5turn)" : "",
      transition: "all 0.25s ease-out",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    placeholder: () => ({
      typoContent,
      fontSize: "0.875rem",
      color: `${colors["colors/text/lightGrey"]}`,
    }),
  };

  const containerWidth = props.allWidth
    ? "100%"
    : props.width
      ? `${props.width + 2}rem`
      : longestOptionWidth
        ? `${longestOptionWidth + 2}rem`
        : "100%";

  return (
    <StyledContainer $width={containerWidth}>
      {props.label && (
        <StyledLabel>
          <TextCapitalized>{props.label}</TextCapitalized>
          <Spacer x={0.5} />
          {props.isMandatory && <StyledMandatory>*</StyledMandatory>}
        </StyledLabel>
      )}
      <StyledBackground
        $width={containerWidth}
        $isError={props.error !== undefined}
        $isInputFocus={isInputFocus}
      >
        <Select<Option<T>>
          ref={selectRef}
          onChange={handleChange}
          options={capitalizeOptions(props.options)}
          placeholder={props.placeholder && capitalize(props.placeholder)}
          value={(props.value && capitalizeValueOption(props.value)) || null}
          isSearchable={false}
          noOptionsMessage={() =>
            capitalize(t("selectInput.noOptions.message"))
          }
          isOptionDisabled={(option) => option.disabled ?? false}
          onFocus={() => setIsInputFocus(true)}
          onBlur={() => setIsInputFocus(false)}
          styles={customStyles}
          menuPlacement="auto"
          menuPosition="fixed"
          isDisabled={props.disabled}
          menuIsOpen={menuIsOpen}
          onMenuOpen={onMenuOpen}
          blurInputOnSelect={true}
        />
        {props.error && (
          <>
            <Spacer y={0.25} />
            <StyledErrorMessage>
              <TextCapitalized>
                {!props.withoutErrorMessage ? props.error : ""}
              </TextCapitalized>
            </StyledErrorMessage>
          </>
        )}
      </StyledBackground>
      {!props.withoutSpacerBottom && <Spacer y={1.5} />}
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{ $width?: string }>`
  position: relative;
  width: ${({ $width }) => $width};
  & svg {
    width: 1rem;
    height: 1rem;
  }
`;

const StyledBackground = styled.div<{
  $isError: boolean;
  $isInputFocus: boolean;
  $width?: string;
}>`
  width: ${({ $width }) => $width};
  transition: all 0.4s ease-out;
  border: 0.25rem solid transparent;
  border-radius: 0.5rem;
  border-color: ${({ $isError, $isInputFocus }) =>
    !$isError && $isInputFocus && colors["colors/accent/250"]};
`;

const StyledLabel = styled.div`
  display: flex;
  ${typographies["Body/M"]};
  color: ${colors["colors/text/black"]};
  border-left: solid 0.25rem transparent;
`;

const StyledErrorMessage = styled.div`
  display: flex;
  ${typographies["Body/XXS"]};
  color: ${colors["colors/system/error/error_normal"]};
`;

const StyledMandatory = styled.div`
  color: ${colors["colors/system/error/error_normal"]};
`;
