import { colors } from "../../styles/colors";
import styled from "styled-components";
import Pin from "../../assets/pin.svg?react";
import { Spacer } from "../Spacer";
import { useTranslation } from "react-i18next";

type Status = "IN_CIRCULATION" | "ORDERED" | "DEACTIVATED";

const statusProps = {
  IN_CIRCULATION: {
    pinColor: colors.primary,
    backgroundColor: colors.primary33,
  },
  ORDERED: {
    pinColor: colors.orange,
    backgroundColor: colors.tertiary33,
  },
  DEACTIVATED: {
    pinColor: null,
    backgroundColor: colors.deactivated,
  },
};

interface categoryLabelProps {
  status: Status;
}
export const TableLabelVehicle = (props: categoryLabelProps) => {
  const vehicleStatus = statusProps[props.status];
  const { t } = useTranslation();

  return (
    <StyledContainer $backgroundColor={vehicleStatus.backgroundColor}>
      {vehicleStatus.pinColor && (
        <>
          <Spacer x={0.5} />
          <StyledPinContainer $pinColor={vehicleStatus.pinColor}>
            <Pin />
          </StyledPinContainer>
        </>
      )}
      <Spacer x={0.5} />

      <StyledTextContainer>
        {t(
          `vehicles.status.${
            props.status === "IN_CIRCULATION"
              ? "inCirculation"
              : props.status.toLowerCase()
          }`,
        )}
      </StyledTextContainer>
      <Spacer x={0.5} />
    </StyledContainer>
  );
};

const StyledPinContainer = styled.div<{
  $pinColor: string | null;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5rem;
  & circle {
    fill: ${({ $pinColor }) => $pinColor};
  }
`;
const StyledTextContainer = styled.div`
  font-family: Inter;
  font-size: 1rem;
  height: 1.5rem;
  line-height: 1.5rem;
  &::first-letter {
    text-transform: uppercase;
  }
  cursor: default;
`;

const StyledContainer = styled.div<{ $backgroundColor: string }>`
  display: flex;
  border-radius: 0.5rem;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  height: 1.5rem;
  width: max-content;
`;
