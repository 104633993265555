import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { QueryStatus, authAxios } from "../../utils";
import { RootState } from "../../redux/store";
import { DriversList, DriverApi, UpdateDriverPhoneInput } from "./driversApi";

export interface DriverState {
  driversList: DriversList;
  driversListStatus: QueryStatus;
  updateDriverPhoneStatus: QueryStatus;
}

const initialStateDriver: DriverState = {
  driversList: [],
  driversListStatus: "idle",
  updateDriverPhoneStatus: "idle",
};

export const driverListAsync = createAsyncThunk(
  "driverList/call",
  async (payload: { companyUuid: string }) => {
    const axios = authAxios();
    const response = await axios.get<DriverApi[]>(
      `/drivers/company/${payload.companyUuid}`,
    );
    return response.data.sort((a, b) => a.lastName.localeCompare(b.lastName));
  },
);

export const updateDriverPhoneAsync = createAsyncThunk(
  "driverList/update",
  async (payload: UpdateDriverPhoneInput) => {
    const axios = authAxios();
    await axios.post("/drivers/update_phone", payload);
  },
);

export const driverSlice = createSlice({
  name: "drivers",
  initialState: initialStateDriver,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(driverListAsync.pending, (state) => {
        state.driversListStatus = "processing";
      })
      .addCase(driverListAsync.fulfilled, (state, action) => {
        state.driversList = action.payload;
        state.driversListStatus = "success";
      })
      .addCase(driverListAsync.rejected, (state) => {
        state.driversListStatus = "failed";
      })
      .addCase(updateDriverPhoneAsync.pending, (state) => {
        state.updateDriverPhoneStatus = "processing";
      })
      .addCase(updateDriverPhoneAsync.fulfilled, (state) => {
        state.updateDriverPhoneStatus = "success";
        state.driversListStatus = "idle";
      })
      .addCase(updateDriverPhoneAsync.rejected, (state) => {
        state.updateDriverPhoneStatus = "failed";
        state.driversListStatus = "idle";
      });
  },
});

export const selectDriversList = (state: RootState) =>
  state.drivers.driversList;
export const selectDriversListStatus = (state: RootState) =>
  state.drivers.driversListStatus;
export const selectUpdateDriverPhoneStatus = (state: RootState) =>
  state.drivers.updateDriverPhoneStatus;

export default driverSlice.reducer;
