import styled from "styled-components";
import { colors } from "../styles/colors";
import { Button } from "./Button";
import { Spacer } from "./Spacer";
import PhoneSolid from "../assets/phoneSolid.svg?react";
import PhoneWhite from "../assets/phoneWhite.svg?react";
import Cross from "../assets/crossLittle.svg?react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TextCapitalized } from "./text/TextCapitalized";

export const Support = (props: { color?: string }) => {
  const [isSupportOpen, setIsSupportOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <StyledSupport>
      {isSupportOpen ? (
        <StyledSupportOpen>
          <StyledSupportCard>
            <Spacer y={1.25} />
            <StyledSupportCardTitle>
              <Spacer x={1.25} />
              <TextCapitalized>{t("dashboard.support.title")}</TextCapitalized>
              <Spacer x={1.25} />
            </StyledSupportCardTitle>
            <Spacer y={0.25} />
            <StyledFlex>
              <Spacer x={1.25} />
              <TextCapitalized>{t("dashboard.support.body")} </TextCapitalized>
              <Spacer x={1.25} />
            </StyledFlex>
            <Spacer y={1} />
            <StyledFlex>
              <Spacer x={1.25} />
              <StyledSupportCardInfo>
                {t("dashboard.support.phone")}
              </StyledSupportCardInfo>
              <Spacer x={1.25} />
            </StyledFlex>
            <Spacer y={1} />
            <StyledFlex>
              <Spacer x={1.25} />
              <StyledSupportCardInfo>
                {t("dashboard.support.mail")}
              </StyledSupportCardInfo>
              <Spacer x={1.25} />
            </StyledFlex>
            <Spacer y={1.25} />
          </StyledSupportCard>
          <Spacer y={0.75} />
          <Button
            title={t("dashboard.support.close")}
            onClick={() => setIsSupportOpen(false)}
            backgroundColor={colors.ligthGrey}
            LeftElement={<Cross />}
            borderRadius={1.75}
            withoutBackground={true}
            fontWeight={500}
            width={6.5}
            height={2.25}
            hasStrokeSvg={true}
          />
        </StyledSupportOpen>
      ) : (
        <Button
          title={t("dashboard.support")}
          onClick={() => setIsSupportOpen(true)}
          backgroundColor={
            props.color === "green" ? colors.primary : colors.ligthGrey
          }
          colorHover={
            props.color === "green" ? colors.primary80 : colors.ligthGrey
          }
          LeftElement={
            props.color === "green" ? <PhoneWhite /> : <PhoneSolid />
          }
          borderRadius={1.75}
          withoutBackground={props.color === "green" ? false : true}
          fontWeight={500}
          width={6.5}
          height={2.25}
        />
      )}
      <Spacer y={1.5} />
    </StyledSupport>
  );
};

const StyledSupportCard = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 17rem;
  border: 1px solid ${colors.ligthGrey};
  box-shadow: 0 0.25rem 0.5rem ${colors.background};
  border-radius: 0.5rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  align-items: flex-start;
  color: ${colors.black};
  background-color: ${colors.white};
`;

const StyledSupportOpen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const StyledSupportCardTitle = styled.div`
  display: flex;
  font-weight: 600;
`;

const StyledSupportCardInfo = styled.div`
  display: flex;
  font-weight: 600;
  border-radius: 8px;
  background-color: ${colors.background};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2.5rem;
`;

const StyledSupport = styled.div`
  position: absolute;
  z-index: 1;
`;

const StyledFlex = styled.div`
  display: flex;
  width: 100%;
`;
