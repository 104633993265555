import styled from "styled-components";
import Cross from "../assets/cross.svg?react";
import { colors } from "../styles/colors";
import { Spacer } from "./Spacer";
import { MouseEvent } from "react";

export interface ModalWithLeftPartProps {
  children?: string | JSX.Element | JSX.Element[];
  visible: boolean;
  title?: string;
  onClose?: () => void;
  leftPart: string | JSX.Element | JSX.Element[];
}
export const ModalWithLeftPart = (props: ModalWithLeftPartProps) => {
  const clickOnModal = (targetElement: string) => {
    if (
      targetElement === "backgroundModal" ||
      targetElement === "backgroundModalWithLeftPart"
    ) {
      props.onClose && props.onClose();
    }
  };

  return (
    <StyledBackground
      $visible={props.visible}
      id="backgroundModalWithLeftPart"
      onClick={(e: MouseEvent<HTMLElement>) =>
        clickOnModal((e.target as HTMLElement)?.id)
      }
    >
      <Spacer x={3} />
      <StyledColumn>
        <Spacer y={4} />
        <StyledContainer>
          <StyledLeftModal>{props.leftPart}</StyledLeftModal>
          <Spacer x={4} />
          <StyledRightModal>
            <Spacer y={1.5} />
            <StyledAroundCloseIcon onClick={props.onClose}>
              <Cross />
            </StyledAroundCloseIcon>
            <Spacer y={1} />
            {props.title && <StyledModalTitle>{props.title}</StyledModalTitle>}
            <Spacer y={1} />
            {props.children}
            <Spacer y={1.5} />
          </StyledRightModal>
          <Spacer x={3} />
        </StyledContainer>
        <Spacer y={4} />
      </StyledColumn>
      <Spacer x={3} />
    </StyledBackground>
  );
};

const StyledAroundCloseIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5rem;
  min-width: 2.5rem;
  min-height: 2.5rem;
  cursor: pointer;
  margin-left: auto;
  &:hover {
    background-color: ${colors.background};
  }
`;

const StyledModalTitle = styled.div`
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 600;
  &::first-letter {
    text-transform: uppercase;
  }
  font-style: normal;
  box-sizing: border-box;
  padding: 0.75rem 0rem;
  border-bottom: 1px solid ${colors.middleGrey};
  width: 100%;
`;

const StyledRightModal = styled.div`
  display: flex;
  height: 42rem;
  width: 100%;
  align-content: space-between;
  flex-direction: column;
  overflow: auto;
`;

const StyledLeftModal = styled.div`
  display: flex;
  align-items: flex-start;
  line-height: 2.5rem;
  flex-direction: row;
  height: 42rem;
  min-width: 23rem;
  background-color: ${colors.background};
`;

const StyledContainer = styled.div`
  display: flex;
  width: 70rem;
  border-radius: 0.5rem;
  background-color: white;
  border: solid 1px ${colors.silver};
  flex-direction: row;
  justify-content: center;
  overflow: auto;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100vh;
  justify-content: center;
`;

const StyledBackground = styled.div<{ $visible: boolean }>`
  z-index: 10;
  position: fixed;
  display: ${({ $visible }) => ($visible ? "flex" : "none")};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  backdrop-filter: blur(0.25rem);
  background-color: ${colors.ligthGrey};
  box-shadow:
    0px 0px 15px rgba(143, 143, 143, 0.15),
    0px 2px 5px rgba(143, 143, 143, 0.15);
  overflow: auto;
`;
