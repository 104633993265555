import styled from "styled-components";
import { colors } from "../styles/colors";

interface InitialCircleProps {
  letter: string;
  size: keyof typeof sizes;
  withoutBackground?: boolean;
  clickable?: boolean;
}

interface CircleProps {
  $fontSize: number;
  $diameter: number;
  $withoutBackground?: boolean;
  $clickable?: boolean;
}

const sizes = {
  large: {
    $diameter: 4.5,
    $fontSize: 2.25,
  },
  small: {
    $diameter: 2.5,
    $fontSize: 1.25,
  },
  xsmall: {
    $diameter: 1.25,
    $fontSize: 0.75,
  },
};

export const InitialCircle = (props: InitialCircleProps) => (
  <StyledCircle
    {...sizes[props.size]}
    $withoutBackground={props.withoutBackground}
    $clickable={props.clickable}
  >
    {props.letter}
  </StyledCircle>
);

const StyledCircle = styled.div<CircleProps>`
  width: ${({ $diameter }) => $diameter}rem;
  height: ${({ $diameter }) => $diameter}rem;
  border-radius: ${({ $diameter }) => $diameter / 2}rem;
  border: ${({ $withoutBackground }) =>
    $withoutBackground && "1px solid" + colors.black};
  line-height: ${({ $diameter }) => $diameter}rem;
  font-size: ${({ $fontSize }) => $fontSize}rem;
  color: ${({ $withoutBackground }) =>
    $withoutBackground ? colors.black : colors.white};
  text-align: center;
  font-family: inter;
  cursor: ${({ $clickable }) => ($clickable ? "pointer" : "default")};
  background-color: ${({ $withoutBackground }) =>
    $withoutBackground ? colors.white : colors.ligthGrey};
`;
