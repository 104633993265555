import styled from "styled-components";
import { colors } from "../styles/colors";
import Select from "react-select";
import { Spacer } from "./Spacer";
import { Option } from "./input/SelectInput";

export interface SelectTimeProps {
  beginningTime: number;
  endingTime: number;
  onChange: (value: string | null) => void;
  value: string | null;
  disabled?: boolean;
  error?: boolean;
}

export const SelectTime = (props: SelectTimeProps) => {
  const handleChange = (selectedOption: Option<string> | null) => {
    props.onChange(selectedOption && selectedOption.value);
  };

  const timeValue = (beginningTime: number, endingTime: number) => {
    const options: { value: string; label: string }[] = [];
    for (let i = beginningTime; i < endingTime; i++) {
      options.push({
        value: `${i < 10 ? "0" + i.toString() : i}:00:00`,
        label: `${i < 10 ? "0" + i.toString() : i} h`,
      });
    }
    return options as Option<string>[];
  };

  const options = timeValue(props.beginningTime, props.endingTime);
  const valueOption = options.find((option) => option.value === props.value);

  const customStyles = {
    menu: () => ({
      borderTop: `0.25rem solid transparent`,
    }),
    menuList: () => ({
      borderRadius: "0.5rem",
      border: `1px solid ${colors.ligthGrey}`,
      backgroundColor: `${colors.white}`,
      color: `${colors.grey}`,
      height: "9.5rem",
      overflow: "auto",
    }),
    option: (
      provided: unknown,
      state: { isFocused: boolean; isDisabled: boolean },
    ) => ({
      display: "flex",
      alignItems: "center",
      backgroundColor:
        !state.isDisabled && state.isFocused ? `${colors.background}` : "",
      fontFamily: "Inter",
      fontSize: "1rem",
      fontWeigth: "400",
      borderLeft: "0.5rem solid transparent",
      color: state.isDisabled ? `${colors.disabled}` : `${colors.grey}`,
      cursor: state.isDisabled ? "not-allowed" : "pointer",
      height: "3rem",
    }),
    control: () => ({
      display: "flex",
      height: "3rem",
      borderRadius: "0.5rem",
      fontSize: "1rem",
      outline: "none",
    }),
    container: (provided: unknown, state: { isDisabled: boolean }) => ({
      height: "3rem",
      borderRadius: "0.5rem",
      fontSize: "1rem",
      border: `1px solid ${
        props.error
          ? colors.red
          : state.isDisabled
          ? colors.ligthGrey
          : colors.middleGrey
      }`,
      backgroundColor: `transparent`,
    }),
    singleValue: (provided: unknown, state: { isDisabled: boolean }) => ({
      fontFamily: "Inter",
      fontSize: "1rem",
      fontWeigth: "400",
      color: state.isDisabled ? `${colors.disabled}` : `${colors.grey}`,
    }),
    valueContainer: (provided: unknown, state: { isDisabled: boolean }) => ({
      width: "5.5rem",
      display: "flex",
      alignItems: "center",
      borderLeft: `0.75rem solid transparent`,
      cursor: state.isDisabled ? "not-allowed" : "pointer",
    }),
    dropdownIndicator: (provided: unknown, state: { isDisabled: boolean }) => ({
      display: "flex",
      alignItems: "center",
      border: `0.5rem solid transparent`,
      color: state.isDisabled
        ? `${colors.middleGrey}`
        : `${colors.contentTertiary}`,
      cursor: state.isDisabled ? "not-allowed" : "pointer",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    placeholder: () => ({
      display: "none",
    }),
  };

  return (
    <SelectContainer>
      <Select<Option<string>>
        onChange={handleChange}
        options={options}
        value={valueOption}
        styles={customStyles}
        menuPlacement="auto"
        menuPosition="fixed"
        isSearchable={false}
        isDisabled={props.disabled}
      />
      <Spacer y={1.5} />
    </SelectContainer>
  );
};

const SelectContainer = styled.div`
  width: 5.5rem;
  height: 3rem;
`;
