import {
  ButtonDS,
  DownloadOutlined,
  NumericInputDS,
  SelectDS,
  Spacer,
  TableDS,
  TextCapitalized,
  dateFormatterDayMonthYearLong2Digits,
  triggerToast,
  unreachable,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import styled from "styled-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  companiesListAsync,
  selectCompaniesList,
  selectCompaniesListStatus,
} from "./../companiesSlice";
import {
  depositDownloadAsync,
  depositExportAsync,
  depositsListAsync,
  monthlyCreateAsync,
  selectDepositExportLink,
  selectDepositExportStatus,
  selectDepositsList,
  selectMonthlyCreateStatus,
} from "./depositSlice";
import { GetDepositsApi as DepositsList } from "./depositApi";
import { useParams } from "react-router-dom";

export const DepositTab = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();

  const [amountPerDeposit, setAmountPerDeposit] = useState<number | "">("");
  const companiesList = useAppSelector(selectCompaniesList);
  const companiesListStatus = useAppSelector(selectCompaniesListStatus);
  const monthlyCreateStatus = useAppSelector(selectMonthlyCreateStatus);
  const depositsList = useAppSelector(selectDepositsList);
  const depositExportStatus = useAppSelector(selectDepositExportStatus);
  const depositExportLink = useAppSelector(selectDepositExportLink);

  const [companySelected, setCompanySelected] = useState<string>("");
  const [displayError, setDisplayError] = useState<boolean>(false);

  const isValidSubmission = useMemo(
    () => companySelected !== "" && amountPerDeposit !== "",
    [amountPerDeposit, companySelected],
  );

  const optionCompany = useMemo(
    () =>
      companiesList.map((company) => {
        return {
          label: company.name,
          value: company.uuid,
        };
      }),
    [companiesList],
  );

  const valueOptionCompany = useMemo(
    () => optionCompany.find((option) => option.value === companySelected),
    [companySelected, optionCompany],
  );

  useEffect(() => {
    if (depositExportLink.presignedUrl && depositExportLink.fileName) {
      void dispatch(depositDownloadAsync(depositExportLink));
    }
  }, [depositExportLink, dispatch]);

  useEffect(() => {
    if (depositExportStatus === "success") {
      triggerToast(t("deposit.upload.success") || "", "valid");
    } else if (depositExportStatus === "failed") {
      triggerToast(t("deposit.upload.failure") || "", "error");
    }
  }, [depositExportStatus, t]);

  useEffect(() => {
    if (companiesListStatus === "idle") {
      void dispatch(companiesListAsync());
    }
  }, [companiesListStatus, dispatch]);

  useEffect(() => {
    if (companySelected) {
      void dispatch(depositsListAsync({ companyUuid: companySelected }));
    }
  }, [companySelected, dispatch]);

  useEffect(() => {
    if (monthlyCreateStatus === "success") {
      triggerToast(t("deposit.creation.success") || "", "valid");
    } else if (monthlyCreateStatus === "failed") {
      triggerToast(t("deposit.creation.failure") || "", "error");
    }
  }, [monthlyCreateStatus, t]);

  const clickToSubmit = useCallback(() => {
    if (!isValidSubmission) {
      setDisplayError(true);
    } else {
      setDisplayError(false);
      void dispatch(
        monthlyCreateAsync({
          companyUuid: companySelected,
          amount: amountPerDeposit === "" ? 0 : amountPerDeposit,
        }),
      );
      setAmountPerDeposit("");
    }
  }, [amountPerDeposit, companySelected, dispatch, isValidSubmission]);

  const headers = {
    companyName: {
      text: t("deposit.column.companyName"),
    },
    amount: {
      text: t("deposit.column.amount"),
    },
    number: {
      text: t("deposit.column.number"),
    },
    date: {
      text: t("deposit.column.date"),
    },
    createdAt: {
      text: t("deposit.column.createdAt"),
    },
    paidAt: {
      text: t("deposit.column.paidAt"),
    },
    uuid: {
      text: t("deposit.column.download"),
    },
  };

  if (params.tab !== "deposits") {
    return;
  }

  const render = (row: DepositsList) => (key: keyof DepositsList) => {
    switch (key) {
      case "companyName":
        return valueOptionCompany?.label;
      case "amount":
        return row[key] / 100 + " €";
      case "date":
        return dateFormatterDayMonthYearLong2Digits(new Date(row[key]));
      case "createdAt":
        return dateFormatterDayMonthYearLong2Digits(new Date(row[key]));
      case "paidAt":
        return row[key]
          ? dateFormatterDayMonthYearLong2Digits(new Date(row[key]))
          : "-";
      case "number":
        return row[key];
      case "uuid":
        return (
          <ButtonDS
            sizeButton={"S"}
            format={"hug"}
            buttonType={"secondary"}
            singleIcon={{ icon: <DownloadOutlined />, size: "S" }}
            onClick={() => {
              void dispatch(
                depositExportAsync({
                  uuid: row[key],
                  number: row["number"],
                }),
              );
            }}
          />
        );
    }
    unreachable(key);
  };

  return (
    <StyledContainer>
      <Spacer y={1} />
      <StyledHeader>
        <TextCapitalized>{t("deposit.select.company")}</TextCapitalized>
        <SelectDS
          label={""}
          value={valueOptionCompany}
          options={optionCompany}
          onChange={(selectedOption) =>
            setCompanySelected(selectedOption ? selectedOption.value : "")
          }
          error={
            (displayError &&
              companySelected === "" &&
              t("deposit.select.company.error")) ||
            undefined
          }
          allWidth
        />
        <TextCapitalized>{t("deposit.input.amountPerDeposit")}</TextCapitalized>
        <NumericInputDS
          value={amountPerDeposit}
          update={(value) => setAmountPerDeposit(value !== "" ? value : "")}
          floatNumber
          error={
            (displayError &&
              amountPerDeposit === "" &&
              t("deposit.select.amountPerDeposit.error")) ||
            undefined
          }
        />
        <ButtonDS
          format="hug"
          buttonType="primary"
          text={t("deposit.button.create")}
          onClick={clickToSubmit}
          disabled={!isValidSubmission}
        />
      </StyledHeader>
      <Spacer y={2} />
      <StyledTable>
        <TableDS<keyof DepositsList, DepositsList>
          data={depositsList}
          headers={headers}
          render={render}
        />
      </StyledTable>
      <Spacer y={3} />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 30rem;
`;

const StyledTable = styled.div`
  height: 100%;
  overflow: auto;
`;
