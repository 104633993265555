import {
  Button,
  Spacer,
  triggerToast,
  Modal,
  TextCapitalized,
  TableDS,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { GenerateStatementDisplayed } from "./statementApi";
import { unreachable } from "../../utils";
import {
  selectStatementCreationStatus,
  selectStatementsMultipleCreationStatus,
  statementAsync,
  statementsListAsync,
  statementsMultipleAsync,
} from "./statementSlice";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import {
  companiesListAsync,
  selectCompaniesList,
  selectCompaniesListStatus,
} from "./../companiesSlice";
import { GetCompaniesApi } from "./../companiesApi";
import { useParams } from "react-router-dom";

export const CreationTab = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const params = useParams();
  const companiesList = useAppSelector(selectCompaniesList);
  const companiesListStatus = useAppSelector(selectCompaniesListStatus);
  const statementCreationStatus = useAppSelector(selectStatementCreationStatus);
  const statementsMultipleCreationStatus = useAppSelector(
    selectStatementsMultipleCreationStatus,
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMultipleGeneration, setIsMultipleGeneration] = useState(false);
  const [currentCompany, setCurrentCompany] = useState<string>("");

  if (companiesListStatus === "idle") {
    void dispatch(companiesListAsync());
  }

  useEffect(() => {
    void dispatch(statementsListAsync());
  }, [dispatch, statementCreationStatus, statementsMultipleCreationStatus]);

  useEffect(() => {
    if (statementCreationStatus === "success") {
      triggerToast(t("statement.creation.success") || "", "valid");
    } else if (statementCreationStatus === "failed") {
      triggerToast(t("statement.creation.failure") || "", "error");
    }
  }, [statementCreationStatus, t, dispatch]);

  useEffect(() => {
    if (statementsMultipleCreationStatus === "success") {
      triggerToast(t("statement.multiple.creation.success") || "", "valid");
    } else if (statementsMultipleCreationStatus === "failed") {
      triggerToast(t("statement.multiple.creation.failure") || "", "error");
    }
  }, [statementsMultipleCreationStatus, t, dispatch]);

  if (params.tab !== "creation") {
    return;
  }

  const renderGenerateStatement =
    (row: GenerateStatementDisplayed) =>
    (key: keyof GenerateStatementDisplayed) => {
      switch (key) {
        case "uuid":
          return (
            <StyledFlex>
              <Spacer x={1} />
              <Button
                title={t("statement.button.generation")}
                width={12}
                onClick={() => {
                  setIsMultipleGeneration(false);
                  setCurrentCompany(row[key]);
                  setIsModalVisible(true);
                }}
              />
            </StyledFlex>
          );
        case "name":
          return row[key];
      }
      unreachable(key);
    };

  const headersGenerateStatements = {
    name: {
      text: t("statement.page.column.company"),
    },
    uuid: {
      text: t("statement.page.column.uuid"),
      hasButton: true,
    },
  };

  const retrieveCompanyUuid = (uuid: string) => {
    setCurrentCompany("");
    const company = companiesList.find(
      (row: GetCompaniesApi) => row.uuid === uuid,
    );
    if (!company) {
      triggerToast(t("statement.reloadCompany.error") || "", "error");
      return;
    }
    const date = new Date();
    const previousMonthDate = new Date(date.setMonth(date.getMonth() - 1));
    const datas = {
      company: uuid,
      date: previousMonthDate,
    };
    void dispatch(statementAsync(datas));
  };

  const ConfirmationModal = (props: { isMultipleGeneration: boolean }) => {
    return (
      <>
        <StyledDiv>
          <StyledContent>
            <TextCapitalized>
              {props.isMultipleGeneration === true
                ? t("statement.modal.content.multiple")
                : t("statement.modal.content", { account: currentCompany })}
            </TextCapitalized>
          </StyledContent>
        </StyledDiv>
        <Spacer y={4} />
        <StyledButtons>
          <Button
            title={t("statement.modal.button.cancel")}
            width={12}
            onClick={() => {
              setIsModalVisible(false);
            }}
          />
          <Spacer x={2} />
          {props.isMultipleGeneration === true ? (
            <Button
              title={t("statement.modal.button.confirm")}
              width={12}
              onClick={() => {
                void dispatch(statementsMultipleAsync());
                setIsModalVisible(false);
              }}
            />
          ) : (
            <Button
              title={t("statement.modal.button.confirm")}
              width={12}
              onClick={() => {
                if (currentCompany !== "") retrieveCompanyUuid(currentCompany);
                setIsModalVisible(false);
              }}
            />
          )}
        </StyledButtons>
        <Spacer y={2} />
      </>
    );
  };

  return (
    <>
      <Modal
        title={t("statement.modal.title") || ""}
        visible={isModalVisible}
        onClose={() => {
          setIsModalVisible(false);
        }}
        width={32}
      >
        <ConfirmationModal isMultipleGeneration={isMultipleGeneration} />
      </Modal>
      <div>
        <Button
          title={t("statement.button.generation.multiple")}
          width={12}
          onClick={() => {
            setIsMultipleGeneration(true);
            setIsModalVisible(true);
          }}
        />
      </div>
      <Spacer y={2} />
      <TableDS<keyof GenerateStatementDisplayed, GenerateStatementDisplayed>
        data={companiesList}
        headers={headersGenerateStatements}
        render={renderGenerateStatement}
      />
    </>
  );
};

const StyledDiv = styled.div`
  display: flex;
  text-align: center;
`;

const StyledContent = styled.div`
  font-weight: 600;
  font-size: xx-large;
  color: ${colors["colors/system/error/error_normal"]};
`;

const StyledButtons = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
`;

const StyledFlex = styled.div`
  display: flex;
  align-items: center;
`;
