import styled from "styled-components";
import { colors } from "../../styles/figmaColors";

export interface SwitchProps {
  checked: boolean;
  color?: colorOptions;
  disabled?: boolean;
  onClick: () => void;
  size: sizeOptions;
}
type sizeOptions = "S" | "M";
type colorOptions = "GREEN" | "BLACK" | "RED";

const switchOptions = {
  S: {
    size: 1,
    sizeRoundSlider: 1,
    transform: 1,
  },
  M: {
    size: 1,
    sizeRoundSlider: 1.25,
    transform: 1,
  },
};

export const Switch = (props: SwitchProps) => {
  const { disabled, checked, color, onClick, size } = props;
  const options = switchOptions[size];

  return (
    <StyledContainer
      $disabled={disabled}
      $checked={checked}
      $color={color}
      $size={options.size}
      onClick={disabled ? undefined : onClick}
    >
      <StyledRoundSlider
        $transform={options.transform}
        $disabled={disabled}
        $checked={checked}
        $size={options.sizeRoundSlider}
        $color={color}
      />
    </StyledContainer>
  );
};

interface StyledSwitchArgs {
  $disabled?: boolean;
  $checked: boolean;
  $color?: colorOptions;
  $size: number;
  $transform?: number;
}

const StyledRoundSlider = styled.div<StyledSwitchArgs>`
  background-color: ${({ $checked, $disabled, $color }) =>
    $checked && !$disabled
      ? colors["colors/surfaces/background/background_level0"]
      : $disabled && $color === "GREEN" && $checked
        ? colors["colors/surfaces/background/background_level0"]
        : !$disabled
          ? colors["colors/button/primary/default"]
          : colors["colors/button/primary/disabled"]};
  height: ${({ $size }) => $size}rem;
  width: ${({ $size }) => $size}rem;
  transform: translateX(
    ${({ $checked, $transform }) => ($checked ? $transform : 0.25)}rem
  );
  border-radius: 1rem;
  transition: 0.4s ease-out;
`;

const StyledContainer = styled.div<StyledSwitchArgs>`
  background-color: ${({ $checked, $disabled, $color }) =>
    $disabled && $checked && $color === "GREEN"
      ? colors["colors/system/success/success_veryLight"]
      : $disabled && $checked
        ? $color || colors["colors/surfaces/background/background_level0"]
        : $checked && $color === "RED"
          ? colors["colors/system/error/error_normal"]
          : $checked
            ? $color || colors["colors/button/primary/default"]
            : colors["colors/surfaces/background/background_level0"]};
  border: 0.0625rem solid ${colors["colors/borders/button/primary"]};
  border-radius: 1rem;
  height: 1.5rem;
  width: 2.4375rem;
  display: flex;
  align-items: center;
  transition: 0.4s ease-out;
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
`;
