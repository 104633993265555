import {
  Assets,
  ButtonDS,
  DownloadOutlined,
  Page,
  Spacer,
  TableDS,
  ToastContainer,
  dateFormatterDayMonthYearHourMinute,
  triggerToast,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  zipFoldersListAsync,
  selectZipFolderList,
  selectZipFolderListStatus,
  selectZipFoldersExportStatus,
  zipFolderExportAsync,
  zipFolderDownloadAsync,
  selectZipFoldersExportLink,
} from "./zipFolderSlice";
import { ZipFolderDisplayed } from "./zipFolderApi";
import { unreachable } from "../utils";
import { useEffect } from "react";
import styled from "styled-components";

export const ZipFolder = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const zipFoldersListDisplayed = useAppSelector(selectZipFolderList);
  const zipFoldersListStatus = useAppSelector(selectZipFolderListStatus);
  const zipFoldersExportStatus = useAppSelector(selectZipFoldersExportStatus);
  const zipFoldersExportLink = useAppSelector(selectZipFoldersExportLink);

  if (zipFoldersListStatus === "idle") {
    void dispatch(zipFoldersListAsync());
  }

  useEffect(() => {
    if (zipFoldersExportLink.presignedUrl && zipFoldersExportLink.fileName) {
      void dispatch(zipFolderDownloadAsync(zipFoldersExportLink));
    }
  }, [zipFoldersExportLink, dispatch]);

  useEffect(() => {
    if (zipFoldersExportStatus === "success") {
      triggerToast(t("zipFolder.upload.success") || "", "valid");
    } else if (zipFoldersExportStatus === "failed") {
      triggerToast(t("zipFolder.upload.failure") || "", "error");
    }
  }, [zipFoldersExportStatus, t]);

  const headers = {
    uuid: {
      iconSrc: <Assets.VerifiedUser />,
      text: t("zipFolder.page.column.uuid"),
    },
    bucketFileName: {
      iconSrc: <Assets.BadgeName />,
      text: t("zipFolder.page.column.bucketFileName"),
    },
    createdAt: {
      iconSrc: <Assets.Calendar />,
      text: t("zipFolder.page.column.createdAt"),
    },
  };

  const render =
    (row: ZipFolderDisplayed) => (key: keyof ZipFolderDisplayed) => {
      switch (key) {
        case "createdAt":
          return row[key]
            ? dateFormatterDayMonthYearHourMinute(new Date(row[key]))
            : row[key];
        case "uuid":
          return row[key];
        case "bucketFileName":
          return (
            <StyledFlex>
              <ButtonDS
                sizeButton={"S"}
                format={"hug"}
                buttonType={"secondary"}
                singleIcon={{ icon: <DownloadOutlined />, size: "S" }}
                onClick={() => {
                  void dispatch(
                    zipFolderExportAsync({
                      uuid: row["uuid"],
                      fileName: row["bucketFileName"],
                    }),
                  );
                }}
              />
              <Spacer x={1} />
              {row[key]}
            </StyledFlex>
          );
      }
      unreachable(key);
    };

  return (
    <Page
      title={t("zipFolder.title")}
      pageName={"zipFolder"}
      toaster={<ToastContainer />}
    >
      <>
        !!! NOT WORKING YET !!!
        <TableDS<keyof ZipFolderDisplayed, ZipFolderDisplayed>
          data={zipFoldersListDisplayed}
          headers={headers}
          render={render}
        />
      </>
    </Page>
  );
};

const StyledFlex = styled.div`
  display: flex;
  align-items: center;
`;
