import {
  triggerToast,
  dateFormatterDayMonthYearHourMinute,
  ButtonDS,
  DownloadOutlined,
  TableDS,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { BankStatementsListDisplayed } from "./statementApi";
import { unreachable } from "../../utils";
import {
  bankStatementDownloadAsync,
  bankStatementExportAsync,
  bankStatementsListAsync,
  selectBankStatementExportLink,
  selectBankStatementExportStatus,
  selectBankStatementsList,
  selectBankStatementsListStatus,
} from "./statementSlice";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const BankStatementTab = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const params = useParams();
  const bankStatementsList = useAppSelector(selectBankStatementsList);
  const bankStatementsListStatus = useAppSelector(
    selectBankStatementsListStatus,
  );

  const bankStatementsExportStatus = useAppSelector(
    selectBankStatementExportStatus,
  );
  const bankStatementExportLink = useAppSelector(selectBankStatementExportLink);

  if (bankStatementsListStatus === "idle") {
    void dispatch(bankStatementsListAsync());
  }

  useEffect(() => {
    if (bankStatementsExportStatus === "success") {
      triggerToast(t("bankStatement.upload.success") || "", "valid");
    } else if (bankStatementsExportStatus === "failed") {
      triggerToast(t("bankStatement.upload.failure") || "", "error");
    }
  }, [bankStatementsExportStatus, t]);

  useEffect(() => {
    if (
      bankStatementExportLink.presignedUrl &&
      bankStatementExportLink.fileName
    ) {
      void dispatch(bankStatementDownloadAsync(bankStatementExportLink));
    }
  }, [bankStatementExportLink, dispatch]);

  if (params.tab !== "bankStatements") {
    return;
  }

  const headersBankStatementsList = {
    companyName: {
      text: t("bankStatement.page.column.company"),
    },
    date: {
      text: t("bankStatement.page.column.date"),
    },
    closingBalance: {
      text: t("bankStatement.page.column.closingBalance"),
    },

    fileName: {
      text: t("bankStatement.page.column.fileName"),
    },
    bucketFileName: {
      text: t("bankStatement.page.column.download.pdf"),
    },
  };

  const renderBankStatementsList =
    (row: BankStatementsListDisplayed) =>
    (key: keyof BankStatementsListDisplayed) => {
      switch (key) {
        case "companyName":
        case "fileName":
          return row[key];
        case "closingBalance":
          return `${row[key] === null ? "-" : (row[key] / 100).toString() + " EUR"}`;
        case "bucketFileName":
          return (
            row[key] && (
              <ButtonDS
                sizeButton={"S"}
                format={"hug"}
                buttonType={"secondary"}
                singleIcon={{ icon: <DownloadOutlined />, size: "S" }}
                onClick={() => {
                  void dispatch(
                    bankStatementExportAsync({
                      bucketFileName: row["bucketFileName"] ?? "",
                      fileName: row["fileName"],
                    }),
                  );
                }}
              />
            )
          );
        case "date":
          return dateFormatterDayMonthYearHourMinute(new Date(row[key]));
      }
      unreachable(key);
    };

  return (
    <TableDS<keyof BankStatementsListDisplayed, BankStatementsListDisplayed>
      data={bankStatementsList}
      headers={headersBankStatementsList}
      render={renderBankStatementsList}
    />
  );
};
