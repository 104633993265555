import styled from "styled-components";
import { colors } from "../styles/colors";
import { FormEvent } from "react";
import { capitalize } from "../format";
import { Spacer } from "./Spacer";
import Search from "../assets/search.svg?react";
import { filteredValuesToTranslate } from "../utils";

export interface SearchBarProps<T extends object> {
  placeholder?: string;
  setFilterValues: (filteredValues: T[]) => void;
  values: T[];
  keysToTranslate?: Array<keyof T>;
  findKeysToTranslate?: (key: keyof T, value: string) => string;
  keysToIgnore?: Array<keyof T>;
}

export const SearchBar = <T extends object>(props: SearchBarProps<T>) => {
  const change = (e: FormEvent<HTMLInputElement>) => {
    const text = (e.target as HTMLInputElement).value;
    const filteredValues = filteredValuesToTranslate(
      props.values,
      text,
      props.keysToTranslate,
      props.findKeysToTranslate,
      props.keysToIgnore,
    );
    props.setFilterValues(text ? filteredValues : props.values);
  };
  return (
    <StyledSearchBarContainer>
      <StyledSearchBarRow>
        <Spacer x={0.5} />
        <StyledTest>
          <Search />
        </StyledTest>
        <Spacer x={0.5} />
        <StyledSearchBar
          placeholder={props.placeholder && capitalize(props.placeholder)}
          onChange={change}
        />
        <Spacer x={0.5} />
      </StyledSearchBarRow>
    </StyledSearchBarContainer>
  );
};

const StyledSearchBarContainer = styled.div`
  display: flex;
  width: 11rem;
  height: 3rem;
  font-size: 1rem;
  background-color: transparent;
  border: 1px solid ${colors.ligthGrey};
  border-radius: 0.5rem;
`;

const StyledSearchBar = styled.input`
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  color: ${colors.black};
  width: 100%;
  border: 0px;
  &:focus {
    outline: none;
  }
`;

const StyledSearchBarRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledTest = styled.div`
  display: flex;
  align-items: center;
`;
