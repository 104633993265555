import { ReactNode, useState } from "react";
import styled from "styled-components";
import { TableHeader, TableHeaderProps } from "./TableHeader";
import { colors } from "../../styles/colors";
import { Spacer } from "../Spacer";

export interface TableProps<
  Key extends string,
  T extends Record<Key, string | number | null>,
> {
  data: Array<T>;
  headers: Record<keyof T, TableHeaderProps>;
  render?: (row: T) => (key: Key) => ReactNode;
  element?: (props: unknown) => JSX.Element | undefined;
  elementOnHover?: (props: T) => JSX.Element | undefined;
}

interface CellProps {
  $first: boolean;
}

interface RowProps<
  Key extends string,
  T extends Record<Key, string | number | null>,
> {
  keys: Array<Key>;
  render?: (row: T) => (key: Key) => ReactNode;
  headers?: Record<keyof T, TableHeaderProps>;
  data: T;
  rowNumber: number;
  element?: (props: T) => JSX.Element | undefined;
  elementOnHover?: (props: T) => JSX.Element | undefined;
}

const Row = <Key extends string, T extends Record<Key, string | number | null>>(
  props: RowProps<Key, T>,
) => {
  const [isHover, setHover] = useState(false);

  return (
    <StyledRow
      key={props.rowNumber}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {props.keys.map((key, index) => (
        <StyledCell $first={index === 0} key={index}>
          <Spacer x={0.5} />
          <StyledText>
            {props.render ? (
              props.render(props.data)(key)
            ) : (
              <> {`${props.data[key] || "-"}`} </>
            )}
          </StyledText>
          {!isHover &&
            props.element &&
            props.headers &&
            props.headers[key].hasButton && <>{props.element?.(props.data)}</>}
          {isHover &&
            props.elementOnHover &&
            props.headers &&
            props.headers[key].hasButton && (
              <>{props.elementOnHover?.(props.data)}</>
            )}
          <Spacer x={1} />
        </StyledCell>
      ))}
    </StyledRow>
  );
};

export const Table = <
  Key extends string,
  T extends Record<Key, string | number | null>,
>(
  props: TableProps<Key, T>,
) => {
  const keys = Object.keys(props.headers) as Key[];
  return (
    <StyledContainer>
      <StyledContent>
        <StyledHeader>
          {keys.map((column) => (
            <TableHeader
              text={props.headers[column].text}
              iconSrc={props.headers[column].iconSrc}
              key={column}
              sortAction={props.headers[column].sortAction}
            />
          ))}
        </StyledHeader>
        {props.data.map((data: T, rowNumber: number) => (
          <Row
            keys={keys}
            render={props.render}
            headers={props.headers}
            data={data}
            rowNumber={rowNumber}
            key={rowNumber}
            element={props.element}
            elementOnHover={props.elementOnHover}
          />
        ))}
      </StyledContent>
      <Spacer y={5} />
    </StyledContainer>
  );
};

const StyledText = styled.div`
  flex-direction: column;
  display: flex;
  font-weight: 400;
  width: 100%;
`;

const StyledRow = styled.div`
  &:hover {
    background-color: ${colors.background};
  }
  display: flex;
  height: 3rem;
  line-height: 3rem;
`;

const StyledCell = styled.div<CellProps>`
  min-width: 11rem;
  width: 100%;
  font-size: 1rem;
  font-family: Inter;
  color: black;
  display: flex;
  border: solid ${colors.ligthGrey} 1px;
  border-top: none;
  border-right: none;
  align-items: center;
  border-left: ${({ $first }) =>
    $first ? "none" : "solid " + colors.ligthGrey + " 1px"};
  white-space: nowrap;
  overflow: hidden;
`;

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`;
const StyledContent = styled.div`
  overflow: auto;
  flex-grow: 1;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0.5rem;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;
const StyledHeader = styled.div`
  display: flex;
  border-bottom: solid ${colors.ligthGrey} 1px;
  padding-bottom: 1rem;
  position: sticky;
  top: 0;
  background-color: ${colors.white};
  min-width: fit-content;
`;
