import styled from "styled-components";
import { colors } from "../../styles/figmaColors";
import { typographies } from "../../styles/figmaTypographies";
import { Spacer } from "./Spacer";

export type EnergyClass = "A" | "B" | "C" | "D" | "E" | "F" | "G";

const energyClassDict: {
  [key in EnergyClass]: { backgroundColor: string };
} = {
  A: {
    backgroundColor: "#009642",
  },
  B: {
    backgroundColor: "#4FAD2E",
  },
  C: {
    backgroundColor: "#C5D501",
  },
  D: {
    backgroundColor: "#FDED00",
  },
  E: {
    backgroundColor: "#EFB202",
  },
  F: {
    backgroundColor: "#EC6705",
  },
  G: {
    backgroundColor: "#E40716",
  },
};

type EnergyClassProps = {
  energyClass: EnergyClass;
};

export const EnergyClass = (props: EnergyClassProps) => {
  const { energyClass } = props;
  return (
    <StyledSquare
      $energyClassColor={energyClassDict[energyClass]?.backgroundColor}
    >
      <Spacer x={0.75} />
      {energyClass}
      <Spacer x={0.25} />
      <StyledTriangle
        $energyClassColor={energyClassDict[energyClass]?.backgroundColor}
      />
    </StyledSquare>
  );
};

const StyledSquare = styled.div<{
  $energyClassColor: string;
}>`
  width: 1rem;
  height: 1rem;
  background-color: ${({ $energyClassColor }) => $energyClassColor};
  display: flex;
  align-items: center;
  justify-content: center;
  ${typographies["Body/XXS"]}
  color: ${colors["colors/text/white"]}
`;

const StyledTriangle = styled.div<{
  $energyClassColor: string;
}>`
  border-top: 0.5rem solid transparent;
  border-bottom: 0.5rem solid transparent;
  border-left: 0.5rem solid ${({ $energyClassColor }) => $energyClassColor};
`;
