import styled from "styled-components";
import { colors } from "../styles/colors";
import { Spacer } from "./Spacer";
import { Key } from "react";
import WhiteMarkCheckbox from "../assets/whiteMarkCheckbox.svg?react";

export interface CheckboxProps {
  text: string | JSX.Element;
  onClick: () => void;
  keyProp?: Key | undefined | null;
  check: boolean;
  borderColor?: string;
  size?: number;
}

export const Checkbox = (props: CheckboxProps) => {
  const { text, onClick, keyProp, check, borderColor, size } = props;

  return (
    <StyledContainer key={keyProp} $check={check}>
      <StyledCkeckBoxInput
        $check={check}
        $borderColor={borderColor}
        $size={size}
        onClick={onClick}
      >
        {check && <WhiteMarkCheckbox />}
      </StyledCkeckBoxInput>
      <Spacer x={1} />
      <StyledText $check={check}>{text}</StyledText>
    </StyledContainer>
  );
};

interface StyledCheckboxArgs {
  $check: boolean;
  $borderColor?: string;
  $size?: number;
}

const StyledText = styled.div<StyledCheckboxArgs>`
  font-family: Inter;
  font-size: 1rem;
  font-weight: 500;
  color: ${({ $check }) => ($check ? colors.black : colors.disabled)};
  &::first-letter {
    text-transform: uppercase;
  }
`;

const StyledCkeckBoxInput = styled.div<StyledCheckboxArgs>`
  background-color: ${({ $check }) =>
    $check ? colors.primary : "transparent"};
  border-color: ${({ $borderColor }) =>
    $borderColor ? $borderColor : colors.primary} !important;
  border: 0.1875rem solid;
  min-height: ${({ $size }) => ($size ? $size : "1.5")}rem;
  min-width: ${({ $size }) => ($size ? $size : "1.5")}rem;
  display: flex;
  align-items: center;
  transition: 0.4s ease-out;
  cursor: pointer;
  border-radius: 0.25rem;
  justify-content: center;
`;

const StyledContainer = styled.div<StyledCheckboxArgs>`
  display: flex;
  align-items: center;
`;
