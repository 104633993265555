// Externally-visible signature
import axiosLib from "axios";

export function unreachable(p: never): never;
// Implementation signature
export function unreachable() {
  throw new Error("Switch is not exhaustive");
}

export type QueryStatus = "idle" | "processing" | "success" | "failed";

export const authAxios = () => {
  const baseUrl = `${import.meta.env["VITE_BACKEND_URL"] as string}/qg/`;
  return axiosLib.create({
    baseURL: baseUrl,
    timeout: 10000,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token") as string}`,
    },
  });
};

export const axiosInstanceNoAuth = () =>
  axiosLib.create({
    baseURL: `${import.meta.env["VITE_BACKEND_URL"] as string}/qg`,
    timeout: 10000,
    headers: { Authorization: `Bearer` },
  });
