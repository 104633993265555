import { FileInputButton, triggerToast } from "@qivia/ui";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  privacyPoliciesAsync,
  selectPrivatePolicyPresignedUrlStatus,
  selectTermsAndConditionsPresignedUrlStatus,
  selectUploadtoS3StatusStatus,
  termsAndConditionsAsync,
  uploadDocumentToS3Async,
} from "./termsAndPolicySlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { TermsAndPolicyType } from "./termsAndPolicyApi";

export interface UploadDocumentButtonProps {
  children?: string | JSX.Element | JSX.Element[];
  documentType: TermsAndPolicyType;
}

export const UploadDocumentButton = (props: UploadDocumentButtonProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [fileContent, setFileContent] = useState<string | ArrayBuffer | null>(
    null,
  );
  const loadPresignedUrlTermsAndConditions = useAppSelector(
    selectTermsAndConditionsPresignedUrlStatus,
  );
  const loadPresignedUrlPrivatePolicy = useAppSelector(
    selectPrivatePolicyPresignedUrlStatus,
  );
  const uploadToS3Status = useAppSelector(selectUploadtoS3StatusStatus);

  useEffect(() => {
    if (
      loadPresignedUrlTermsAndConditions &&
      fileContent &&
      uploadToS3Status === "idle"
    )
      void dispatch(
        uploadDocumentToS3Async({
          presignedUrl: loadPresignedUrlTermsAndConditions,
          fileContent,
        }),
      );
  }, [
    fileContent,
    dispatch,
    loadPresignedUrlTermsAndConditions,
    uploadToS3Status,
  ]);

  useEffect(() => {
    if (
      loadPresignedUrlPrivatePolicy &&
      fileContent &&
      uploadToS3Status === "idle"
    )
      void dispatch(
        uploadDocumentToS3Async({
          presignedUrl: loadPresignedUrlPrivatePolicy,
          fileContent,
        }),
      );
  }, [fileContent, dispatch, loadPresignedUrlPrivatePolicy, uploadToS3Status]);

  useEffect(() => {
    if (uploadToS3Status === "success")
      triggerToast(t("termsAndPolicy.upload.success") || "", "valid");
    if (uploadToS3Status === "failed") {
      triggerToast(t("termsAndPolicy.upload.failure") || "", "error");
    }
  }, [uploadToS3Status, t]);

  const readFile = (file: File | null) => {
    if (!file) return;

    const regex = /^.+\.(([pP][dD][fF])|([jJ][pP][eE]?[gG])|([pP][nN][gG]))$/;
    const reader = new FileReader();
    reader.onload = () => {
      if (file.name.match(regex)) {
        const content = reader.result;
        if (!content) return;
        if (props.documentType === "PRIVATE_POLICY") {
          void dispatch(
            privacyPoliciesAsync({
              originalFileName: file.name,
            }),
          );
        } else {
          void dispatch(
            termsAndConditionsAsync({
              originalFileName: file.name,
            }),
          );
        }
        setFileContent(content);
      } else {
        triggerToast(t("termsAndPolicy.incorrectType") || "", "error");
      }
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <FileInputButton handleFile={(file) => readFile(file as File)}>
      {props.children}
    </FileInputButton>
  );
};
