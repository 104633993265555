import {
  ButtonDS,
  DownloadOutlined,
  SelectDS,
  Spacer,
  TableDS,
  TextCapitalized,
  dateFormatterDayLongMonthYearHourMinuteSecond,
  dateFormatterLongMonthYear,
  triggerToast,
  unreachable,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import styled from "styled-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  createInvoiceAsync,
  getInvoicesListAsync,
  invoiceDownloadAsync,
  invoiceExportAsync,
  selectCreateInvoiceStatus,
  selectInvoicesList,
  selectInvoiceExportLink,
} from "./invoiceSlice";
import { InvoicesListDisplayed, InvoicesListDisplayedRow } from "./invoiceApi";
import {
  companiesListAsync,
  selectCompaniesList,
  selectCompaniesListStatus,
} from "./../companiesSlice";
import { useParams } from "react-router-dom";

const monthValueList = (
  creationDate: string,
  invoices: InvoicesListDisplayed[],
) => {
  const optionMonth: { value: string; label: string }[] = [];
  const now = new Date(new Date().setMonth(new Date().getMonth() - 1));
  const beginningDate = new Date(creationDate);
  beginningDate.setDate(1);

  for (
    let i = beginningDate;
    i < now;
    i = new Date(beginningDate.setMonth(beginningDate.getMonth() + 1))
  ) {
    const currentMonth = invoices.find((invoice) => {
      return (
        new Date(invoice.date).getMonth() === i.getMonth() &&
        new Date(invoice.date).getFullYear() === i.getFullYear()
      );
    });
    if (!currentMonth) {
      optionMonth.push({
        value: `${new Date(i).toISOString().substring(0, 10)}`,
        label: `${dateFormatterLongMonthYear(new Date(i))}`,
      });
    }
  }
  return optionMonth;
};

export const InvoiceTab = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const companiesList = useAppSelector(selectCompaniesList);
  const companiesListStatus = useAppSelector(selectCompaniesListStatus);
  const createInvoiceStatus = useAppSelector(selectCreateInvoiceStatus);
  const invoicesList = useAppSelector(selectInvoicesList);
  const invoiceExportLink = useAppSelector(selectInvoiceExportLink);
  const [companySelected, setCompanySelected] = useState<string>("");
  const [companyCreation, setCompanyCreation] = useState<string>("");
  const [monthSelected, setMonthSelected] = useState<string>("");
  const [displayError, setDisplayError] = useState<boolean>(false);

  const isValidSubmission = useMemo(
    () => companySelected !== "" && monthSelected !== "",
    [companySelected, monthSelected],
  );

  const optionMonth = monthValueList(companyCreation, invoicesList);

  const valueOptionMonth = useMemo(
    () => optionMonth.find((option) => option.value === monthSelected),
    [optionMonth, monthSelected],
  );
  const optionCompany = useMemo(
    () =>
      companiesList.map((company) => {
        return {
          value: company.uuid,
          label: company.name,
        };
      }),
    [companiesList],
  );

  const valueOptionCompany = useMemo(
    () => optionCompany.find((option) => option.value === companySelected),
    [optionCompany, companySelected],
  );

  useEffect(() => {
    if (companiesListStatus === "idle") {
      void dispatch(companiesListAsync());
    }
  }, [dispatch, companiesListStatus]);

  useEffect(() => {
    if (companySelected) {
      void dispatch(getInvoicesListAsync({ company: companySelected }));
    }
  }, [companySelected, dispatch, createInvoiceStatus]);

  useEffect(() => {
    if (createInvoiceStatus === "success") {
      triggerToast(t("invoice.creation.success") || "", "valid");
    } else if (createInvoiceStatus === "failed") {
      triggerToast(t("invoice.creation.failure") || "", "error");
    }
  }, [createInvoiceStatus, t, dispatch]);

  useEffect(() => {
    if (invoiceExportLink.presignedUrl && invoiceExportLink.fileName) {
      void dispatch(invoiceDownloadAsync(invoiceExportLink));
    }
  }, [invoiceExportLink, dispatch]);

  const clickToSubmit = useCallback(() => {
    if (!isValidSubmission) {
      setDisplayError(true);
    } else {
      setDisplayError(false);
      void dispatch(
        createInvoiceAsync({
          company: companySelected,
          date: monthSelected,
        }),
      );
    }
  }, [companySelected, dispatch, isValidSubmission, monthSelected]);

  const selectCompany = useCallback(
    (companyUuid: string) => {
      setCompanySelected(companyUuid);
      const companyFound = companiesList.find(
        (row) => row.uuid === companyUuid,
      );
      if (companyFound) {
        setCompanyCreation(companyFound.createdDate);
      }
    },
    [companiesList],
  );

  if (params.tab !== "invoices") {
    return;
  }

  const headers = {
    company: {
      text: t("invoice.column.company"),
    },
    date: {
      text: t("invoice.column.date"),
    },
    bucketFileName: {
      text: t("invoice.column.download"),
    },
    activeTransactions: {
      text: t("invoice.column.activeTransactions"),
    },
    activeCards: {
      text: t("invoice.column.activeCards"),
    },
    badgeTransactions: {
      text: t("invoice.column.badgeTransactions"),
    },
    activeBadges: {
      text: t("invoice.column.activeBadges"),
    },
    createdAt: {
      text: t("invoice.column.createdAt"),
    },
  };

  const render =
    (row: InvoicesListDisplayedRow) => (key: keyof InvoicesListDisplayed) => {
      switch (key) {
        case "bucketFileName":
          return row[key] ? (
            <ButtonDS
              sizeButton={"S"}
              format={"hug"}
              buttonType={"secondary"}
              singleIcon={{ icon: <DownloadOutlined />, size: "S" }}
              onClick={() => {
                void dispatch(
                  invoiceExportAsync({
                    bucketFileName: row[key] ?? "",
                    fileName: row["fileName"] ?? "",
                  }),
                );
              }}
            />
          ) : (
            ""
          );
        case "company":
          return valueOptionCompany?.label;
        case "createdAt":
          return dateFormatterDayLongMonthYearHourMinuteSecond(
            new Date(row[key]),
          );
        case "date":
          return dateFormatterLongMonthYear(new Date(row[key]));
        case "activeCards":
          return row[key] + " cartes";
        case "activeTransactions":
          return row[key] + " transactions";
        case "activeBadges":
          return row[key] + " badges";
        case "badgeTransactions":
          return row[key] + " transactions";
      }
      unreachable(key);
    };

  return (
    <StyledContainer>
      <Spacer y={1} />
      <StyledHeader>
        <TextCapitalized>{t("invoice.select.company")}</TextCapitalized>
        <SelectDS
          label={""}
          value={valueOptionCompany}
          options={optionCompany}
          onChange={(selectedOption) =>
            selectCompany(selectedOption ? selectedOption.value : "")
          }
          error={
            (displayError &&
              companySelected === "" &&
              t("invoice.select.company.error")) ||
            undefined
          }
          allWidth
        />
        <TextCapitalized>{t("invoice.select.month")}</TextCapitalized>
        <SelectDS
          label={""}
          value={valueOptionMonth}
          options={optionMonth}
          onChange={(selectedOption) =>
            setMonthSelected(selectedOption ? selectedOption.value : "")
          }
          error={
            (displayError &&
              companySelected === "" &&
              t("invoice.select.month.error")) ||
            undefined
          }
          allWidth
        />
        <ButtonDS
          format="hug"
          buttonType="primary"
          text={t("invoice.button.create")}
          onClick={clickToSubmit}
          disabled={!isValidSubmission}
        />
      </StyledHeader>
      <Spacer y={2} />
      <StyledTable>
        <TableDS<keyof InvoicesListDisplayed, InvoicesListDisplayed>
          data={invoicesList}
          headers={headers}
          render={render}
        />
      </StyledTable>
      <Spacer y={3} />
    </StyledContainer>
  );
};

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 30rem;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledTable = styled.div`
  height: 100%;
  overflow: auto;
`;
