import styled from "styled-components";
import { colors } from "../../styles/colors";
import { Spacer } from "../Spacer";
import { QiviaLogo } from "../../assets/assets";

export interface LaunchPageProps {
  hasBorderRadius?: boolean;
}

export const LaunchPage = (props: LaunchPageProps) => {
  return (
    <StyledLaunchPage $hasBorderRadius={props.hasBorderRadius || false}>
      <QiviaLogo />
      <Spacer y={5} />
      <div className="dot-spin" />
    </StyledLaunchPage>
  );
};

const StyledLaunchPage = styled.div<{ $hasBorderRadius: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem 0 0 0.75rem;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: ${colors.white};
  border-radius: ${({ $hasBorderRadius }) =>
    $hasBorderRadius && "0.75rem 0 0 0.75rem"};

  .dot-spin {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: transparent;
    color: transparent;
    box-shadow:
      0 -18px 0 0 #63d195,
      12.727926px -12.727926px 0 0 #63d195,
      18px 0 0 0 #63d195,
      12.727926px 12.727926px 0 0 #efe74e,
      0 18px 0 0 #efe74e,
      -12.727926px 12.727926px 0 0 #efe74e,
      -18px 0 0 0 #efe74e,
      -12.727926px -12.727926px 0 0 #efe74e;
    animation: dot-spin 1.5s infinite linear;
  }

  @keyframes dot-spin {
    0%,
    100% {
      box-shadow:
        0 -18px 0 0 #63d195,
        12.727926px -12.727926px 0 0 #63d195,
        18px 0 0 0 #63d195,
        12.727926px 12.727926px 0 -5px #efe74e,
        0 18px 0 -5px #efe74e,
        -12.727926px 12.727926px 0 -5px #efe74e,
        -18px 0 0 -5px #efe74e,
        -12.727926px -12.727926px 0 -5px #efe74e;
    }
    12.5% {
      box-shadow:
        0 -18px 0 -5px #efe74e,
        12.727926px -12.727926px 0 0 #63d195,
        18px 0 0 0 #63d195,
        12.727926px 12.727926px 0 0 #63d195,
        0 18px 0 -5px #efe74e,
        -12.727926px 12.727926px 0 -5px #efe74e,
        -18px 0 0 -5px #efe74e,
        -12.727926px -12.727926px 0 -5px #efe74e;
    }
    25% {
      box-shadow:
        0 -18px 0 -5px #efe74e,
        12.727926px -12.727926px 0 -5px #efe74e,
        18px 0 0 0 #63d195,
        12.727926px 12.727926px 0 0 #63d195,
        0 18px 0 0 #63d195,
        -12.727926px 12.727926px 0 -5px #efe74e,
        -18px 0 0 -5px #efe74e,
        -12.727926px -12.727926px 0 -5px #efe74e;
    }
    37.5% {
      box-shadow:
        0 -18px 0 -5px #efe74e,
        12.727926px -12.727926px 0 -5px #efe74e,
        18px 0 0 -5px #efe74e,
        12.727926px 12.727926px 0 0 #63d195,
        0 18px 0 0 #63d195,
        -12.727926px 12.727926px 0 0 #63d195,
        -18px 0 0 -5px #efe74e,
        -12.727926px -12.727926px 0 -5px #efe74e;
    }
    50% {
      box-shadow:
        0 -18px 0 -5px #efe74e,
        12.727926px -12.727926px 0 -5px #efe74e,
        18px 0 0 -5px #efe74e,
        12.727926px 12.727926px 0 -5px #efe74e,
        0 18px 0 0 #63d195,
        -12.727926px 12.727926px 0 0 #63d195,
        -18px 0 0 0 #63d195,
        -12.727926px -12.727926px 0 -5px #efe74e;
    }
    62.5% {
      box-shadow:
        0 -18px 0 -5px #efe74e,
        12.727926px -12.727926px 0 -5px #efe74e,
        18px 0 0 -5px #efe74e,
        12.727926px 12.727926px 0 -5px #efe74e,
        0 18px 0 -5px #efe74e,
        -12.727926px 12.727926px 0 0 #63d195,
        -18px 0 0 0 #63d195,
        -12.727926px -12.727926px 0 0 #63d195;
    }
    75% {
      box-shadow:
        0 -18px 0 0 #63d195,
        12.727926px -12.727926px 0 -5px #efe74e,
        18px 0 0 -5px #efe74e,
        12.727926px 12.727926px 0 -5px #efe74e,
        0 18px 0 -5px #efe74e,
        -12.727926px 12.727926px 0 -5px #efe74e,
        -18px 0 0 0 #63d195,
        -12.727926px -12.727926px 0 0 #63d195;
    }
    87.5% {
      box-shadow:
        0 -18px 0 0 #63d195,
        12.727926px -12.727926px 0 0 #63d195,
        18px 0 0 -5px #efe74e,
        12.727926px 12.727926px 0 -5px #efe74e,
        0 18px 0 -5px #efe74e,
        -12.727926px 12.727926px 0 -5px #efe74e,
        -18px 0 0 -5px #efe74e,
        -12.727926px -12.727926px 0 0 #63d195;
    }
  }
`;
