import styled from "styled-components";
import { colors } from "../../../styles/figmaColors";
import { typographies } from "../../../styles/figmaTypographies";
import { Spacer } from "./../Spacer";
import { TextCapitalized } from "./../text/TextCapitalized";

export interface SideMenuProps {
  options: Option[];
  setSelectedOption: (section: string) => void;
  selectedOption: string;
}

interface Option {
  label: string;
  action: () => void;
}

export const SideMenu = (props: SideMenuProps) => {
  return (
    <StyledContainer>
      {props.options.map((option, index) => {
        return (
          <StyledRow
            onClick={() => {
              option.action();
              props.setSelectedOption(option.label);
            }}
            $isSelected={option.label === props.selectedOption}
            key={index}
          >
            <Spacer x={1} />
            <div>
              <Spacer y={1} />
              <TextCapitalized>{option.label}</TextCapitalized>
              <Spacer y={1} />
            </div>
            <Spacer x={1} />
          </StyledRow>
        );
      })}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors["colors/surfaces/background/background_level0"]};
  width: 23rem;
`;

const StyledRow = styled.div<{ $isSelected: boolean }>`
  display: flex;
  border-radius: 0.5rem;
  &:hover {
    cursor: pointer;
  }
  ${typographies["Body/S"]};
  color: ${({ $isSelected }) =>
    $isSelected
      ? `${colors["colors/text/black"]}`
      : `${colors["colors/text/darkGrey"]}`};
  ${({ $isSelected }) =>
    $isSelected ? `${typographies["Body/M"]}` : `${typographies["Body/S"]}`};
  background-color: ${({ $isSelected }) =>
    $isSelected && `${colors["colors/surfaces/background/background_level1"]}`};
`;
