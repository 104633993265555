import {
  Assets,
  Button,
  ElementButton,
  Page,
  Spacer,
  TableDS,
  TextCapitalized,
  ToastContainer,
  dateFormatterDayMonth,
  triggerToast,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  termsAndPoliciesListAsync,
  selectTermsAndPolicyList,
  selectTermsAndPolicyListStatus,
  termsAndConditionsDownloadAsync,
  privacyPoliciesDownloadAsync,
} from "./termsAndPolicySlice";
import { TermsAndPolicyDisplayed } from "./termsAndPolicyApi";
import { unreachable } from "../utils";
import styled from "styled-components";
import { UploadDocumentButton } from "./uploadDocumentButton";

export const TermsAndPolicy = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const termsAndPoliciesList = useAppSelector(selectTermsAndPolicyList);
  const listDisplayed: TermsAndPolicyDisplayed[] = termsAndPoliciesList.map(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ uuid, ...payload }) => {
      return payload;
    },
  );
  const termsAndPoliciesListStatus = useAppSelector(
    selectTermsAndPolicyListStatus,
  );

  if (termsAndPoliciesListStatus === "idle") {
    void dispatch(termsAndPoliciesListAsync());
  }

  const headers = {
    originalFileName: {
      iconSrc: <Assets.VerifiedUser />,
      text: t("termsAndPolicy.page.column.originalFileName"),
    },
    fileName: {
      iconSrc: <Assets.Billing />,
      text: t("termsAndPolicy.page.column.fileName"),
    },
    createdAt: {
      iconSrc: <Assets.Calendar />,
      text: t("termsAndPolicy.page.column.createdAt"),
    },
    type: {
      iconSrc: <Assets.CircleNotification />,
      text: t("termsAndPolicy.page.column.type"),
    },
    isLastCreated: {
      iconSrc: <Assets.TimerSolid />,
      text: t("termsAndPolicy.page.column.isLastCreated"),
    },
    downloadLink: {
      iconSrc: <Assets.DownloadGrey />,
      text: t("termsAndPolicy.page.column.downloadLink"),
    },
  };

  const retrieveUuid = (fileName: string) => {
    const termsAndPolicies = termsAndPoliciesList.find(
      (row: TermsAndPolicyDisplayed) => row.fileName === fileName,
    );
    if (!termsAndPolicies) {
      triggerToast(t("termsAndPolicy.reloadUuid.error") || "", "error");
      return;
    }
    if (termsAndPolicies.type === "TERMS_AND_CONDITIONS") {
      void dispatch(
        termsAndConditionsDownloadAsync({
          uuid: termsAndPolicies.uuid,
          fileName,
        }),
      );
    } else {
      void dispatch(
        privacyPoliciesDownloadAsync({ uuid: termsAndPolicies.uuid, fileName }),
      );
    }
  };

  const render =
    (row: TermsAndPolicyDisplayed) => (key: keyof TermsAndPolicyDisplayed) => {
      switch (key) {
        case "createdAt":
          return dateFormatterDayMonth(new Date(row[key]));
        case "isLastCreated":
          return row[key].toString() === "true" ? "YES" : "-";
        case "type":
          return row[key] === "PRIVATE_POLICY" ? (
            <TextCapitalized>
              {t("termsAndPolicy.type.privatePolicy")}
            </TextCapitalized>
          ) : (
            <TextCapitalized>
              {t("termsAndPolicy.type.termsAndConditions")}
            </TextCapitalized>
          );
        case "downloadLink":
          return (
            <ElementButton
              Element={<Assets.DownloadSolid />}
              onClick={() => {
                retrieveUuid(row["fileName"]);
              }}
            />
          );
        case "fileName":
        case "originalFileName":
          return row[key];
      }
      unreachable(key);
    };

  return (
    <Page
      title={t("termsAndPolicy.title")}
      pageName={"termsAndPolicy"}
      toaster={<ToastContainer />}
    >
      <StyledHeader>
        <UploadDocumentButton documentType={"TERMS_AND_CONDITIONS"}>
          <Button
            title={t("termsAndPolicy.termsAndConditions.button.creation")}
            width={19}
          />
        </UploadDocumentButton>
        <Spacer x={5} />
        <UploadDocumentButton documentType={"PRIVATE_POLICY"}>
          <Button
            title={t("termsAndPolicy.privatePolicy.button.creation")}
            width={22}
          />
        </UploadDocumentButton>
      </StyledHeader>
      <Spacer y={3} />
      <TableDS<keyof TermsAndPolicyDisplayed, TermsAndPolicyDisplayed>
        data={listDisplayed}
        headers={headers}
        render={render}
      />
    </Page>
  );
};

const StyledHeader = styled.div`
  display: flex;
  width: 100%;
`;
