import {
  triggerToast,
  dateFormatterDayMonthYearHourMinute,
  ButtonDS,
  DownloadOutlined,
  TableDS,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { StatementsListDisplayed } from "./statementApi";
import { unreachable } from "../../utils";
import {
  selectStatementCreationStatus,
  selectStatementExportLink,
  selectStatementExportStatus,
  selectStatementsList,
  selectStatementsListStatus,
  selectStatementsMultipleCreationStatus,
  statementDownloadAsync,
  statementExportAsync,
  statementsListAsync,
} from "./statementSlice";
import { useEffect } from "react";
import {
  companiesListAsync,
  selectCompaniesListStatus,
} from "./../companiesSlice";
import {
  selectZipFoldersExportLink,
  selectZipFoldersExportStatus,
  zipFolderDownloadAsync,
  zipFolderExportAsync,
} from "./../zipFolderSlice";
import { useParams } from "react-router-dom";

export const StatementTab = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const params = useParams();

  const companiesListStatus = useAppSelector(selectCompaniesListStatus);
  const statementsList = useAppSelector(selectStatementsList);
  const statementsListStatus = useAppSelector(selectStatementsListStatus);
  const statementCreationStatus = useAppSelector(selectStatementCreationStatus);
  const statementsMultipleCreationStatus = useAppSelector(
    selectStatementsMultipleCreationStatus,
  );
  const statementsExportStatus = useAppSelector(selectStatementExportStatus);
  const statementExportLink = useAppSelector(selectStatementExportLink);
  const zipFoldersExportStatus = useAppSelector(selectZipFoldersExportStatus);
  const zipFoldersExportLink = useAppSelector(selectZipFoldersExportLink);

  if (companiesListStatus === "idle") {
    void dispatch(companiesListAsync());
  }

  if (statementsListStatus === "idle") {
    void dispatch(statementsListAsync());
  }
  useEffect(() => {
    void dispatch(statementsListAsync());
  }, [dispatch, statementCreationStatus, statementsMultipleCreationStatus]);

  useEffect(() => {
    if (statementsExportStatus === "success") {
      triggerToast(t("statement.upload.success") || "", "valid");
    } else if (statementsExportStatus === "failed") {
      triggerToast(t("statement.upload.failure") || "", "error");
    }
  }, [statementsExportStatus, t]);

  useEffect(() => {
    if (statementExportLink.presignedUrl && statementExportLink.fileName) {
      void dispatch(statementDownloadAsync(statementExportLink));
    }
  }, [statementExportLink, dispatch]);

  useEffect(() => {
    if (zipFoldersExportLink.presignedUrl && zipFoldersExportLink.fileName) {
      void dispatch(zipFolderDownloadAsync(zipFoldersExportLink));
    }
  }, [zipFoldersExportLink, dispatch]);

  useEffect(() => {
    if (zipFoldersExportStatus === "success") {
      triggerToast(t("zipFolder.upload.success") || "", "valid");
    } else if (zipFoldersExportStatus === "failed") {
      triggerToast(t("zipFolder.upload.failure") || "", "error");
    }
  }, [zipFoldersExportStatus, t]);

  if (params.tab !== "statements") {
    return;
  }

  const headersStatementsList = {
    companyName: {
      text: t("statement.page.column.company"),
    },
    date: {
      text: t("statement.page.column.date"),
    },
    fileName: {
      text: t("statement.page.column.fileName"),
    },
    bucketFileName: {
      text: t("statement.page.column.download.xlsx"),
    },
    uuid: {
      text: t("statement.page.column.download.pdf"),
    },
    zipFolder: {
      text: t("statement.page.column.zipFolder"),
    },
  };

  const renderStatementsList =
    (row: StatementsListDisplayed) => (key: keyof StatementsListDisplayed) => {
      switch (key) {
        case "companyName":
        case "fileName":
          return row[key];
        case "bucketFileName":
          return (
            <ButtonDS
              sizeButton={"S"}
              format={"hug"}
              buttonType={"secondary"}
              singleIcon={{ icon: <DownloadOutlined />, size: "S" }}
              onClick={() => {
                void dispatch(
                  statementExportAsync({
                    bucketFileName: row["bucketFileName"] ?? "",
                    fileName: row["fileName"],
                  }),
                );
              }}
            />
          );
        case "date":
          return dateFormatterDayMonthYearHourMinute(new Date(row[key]));
        case "uuid":
          return (
            <ButtonDS
              sizeButton={"S"}
              format={"hug"}
              buttonType={"secondary"}
              singleIcon={{ icon: <DownloadOutlined />, size: "S" }}
              onClick={() => {
                void dispatch(
                  statementExportAsync({
                    bucketFileName: `${row["uuid"]}.pdf`,
                    fileName: `${row["fileName"].split(".xlsx")[0]}.pdf`,
                  }),
                );
              }}
            />
          );
        case "zipFolder":
          return (
            row["zipFolder"] && (
              <ButtonDS
                sizeButton={"S"}
                format={"hug"}
                buttonType={"secondary"}
                singleIcon={{ icon: <DownloadOutlined />, size: "S" }}
                onClick={() => {
                  void dispatch(
                    zipFolderExportAsync({
                      uuid: `${row["zipFolder"]}`,
                      fileName: `${row["fileName"].split(".xlsx")[0]}.zip`,
                    }),
                  );
                }}
              />
            )
          );
      }
      unreachable(key);
    };

  return (
    <TableDS<keyof StatementsListDisplayed, StatementsListDisplayed>
      data={statementsList}
      headers={headersStatementsList}
      render={renderStatementsList}
    />
  );
};
