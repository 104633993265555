import { FunctionComponent, ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../../styles/figmaColors";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { Cross } from "../../../assets/assets";
import { Section } from "./Section";
import { Spacer } from "../Spacer";

type SidePanelProps = {
  visible: boolean;
  onClose?: () => unknown;
  children?:
    | ReactElement<typeof Section>
    | Iterable<ReactElement<typeof Section>>;
  absoluteSection?: ReactElement<typeof Section>;
};

export const SidePanelDS: FunctionComponent<SidePanelProps> = (props) => {
  const outsideSidePanel = useOutsideClick(() => {
    setIsOpen(true);
    props?.onClose?.();
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (props.visible) {
      setIsOpen(true);
    }
  }, [props.visible]);

  return (
    <StyledContainer ref={outsideSidePanel}>
      <Spacer x={0.5} />
      <StyledColumn
        $visible={isOpen}
        onClick={(event) => event.stopPropagation()}
      >
        <Spacer y={0.5} />
        <StyledContent $visible={isOpen} ref={outsideSidePanel}>
          <StyledHeader>
            <StyledAroundCloseIcon onClick={props.onClose}>
              <Cross />
            </StyledAroundCloseIcon>
          </StyledHeader>
          {props.children}
          <Spacer y={0.5} />
          {props.absoluteSection && <Spacer y={13} />}
        </StyledContent>
        <Spacer y={0.5} />
        {props.absoluteSection && (
          <StyledBottomButton>
            <StyledAbsolute>
              <StyledBody>
                <Section verticalSpacing={1.5} horizontalSpacing={1.5}>
                  {props.absoluteSection}
                </Section>
              </StyledBody>
              <Spacer y={0.5} />
            </StyledAbsolute>
          </StyledBottomButton>
        )}
      </StyledColumn>
      <Spacer x={0.5} />
    </StyledContainer>
  );
};

const StyledBody = styled.div`
  display: flex;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-top: 0.0625rem solid ${colors["colors/borders/cards/cards"]};
  background-color: ${colors["colors/surfaces/background/background_level0"]};
`;
const StyledBottomButton = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;
const StyledAbsolute = styled.div`
  position: absolute;
  width: 25.7rem;
`;
const StyledContainer = styled.div`
  z-index: 100;
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  font-size: 0.875rem;
  width: 100%;
`;

const StyledContent = styled.div<{ $visible: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 0.5rem;
  background-color: white;
  overflow: auto;
  box-shadow:
    0px 0px 15px rgba(143, 143, 143, 0.15),
    0px 2px 5px rgba(143, 143, 143, 0.15);
  border: solid 1px ${colors["colors/borders/cells/cells"]};
  border: ${({ $visible }) =>
    $visible ? `solid 1px ${colors["colors/borders/cells/cells"]}` : "none"};
  backdrop-filter: none;
  & > *:nth-child(n + 3) {
    border-top: 0.0625rem solid ${colors["colors/borders/cards/cards"]};
  }
`;

const StyledColumn = styled.div<{ $visible: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: ${({ $visible }) => ($visible ? "25.875rem" : "0rem")};
  transition: all 0.25s ease-out;
  pointer-events: auto;
`;

const StyledAroundCloseIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5rem;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  &:hover {
    background-color: ${colors["colors/surfaces/background/background_level1"]};
  }
`;

const StyledHeader = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
`;
