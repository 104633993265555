import styled from "styled-components";
import { colors } from "../../styles/colors";
import { Spacer } from "../Spacer";
import SortIcon from "../../assets/sort.svg?react";
import { ElementButton } from "../ElementButton";

export interface TableHeaderProps {
  iconSrc?: JSX.Element;
  text: string;
  hasButton?: boolean;
  element?: JSX.Element;
  sortAction?: () => void;
}

export const TableHeader = (props: TableHeaderProps) => {
  return (
    <StyledContainer>
      <StyledContainerLeft>
        <Spacer x={0.5} />
        {props.iconSrc}
        {props.iconSrc && <Spacer x={0.5} />}
        <StyledText>{props.text}</StyledText>
      </StyledContainerLeft>
      {props.sortAction && (
        <ElementButton
          onClick={() => props.sortAction && props.sortAction()}
          Element={<SortIcon />}
        />
      )}
      <Spacer x={1} />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  min-width: 11rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const StyledContainerLeft = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

const StyledText = styled.div`
  color: ${colors.contentTertiary};
  font-size: 14;
  font-weight: 500;
  font-family: Inter;
  &::first-letter {
    text-transform: uppercase;
  }
  cursor: default;
`;
