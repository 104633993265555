import styled from "styled-components";
import { Spacer } from "./Spacer";
import { Tooltip } from "react-tooltip";
import { TextCapitalized } from "../designSystem/components/text/TextCapitalized";

type DirectionType = "top" | "right" | "bottom" | "left";

export interface TooltipComponentProps {
  id: string;
  place: DirectionType;
  title?: string;
  content?: string;
  withoutSpacer?: boolean;
}

export const TooltipComponent = (props: TooltipComponentProps) => {
  return (
    <StyledTooltip id={props.id} place={props.place}>
      {!props.withoutSpacer && <Spacer y={1} />}
      {props.title && (
        <>
          <StyledTooltipTitle>
            <TextCapitalized>{props.title}</TextCapitalized>
          </StyledTooltipTitle>
          <Spacer y={0.75} />
        </>
      )}
      {props.content && <TextCapitalized>{props.content}</TextCapitalized>}
      {!props.withoutSpacer && <Spacer y={1} />}
    </StyledTooltip>
  );
};

const StyledTooltip = styled(Tooltip)`
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem !important;
  max-width: 20rem;
  background-color: black;
  font-weight: 400;
  font-size: 1rem;
  font-family: Inter;
  font-style: normal;
  z-index: 666;
`;

const StyledTooltipTitle = styled.div`
  font-weight: 600;
`;
