import { FormEvent } from "react";
import { Input, InputProps } from "./Input";

interface NumericInputProps extends InputProps {
  value: number | "";
  update: (e: number | "") => void;
  floatNumber?: boolean;
  maxDigits?: number;
}

export const NumericInput = (props: NumericInputProps) => {
  const updateValue = (e: FormEvent<HTMLInputElement>) => {
    const regexNumber = props.maxDigits
      ? new RegExp("^([0-9]){0," + props.maxDigits.toString() + "}$")
      : /^([0-9]){0,10}$/;
    const regexFloatNumber = props.maxDigits
      ? new RegExp(
          "^([0-9]{0," + props.maxDigits.toString() + "})([.][0-9]{0,3})?$",
        )
      : /^([0-9]{0,10})([.][0-9]{0,3})?$/;

    const number = (e.target as HTMLInputElement).value;

    if (!number || number === "") {
      return props.update("");
    }
    if (!props.floatNumber && number.match(regexNumber)) {
      return props.update(parseInt(number));
    }
    if (props.floatNumber && number.match(regexFloatNumber)) {
      return props.update(parseFloat(number));
    }
    return props.update(props.value);
  };
  return (
    <Input onChange={updateValue} type={"number"} {...props} pattern="[0-9]*" />
  );
};
