import styled from "styled-components";
import { Spacer } from "./Spacer";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TextCapitalized } from "./text/TextCapitalized";
import {
  ClearOutlined,
  ContactSupportOutlined,
} from "../materialUi/materialUi";
import { ButtonDS } from "./Button";
import { colors } from "../../styles/figmaColors";
import { typographies } from "../../styles/figmaTypographies";

export const SupportDS = () => {
  const [isSupportOpen, setIsSupportOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <StyledSupport>
      {isSupportOpen ? (
        <StyledSupportOpen>
          <StyledSupportCard>
            <Spacer y={1.25} />
            <StyledSupportCardTitle>
              <Spacer x={1.25} />
              <TextCapitalized>{t("dashboard.support.title")}</TextCapitalized>
              <Spacer x={1.25} />
            </StyledSupportCardTitle>
            <Spacer y={0.25} />
            <StyledFlex>
              <Spacer x={1.25} />
              <TextCapitalized>{t("dashboard.support.body")} </TextCapitalized>
              <Spacer x={1.25} />
            </StyledFlex>
            <Spacer y={1} />
            <StyledFlex>
              <Spacer x={1.25} />
              <StyledSupportCardInfo>
                {t("dashboard.support.phone")}
              </StyledSupportCardInfo>
              <Spacer x={1.25} />
            </StyledFlex>
            <Spacer y={1} />
            <StyledFlex>
              <Spacer x={1.25} />
              <StyledSupportCardInfo>
                {t("dashboard.support.mail")}
              </StyledSupportCardInfo>
              <Spacer x={1.25} />
            </StyledFlex>
            <Spacer y={1.25} />
          </StyledSupportCard>
          <Spacer y={0.75} />
          <ButtonDS
            text={t("dashboard.support.close")}
            onClick={() => setIsSupportOpen(false)}
            leftIcon={<ClearOutlined />}
            format={"hug"}
            buttonType={"secondary"}
          />
        </StyledSupportOpen>
      ) : (
        <ButtonDS
          text={t("dashboard.support")}
          onClick={() => setIsSupportOpen(true)}
          format={"hug"}
          buttonType={"secondary"}
          leftIcon={<ContactSupportOutlined />}
        />
      )}
    </StyledSupport>
  );
};

const StyledSupportCard = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-width: 17rem;
  border: 0.0625rem solid ${colors["colors/borders/cards/cards"]};
  box-shadow: 0 0.25rem 0.5rem rgba(83, 86, 84, 0.05);
  border-radius: 0.5rem;
  align-items: flex-start;
  color: ${colors["colors/text/black"]};
  background-color: ${colors["colors/surfaces/background/background_level0"]};
  ${typographies["Body/S"]}
`;

const StyledSupportOpen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const StyledSupportCardTitle = styled.div`
  display: flex;
  ${typographies["Body/L"]}
`;

const StyledSupportCardInfo = styled.div`
  display: flex;
  border-radius: 0.5rem;
  background-color: ${colors["colors/surfaces/background/background_level1"]};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 2rem;
  ${typographies["Body/M"]}
`;

const StyledSupport = styled.div`
  position: absolute;
  z-index: 1;
  right: 2.5rem;
  bottom: 2rem;
`;

const StyledFlex = styled.div`
  display: flex;
  width: 100%;
`;
