import { ComponentPropsWithRef, forwardRef, useState, FocusEvent } from "react";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import ClosedEye from "../../assets/closedEye.svg?react";
import OpenEye from "../../assets/openEye.svg?react";
import { Spacer } from "../Spacer";
import { capitalize } from "../../format";
import { ElementButton } from "../ElementButton";
import { TextCapitalized } from "../../designSystem/components/text/TextCapitalized";
export interface InputProps extends ComponentPropsWithRef<"input"> {
  label?: string | JSX.Element | JSX.Element[];
  error?: string;
  hasSmallBottomSpacer?: boolean;
  noneBottomSpacer?: boolean;
  width?: number;
  endText?: string;
  isPlaceholderUppercase?: boolean;
  isMandatory?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      type,
      error,
      hasSmallBottomSpacer,
      noneBottomSpacer,
      isPlaceholderUppercase,
      width,
      endText,
      isMandatory,
      ...props
    },
    ref,
  ) => {
    const isVisible = false;
    const [isPasswordVisible, setIsPasswordVisible] = useState(isVisible);
    const [isInputFocus, setIsInputFocus] = useState<boolean>(false);
    const isPassword = type === "password";
    const inputType = type;

    const togglePasswordVisibility = () => {
      setIsPasswordVisible((isVisible) => !isVisible);
    };

    return (
      <StyledContainer $width={width || null}>
        {label && (
          <StyledLabel>
            <TextCapitalized>{label}</TextCapitalized>
            <Spacer x={0.5} />
            {isMandatory && <StyledMandatory>*</StyledMandatory>}
          </StyledLabel>
        )}

        <StyledBackground
          $isError={error !== undefined}
          $isInputFocus={isInputFocus}
        >
          <StyledInputContainer
            $isError={error !== undefined}
            $width={width || null}
            $isInputFocus={isInputFocus}
          >
            <StyledInputFlex>
              <StyledInputValue
                $isError={error !== undefined}
                ref={ref}
                {...props}
                $isPassword={true}
                type={isPasswordVisible ? "text" : inputType}
                placeholder={
                  isPlaceholderUppercase
                    ? props.placeholder
                    : props.placeholder && capitalize(props.placeholder)
                }
                width={width || null}
                onFocus={() => {
                  setIsInputFocus(true);
                }}
                onBlur={(e: FocusEvent<HTMLInputElement>) => {
                  props.onBlur && props.onBlur(e);
                  setIsInputFocus(false);
                }}
              />
              {isPassword ? (
                <>
                  {isPasswordVisible ? (
                    <ElementButton
                      onClick={togglePasswordVisibility}
                      Element={<OpenEye />}
                    />
                  ) : (
                    <ElementButton
                      onClick={togglePasswordVisibility}
                      Element={<ClosedEye />}
                    />
                  )}
                  <Spacer x={1} />
                </>
              ) : (
                endText && (
                  <StyledEndText>
                    {endText}
                    <Spacer x={1} />
                  </StyledEndText>
                )
              )}
            </StyledInputFlex>
          </StyledInputContainer>
        </StyledBackground>
        {error && (
          <div>
            <Spacer y={0.5} />
            <StyledErrorMessage id={`${props.id || ""}-error`}>
              {error}
            </StyledErrorMessage>
          </div>
        )}
        {hasSmallBottomSpacer ? (
          <Spacer y={1} />
        ) : (
          !noneBottomSpacer && <Spacer y={1.5} />
        )}
      </StyledContainer>
    );
  },
);

const StyledContainer = styled.div<{ $width: number | null }>`
  width: ${({ $width }) => ($width ? `${$width}rem` : "100%")};
`;

const StyledMandatory = styled.div`
  color: red;
`;

const StyledErrorMessage = styled.div`
  color: ${colors.alertMessage};
  font-size: 1rem;
  line-height: 1rem;
  &::first-letter {
    text-transform: uppercase;
  }
`;

const StyledLabel = styled.label`
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  font-family: Inter;
  color: ${colors.grey};
  border: solid 0.25rem transparent;
`;

const StyledInputFlex = styled.div`
  display: flex;
  width: 100%;
`;

const StyledInputValue = styled.input<{
  $isPassword: boolean;
  $isError: boolean;
  width: number | null;
}>`
  width: ${({ width }) => (width ? `${width}rem` : "100%")};
  border-radius: 0.5rem;
  border-color: ${({ $isPassword, $isError }) =>
    $isPassword ? "transparent" : $isError ? colors.alert80 : colors.ligthGrey};
  height: 3.3rem;
  font-size: 1rem;
  line-height: 100%;
  color: ${colors.grey};
  background-color: ${({ $isPassword, $isError }) =>
    $isPassword ? "transparent" : $isError ? colors.alert20 : colors.white};
  &:focus {
    outline: none;
    background-size:
      100% 2px,
      100% 1px;
  }
  padding: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  &::placeholder {
    color: ${colors.disabled};
  }
`;

const StyledInputContainer = styled.div<{
  $width: number | null;
  $isError: boolean;
  $isInputFocus: boolean;
}>`
  display: flex;
  width: ${({ $width }) => ($width ? `${$width}rem` : "100%")};
  border: solid 1px;
  border-radius: 0.5rem;
  border-color: ${({ $isError }) =>
    $isError ? colors.alert80 : colors.ligthGrey};
  background-color: ${({ $isError }) =>
    $isError ? colors.alert20 : "transparent"};
  outline: none;
  &:focus {
    outline: none;
    background-size: 100% 2px, 100% 1px;
  }
  border-color: ${({ $isError, $isInputFocus }) =>
    !$isError && $isInputFocus && colors.primary};
  input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    margin:0;
`;

const StyledBackground = styled.div<{
  $isError: boolean;
  $isInputFocus: boolean;
}>`
  display: flex;
  transition: all 0.4s ease-out;
  border: solid 0.25rem;
  border-color: transparent;
  border-radius: 0.75rem;
  border-color: ${({ $isError, $isInputFocus }) =>
    !$isError && $isInputFocus && colors.primary33};
  &:hover {
    transition: all 0.4s ease-out;
    border-color: ${({ $isError }) => !$isError && colors.primary33};
  }
`;

const StyledEndText = styled.div`
  display: flex;
  background: transparent;
  align-items: center;
  justify-content: flex-end;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  color: ${colors.contentSecondary};
`;
