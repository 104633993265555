import { t } from "i18next";
import {
  dateFormatterDay,
  dateFormatterDayMonthLong,
  dateFormatterDayMonthYear,
} from "./format";

// Externally-visible signature
export function unreachable(p: never): never;
// Implementation signature
export function unreachable() {
  throw new Error("Switch is not exhaustive");
}

export const filteredValuesToTranslate = <T extends object>(
  values: T[],
  text: string,
  keysToTranslate?: Array<keyof T>,
  findKeysToTranslate?: (key: keyof T, value: string) => string,
  keysToIgnore?: Array<keyof T | string>,
) =>
  values.filter((row: T) => {
    const allKeys = Object.keys(row) as Array<keyof T>;
    const translatedValues = allKeys.reduce((newRow, key) => {
      if (keysToIgnore && keysToIgnore.includes(key)) {
        return newRow;
      } else if (
        keysToTranslate &&
        findKeysToTranslate &&
        keysToTranslate.includes(key)
      ) {
        return {
          ...newRow,
          [key]: t(findKeysToTranslate(key, row[key] as string))
            ? t(findKeysToTranslate(key, row[key] as string))
            : findKeysToTranslate(key, row[key] as string),
        };
      } else {
        return { ...newRow, [key]: row[key] };
      }
    }, {});
    const allValues = Object.values(translatedValues);
    const allValuesString = allValues.toString().toLowerCase().split(",");
    return text
      .split(" ")
      .every((word) =>
        allValuesString.find((values) => values.includes(word.toLowerCase())),
      );
  });

export const getDeliveryDate = (
  firstDeliveryDay: number,
  endDeliveryDay: number,
) => {
  const now = new Date();
  const firstDate = new Date(
    new Date().setDate(now.getDate() + firstDeliveryDay),
  );
  const lastDate = new Date(new Date().setDate(now.getDate() + endDeliveryDay));

  if (firstDate.getDay() === 5) {
    firstDate.setDate(firstDate.getDate() + 2);
    lastDate.setDate(lastDate.getDate() + 2);
  } else if (firstDate.getDay() === 6) {
    firstDate.setDate(firstDate.getDate() + 1);
    lastDate.setDate(lastDate.getDate() + 1);
  }

  if (lastDate.getDay() === 5) {
    lastDate.setDate(lastDate.getDate() + 2);
  } else if (lastDate.getDay() === 6) {
    lastDate.setDate(lastDate.getDate() + 1);
  }

  const firstDateDisplayed =
    firstDate.getMonth() === lastDate.getMonth()
      ? dateFormatterDay(firstDate)
      : dateFormatterDayMonthLong(firstDate);

  return firstDateDisplayed + "-" + dateFormatterDayMonthYear(lastDate);
};
