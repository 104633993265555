import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { QueryStatus, authAxios } from "../../utils";
import { RootState } from "../../redux/store";
import {
  ExportDeposit,
  ExportDepositPayload,
  GetDepositsApi,
  monthlyCreate,
} from "./depositApi";
import axios from "axios";

export interface DepositState {
  depositsListStatus: QueryStatus;
  monthlyCreateStatus: QueryStatus;
  depositsList: GetDepositsApi[];
  depositExportStatus: QueryStatus;
  depositExportLink: ExportDepositPayload;
  depositDownloadStatus: QueryStatus;
}

const initialState: DepositState = {
  depositsListStatus: "idle",
  monthlyCreateStatus: "idle",
  depositsList: [],
  depositExportStatus: "idle",
  depositExportLink: {
    presignedUrl: null,
    fileName: null,
  },
  depositDownloadStatus: "idle",
};

export const depositsListAsync = createAsyncThunk(
  "depositsList/call",
  async (payload: { companyUuid: string }) => {
    const axios = authAxios();
    const response = await axios.get<GetDepositsApi[]>(
      `/deposits/${payload.companyUuid}`,
    );
    return response.data;
  },
);

export const monthlyCreateAsync = createAsyncThunk(
  "monthlyCreate/call",
  async (payload: monthlyCreate) => {
    const axios = authAxios();
    await axios.post("/deposits/monthly_create", {
      ...payload,
      amount: payload.amount * 100,
    });
  },
);

export const depositExportAsync = createAsyncThunk(
  "depositExport/call",
  async (payload: ExportDeposit) => {
    const axios = authAxios();
    const response = await axios.post<{ presignedUrl: string | null }>(
      `deposits/${payload.uuid}/link`,
    );
    return {
      presignedUrl: response.data.presignedUrl,
      fileName: `${payload.number}.pdf`,
    };
  },
);

export const depositDownloadAsync = createAsyncThunk(
  "depositDownload/call",
  async (depositExportLink: ExportDepositPayload) => {
    if (depositExportLink.presignedUrl && depositExportLink.fileName) {
      const response = await axios({
        url: depositExportLink.presignedUrl,
        method: "GET",
        responseType: "blob",
      });
      const a = document.createElement("a");
      a.download = depositExportLink.fileName || "yourDepositFile.pdf";
      const url = window.URL.createObjectURL(new Blob([response.data]));
      a.href = url;
      a.click();
    }
  },
);

export const depositSlice = createSlice({
  name: "deposit",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(depositsListAsync.pending, (state) => {
        state.depositsListStatus = "processing";
      })
      .addCase(depositsListAsync.fulfilled, (state, action) => {
        state.depositsList = action.payload;
        state.depositsListStatus = "success";
      })
      .addCase(depositsListAsync.rejected, (state) => {
        state.depositsListStatus = "failed";
      })
      .addCase(monthlyCreateAsync.pending, (state) => {
        state.monthlyCreateStatus = "processing";
      })
      .addCase(monthlyCreateAsync.fulfilled, (state) => {
        state.monthlyCreateStatus = "success";
        state.depositsListStatus = "idle";
      })
      .addCase(monthlyCreateAsync.rejected, (state) => {
        state.monthlyCreateStatus = "failed";
      })
      .addCase(depositExportAsync.pending, (state) => {
        state.depositExportStatus = "processing";
      })
      .addCase(depositExportAsync.fulfilled, (state, action) => {
        state.depositExportLink = action.payload;
        state.depositExportStatus = "success";
        state.depositsListStatus = "idle";
      })
      .addCase(depositExportAsync.rejected, (state) => {
        state.depositExportStatus = "failed";
      })
      .addCase(depositDownloadAsync.pending, (state) => {
        state.depositDownloadStatus = "processing";
      })
      .addCase(depositDownloadAsync.fulfilled, (state) => {
        state.depositExportLink.presignedUrl = null;
        state.depositExportLink.fileName = null;
        state.depositDownloadStatus = "success";
        state.depositExportStatus = "idle";
      })
      .addCase(depositDownloadAsync.rejected, (state) => {
        state.depositDownloadStatus = "failed";
      });
  },
});

export const selectDepositsList = (state: RootState) =>
  state.deposit.depositsList;
export const selectDepositsListStatus = (state: RootState) =>
  state.deposit.depositsListStatus;
export const selectMonthlyCreateStatus = (state: RootState) =>
  state.deposit.monthlyCreateStatus;
export const selectDepositExportStatus = (state: RootState) =>
  state.deposit.depositExportStatus;
export const selectDepositExportLink = (state: RootState) =>
  state.deposit.depositExportLink;
export const selectDepositDownloadStatus = (state: RootState) =>
  state.deposit.depositDownloadStatus;

export default depositSlice.reducer;
