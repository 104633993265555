import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { colors } from "../../styles/colors";
import { Spacer } from "../Spacer";
import { Support } from "../Support";
import { QiviaLogo } from "../../assets/assets";

export interface OnboardingPageProps {
  title?: JSX.Element;
  title2?: JSX.Element;
  subtitle?: string;
  form?: JSX.Element;
  toaster?: JSX.Element;
}

export const OnboardingPage = (props: OnboardingPageProps) => {
  const { t } = useTranslation();
  return (
    <StyledFrame>
      {props.toaster}
      <Spacer x={2} />
      <StyledContainer>
        <Spacer y={2} />
        <StyledLogo>
          <QiviaLogo/>
        </StyledLogo>
        <StyledForm>
          <Spacer y={2} />
          <StyledColumn>
            {props.title}
            {props.title2}
            <StyledSubTitle>{t(`${props.subtitle || ""}`)}</StyledSubTitle>
            <Spacer y={2.5} />
            {props.form}
          </StyledColumn>
          <Spacer x={2} />
        </StyledForm>
        <Spacer y={6} />
      </StyledContainer>
      <StyledSupport>
          <Support color={"green"} />
        </StyledSupport>
      <Spacer x={2} />
    </StyledFrame>
  );
};

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  height: 500%;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 30.5rem;
`;

const StyledSubTitle = styled.div`
  font-weight: 400;
  text-align: justify;
  color: ${colors.black};
  &::first-letter {
    text-transform: uppercase;
  }
`;

const StyledFrame = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  background: white;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 500%;
  overflow: auto;
`;

const StyledLogo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 6.5rem;
  height: 3rem;
`;

const StyledSupport = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1;
`;
