import styled from "styled-components";
import { colors } from "../styles/colors";
import { Spacer } from "./Spacer";
import Cross from "../assets/cross.svg?react";
import { MouseEvent } from "react";

export interface ModalProps {
  children?: string | JSX.Element | JSX.Element[];
  visible: boolean;
  title?: string;
  width?: number;
  onClose?: () => void;
}
export const Modal = (props: ModalProps) => {
  const clickOnModal = (targetElement: string) => {
    if (targetElement === "backgroundModal") {
      props.onClose && props.onClose();
    }
  };

  return (
    <StyledBackground
      $visible={props.visible}
      id="backgroundModal"
      onClick={(e: MouseEvent<HTMLElement>) =>
        clickOnModal((e.target as HTMLElement)?.id)
      }
    >
      <Spacer x={3} />
      <StyledColumn>
        <Spacer y={4} />
        <StyledContainer $width={props.width || 0}>
          <Spacer y={2} />
          <StyledModalBar>
            <Spacer x={2.5} />
            {props.title && <StyledModalTitle>{props.title}</StyledModalTitle>}
            <StyledAroundCloseIcon onClick={props.onClose}>
              <Cross />
            </StyledAroundCloseIcon>
            <Spacer x={2} />
          </StyledModalBar>
          <Spacer y={2} />
          <StyledFlex>
            <Spacer x={2} />
            <StyledChildren>{props.children}</StyledChildren>
            <Spacer x={2} />
          </StyledFlex>
        </StyledContainer>
        <Spacer y={4} />
      </StyledColumn>
      <Spacer x={3} />
    </StyledBackground>
  );
};

export const StyledModalTitle = styled.div`
  width: 100%;
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 600;
  &::first-letter {
    text-transform: uppercase;
  }
`;
export const StyledAroundCloseIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5rem;
  min-width: 2.5rem;
  min-height: 2.5rem;
  cursor: pointer;
  &:hover {
    background-color: ${colors.background};
  }
`;
export const StyledModalBar = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  height: 2.5rem;
`;
export const StyledContainer = styled.div<{ $width: number }>`
  display: flex;
  flex-direction: column;
  width: ${({ $width }) => ($width === 0 ? "25rem" : `${$width}rem`)};
  border-radius: 0.5rem;
  background-color: white;
  border: solid 1px ${colors.silver};
  overflow: auto;
`;
export const StyledBackground = styled.div<{ $visible: boolean }>`
  z-index: 10;
  position: fixed;
  display: ${({ $visible }) => ($visible ? "flex" : "none")};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  backdrop-filter: blur(0.25rem);
  background-color: ${colors.ligthGrey};
  box-shadow:
    0px 0px 15px rgba(143, 143, 143, 0.15),
    0px 2px 5px rgba(143, 143, 143, 0.15);
  overflow: auto;
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100vh;
  justify-content: center;
`;

export const StyledFlex = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const StyledChildren = styled.div`
  display: block;
  width: 100%;
`;

export const StyledBackgroundButton = styled.div`
  display: flex;
  border: solid 0.25rem transparent;
`;
