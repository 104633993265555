import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { QueryStatus, authAxios } from "../../utils";
import { RootState } from "../../redux/store";
import { CreateTankCapacityInput } from "./tankCapacitiesApi";

export interface DriverState {
  createTankCapacityStatus: QueryStatus;
}

const initialStateDriver: DriverState = {
  createTankCapacityStatus: "idle",
};

export const createTankCapacity = createAsyncThunk(
  "tankCapacities/create",
  async (payload: CreateTankCapacityInput) => {
    const axios = authAxios();
    await axios.post("/tank_capacity", payload);
  },
);

export const tankCapacitiesSlice = createSlice({
  name: "tankCapacities",
  initialState: initialStateDriver,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createTankCapacity.pending, (state) => {
        state.createTankCapacityStatus = "processing";
      })
      .addCase(createTankCapacity.fulfilled, (state) => {
        state.createTankCapacityStatus = "success";
      })
      .addCase(createTankCapacity.rejected, (state) => {
        state.createTankCapacityStatus = "failed";
      });
  },
});

export const selectCreateTankCapacityStatus = (state: RootState) =>
  state.tankCapacities.createTankCapacityStatus;

export default tankCapacitiesSlice.reducer;
