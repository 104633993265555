import { FunctionComponent, ReactElement } from "react";
import styled from "styled-components";
import { colors } from "../../../styles/figmaColors";
import { ActionStripe } from "./ActionStripe";
import { SectionedActionStripe } from "./SectionedActionStripe";

type ActionBlockProps = {
  children?:
    | ReactElement<typeof ActionStripe | typeof SectionedActionStripe>
    | Iterable<
        ReactElement<typeof ActionStripe | typeof SectionedActionStripe>
      >;
};

export const ActionBlock: FunctionComponent<ActionBlockProps> = (props) => (
  <StyledContainer>{props.children}</StyledContainer>
);

const StyledContainer = styled.div`
  border-radius: 0.5rem;
  border: 0.0625rem solid ${colors["colors/borders/input/default"]};
  width: 100%;
  height: fit-content;
  background-color: white;

  & > *:nth-child(n + 2) {
    border-top: 0.0625rem solid ${colors["colors/borders/input/default"]};
  }
`;
