import styled from "styled-components";

export interface ElementButtonProps {
  onClick: () => void;
  Element: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | JSX.Element;
  colorElement?: string;
}

export const ElementButton = (props: ElementButtonProps) => {
  const { onClick, Element, colorElement } = props;

  return (
    <StyledButton onClick={onClick} $colorElement={colorElement}>
      <>{Element}</>
    </StyledButton>
  );
};

const StyledButton = styled.div<{ $colorElement?: string }>`
  display: flex;
  align-items: center;
  max-width: fit-content;
  height: 100%;
  background-color: transparent;
  justify-content: center;
  cursor: pointer;
  & svg {
    fill: ${({ $colorElement }) => $colorElement};
  }
`;
