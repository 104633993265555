import { HTMLAttributes } from "react";
import styled from "styled-components";
import { Spacer } from "../../components/Spacer";
import { TextCapitalized } from "./../text/TextCapitalized";
import { colors } from "../../../styles/figmaColors";
import { typographies } from "../../../styles/figmaTypographies";
import { ElementInitial } from "./../ElementInitial";
import {
  KeyboardArrowDownOutlined,
  KeyboardArrowUptOutlined,
} from "../../materialUi/materialUi";
import { useTranslation } from "react-i18next";

export interface SideBarCompaniesProps extends HTMLAttributes<HTMLDivElement> {
  text: string;
  version: "S" | "L";
  onClick: () => void;
  hasFocusBorder?: boolean;
}

export const SideBarCompanies = ({
  text,
  version,
  onClick,
  hasFocusBorder,
}: SideBarCompaniesProps) => {
  const { t } = useTranslation();

  return (
    <StyledColumn $version={version} $hasFocusBorder={hasFocusBorder || false}>
      <Spacer y={0.25} />
      <StyledFlexContainer onClick={onClick}>
        <Spacer x={0.25} />
        <StyledContainer>
          <div>
            <ElementInitial
              text={text[0]}
              size={"ML"}
              color={colors["colors/surfaces/background/background_level0"]}
              backgroundColor={colors["colors/button/avatar/grey"]}
              format={"square"}
              clickable={true}
            />
          </div>

          {version === "L" && (
            <>
              <StyledFlexContainer onClick={onClick}>
                <Spacer x={0.75} />
                <StyledText>
                  <StyledTitle>
                    <TextCapitalized>{t(`home.menu.company`)}</TextCapitalized>
                  </StyledTitle>
                  <Spacer y={0.15} />
                  <TextCapitalized>{text}</TextCapitalized>
                </StyledText>
              </StyledFlexContainer>
              <StyledArrows>
                <KeyboardArrowUptOutlined />
                <KeyboardArrowDownOutlined />
              </StyledArrows>
            </>
          )}
        </StyledContainer>
        <Spacer x={0.25} />
      </StyledFlexContainer>
      <Spacer y={0.25} />
    </StyledColumn>
  );
};

const StyledFlexContainer = styled.div`
  width: 100%;
  display: flex;
`;

const StyledArrows = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  & svg {
    height: 0.75rem;
    width: 1rem;
    fill: ${colors["colors/text/white"]};
  }
`;

const StyledTitle = styled.div`
  ${typographies["Body/XXS"]}
  color: ${colors["colors/text/veryLightGrey"]};
`;

const StyledText = styled.div`
  ${typographies["Body/M"]}
  color: ${colors["colors/text/white"]};
`;

interface StyledContainerProps {
  $version: "S" | "L";
  $hasFocusBorder: boolean;
}

const StyledColumn = styled.div<StyledContainerProps>`
  display: flex;
  width: ${({ $version }) => ($version === "S" ? "" : "100%")};
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  border: 0.0625rem solid;
  border-color: ${({ $hasFocusBorder }) =>
    $hasFocusBorder
      ? colors["colors/surfaces/background/background_level0"]
      : colors["colors/accent/900"]};
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  border-radius: 0.5rem;
  cursor: pointer;
  width: 100%;
  word-break: break-all;
`;
