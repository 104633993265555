import styled from "styled-components";
import { colors } from "../../styles/figmaColors";
import { typographies } from "../../styles/figmaTypographies";
import { TextCapitalized } from "./text/TextCapitalized";
import { Spacer } from "./Spacer";
import { CheckOutlined } from "../materialUi/materialUi";

export type StepStatusType = "DONE" | "CURRENT" | "TODO";

const statusProps = {
  DONE: {
    contentColor: colors["colors/text/black"],
    backgroundColor: colors["colors/borders/cells/cells"],
    borderCircleColor: colors["colors/borders/cells/cells"],
    textColor: colors["colors/text/darkGrey"],
    icon: <CheckOutlined />,
  },
  CURRENT: {
    contentColor: colors["colors/text/white"],
    backgroundColor: colors["colors/accent/600"],
    borderCircleColor: colors["colors/accent/600"],
    textColor: colors["colors/accent/600"],
    icon: null,
  },
  TODO: {
    contentColor: colors["colors/text/black"],
    backgroundColor: colors["colors/surfaces/background/background_level0"],
    borderCircleColor: colors["colors/borders/cells/cells"],
    textColor: colors["colors/text/darkGrey"],
    icon: null,
  },
};

type StepStatusProps = {
  type: StepStatusType;
  index: number;
  text: string;
};

export const StepStatus = (props: StepStatusProps) => {
  const options = statusProps[props.type];

  return (
    <StyledContainer>
      <StyledCircle
        $color={options.contentColor}
        $backgroundColor={options.backgroundColor}
        $borderCircleColor={options.borderCircleColor}
      >
        {options.icon ?? props.index}
      </StyledCircle>
      <Spacer y={0.5} />
      <StyledText $color={options.textColor}>
        <TextCapitalized>{props.text}</TextCapitalized>
      </StyledText>
    </StyledContainer>
  );
};

interface StyledContainerArgs {
  $backgroundColor?: string;
  $color?: string;
  $borderCircleColor?: string;
  $textColor?: string;
}

const StyledText = styled.div<StyledContainerArgs>`
  color: ${({ $color }) => $color};
  display: flex;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${typographies["Body/M"]}
  justify-content: center;
  align-items: center;
`;

const StyledCircle = styled.div<StyledContainerArgs>`
  display: flex;
  color: ${({ $color }) => $color};
  & svg {
    width: 1rem;
    height: 1rem;
  }
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 4.875rem;
  align-items: center;
  justify-content: center;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border: 0.0625rem solid ${({ $borderCircleColor }) => $borderCircleColor};
`;
