import styled from "styled-components";
import { colors } from "../../styles/figmaColors";
import { Spacer } from "./Spacer";
import { Avatar } from "./Avatar/Avatar";
import { TextCapitalized } from "./text/TextCapitalized";
import { typographies } from "../../styles/figmaTypographies";

export interface DriverCardProps {
  firstName: string;
  lastName: string;
  badge?: JSX.Element;
}

export const DriverCard = (props: DriverCardProps) => {
  const { firstName, lastName, badge } = props;
  return (
    <StyledContainer>
      <Spacer y={1} />
      <StyledDriverCard>
        <Spacer x={1.5} />
        <StyledDriver>
          <Avatar
            color={"blue"}
            size={"M"}
            firstName={firstName}
            lastName={lastName}
          />
          <Spacer x={0.5} />
          <TextCapitalized>{firstName}</TextCapitalized>
          <Spacer x={0.3} />
          <TextCapitalized>{lastName}</TextCapitalized>
          <Spacer x={1} />
        </StyledDriver>
        {badge && (
          <StyledBadge>
            <>{badge}</>
          </StyledBadge>
        )}
        <Spacer x={1.5} />
      </StyledDriverCard>
      <Spacer y={1} />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  background-color: ${colors["colors/button/secondary/hover"]};
  border-radius: 0.5rem;
`;

const StyledDriverCard = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDriver = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  ${typographies["Body/S"]};
`;

const StyledBadge = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;
