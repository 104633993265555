// To adapt manually when needed
export const typographies = {
  "Body/XL":
    "font-size: 1rem;\n  font-family: Inter;\n  font-weight: 500;\n  line-height: 1.0625rem;\n",
  "Body/L":
    "font-size: 0.875rem;\n  font-family: Inter;\n  font-weight: 600;\n  line-height: 1.0625rem;\n",
  "Body/M":
    "font-size: 0.875rem;\n  font-family: Inter;\n  font-weight: 450;\n  line-height: 1.0625rem;\n",
  "Body/S":
    "font-size: 0.875rem;\n  font-family: Inter;\n  font-weight: 400;\n  line-height: 1.0625rem;\n",
  "Body/XS":
    "font-size: 0.875rem;\n  font-family: Inter;\n  font-weight: 250;\n  line-height: 1.0625rem;\n",
  "Body/XXS":
    "font-size: 0.75rem;\n  font-family: Inter;\n  font-weight: 350;\n  line-height: 1.0625rem;\n  letter-spacing: -0.015rem;\n",
  "Body/XXXS":
    "font-size: 0.625rem;\n  font-family: Inter;\n  font-weight: 450;\n  line-height: 1.0625rem;\n",
  "Header/H1":
    "font-size: 1.5rem;\n  font-family: Inter;\n  font-weight: 650;\n  line-height: 1.0625rem;\n",
  "Header/H2":
    "font-size: 1.125rem;\n  font-family: Inter;\n  font-weight: 650;\n  line-height: 1.0625rem;\n",
  "Header/H3":
    "font-size: 1.125rem;\n  font-family: Inter;\n  font-weight: 500;\n  line-height: 1.0625rem;\n",
  "Header/H4":
    "font-size: 1rem;\n  font-family: Inter;\n  font-weight: 500;\n  line-height: 1.0625rem;\n",
  "Header/H5":
    "font-size: 0.875rem;\n  font-family: Inter;\n  font-weight: 700;\n  line-height: 1.0625rem;\n",
  "Header/H6":
    "font-size: 0.75rem;\n  font-family: Inter;\n  font-weight: 650;\n  line-height: 1.0625rem;\n",
  "Title/H1":
    "font-size: 2.25rem;\n  font-family: Inter;\n  font-weight: 650;\n  line-height: 2rem;\n",
  "Paragraph/P1":
    "font-size: 1rem;\n  font-family: Inter;\n  font-weight: 350;\n  line-height: 1.0625rem;\n",
  "Paragraph/P2":
    "font-size: 0.75rem;\n  font-family: Inter;\n  font-weight: 500;\n  line-height: 1.0625rem;\n",
};
