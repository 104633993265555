import styled from "styled-components";
import { colors } from "../../styles/colors";
import Select from "react-select";
import { Spacer } from "../Spacer";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { capitalize } from "../../format";
import { TextCapitalized } from "../text/TextCapitalized";

export interface Option<T extends string> {
  readonly value: T;
  readonly label: string;
  readonly disabled?: boolean;
}

export interface SelectProps<T extends string> {
  label: string;
  options: Option<T>[];
  placeholder?: string;
  onChange: (selectedOption: Option<T> | null) => void;
  value: Option<T> | undefined | null;
  error?: string;
  width?: number;
  withoutSpacerBottom?: boolean;
  isMandatory?: boolean;
}

export const SelectInput = <T extends string>(props: SelectProps<T>) => {
  const { t } = useTranslation();

  const [isInputFocus, setIsInputFocus] = useState<boolean>(false);
  const handleChange = (selectedOption: Option<T> | null) => {
    props.onChange(selectedOption);
  };

  const capitalizeOptions = (options: Option<T>[]) => {
    const capitalizedOptions = options.map((option) => {
      const opt = {
        ...option,
        label: option.label?.charAt(0).toUpperCase() + option.label?.slice(1),
      };
      return opt;
    });
    return capitalizedOptions;
  };

  const capitalizeValueOption = (option: Option<T>) => {
    const capitalized = {
      ...option,
      label: option.label.charAt(0).toUpperCase() + option.label.slice(1),
    };
    return capitalized;
  };

  const customStyles = {
    menu: () => ({
      borderTop: `0.5rem solid transparent`,
    }),
    menuList: () => ({
      borderRadius: "0.5rem",
      border: `1px solid ${colors.ligthGrey}`,
      backgroundColor: "white",
      color: `${colors.grey}`,
      maxHeight: "15rem",
      overflow: "auto",
    }),
    option: (
      provided: unknown,
      state: { isFocused: boolean; isDisabled: boolean },
    ) => ({
      display: "flex",
      alignItems: "center",
      backgroundColor:
        !state.isDisabled && state.isFocused ? `${colors.background}` : "",
      fontFamily: "Inter",
      fontSize: "1rem",
      fontWeigth: "400",
      border: "1rem solid transparent",
      color: state.isDisabled ? `${colors.disabled}` : `${colors.grey}`,
      cursor: state.isDisabled ? "not-allowed" : "pointer",
    }),
    control: () => ({
      display: "flex",
      height: "3.3rem",
      borderRadius: "0.5rem",
      fontSize: "1rem",
      backgroundColor: props.error && colors.alert20,
      outline: "none",
    }),
    container: (provided: unknown, state: { isFocused: boolean }) => ({
      height: "3.3rem",
      borderRadius: "0.5rem",
      fontSize: "1rem",
      border: `1px solid ${
        props.error
          ? colors.alert80
          : state.isFocused
            ? colors.primary
            : colors.ligthGrey
      }}`,
      backgroundColor: props.error && colors.alert20,
    }),
    singleValue: () => ({
      fontFamily: "Inter",
      fontSize: "1rem",
      fontWeigth: "400",
      color: `${colors.grey}`,
    }),
    valueContainer: () => ({
      width: "100%",
      display: "flex",
      alignItems: "center",
      borderLeft: `1rem solid transparent`,
      cursor: "pointer",
    }),
    dropdownIndicator: () => ({
      display: "flex",
      alignItems: "center",
      border: `1rem solid transparent`,
      color: `${colors.contentTertiary}`,
      cursor: "pointer",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    placeholder: () => ({
      fontFamily: "Inter",
      fontSize: "1rem",
      fontWeigth: "400",
      color: `${colors.disabled}`,
    }),
  };

  return (
    <StyledContainer $width={props.width}>
      {props.label && (
        <StyledLabel>
          {" "}
          <TextCapitalized>{props.label}</TextCapitalized>
          <Spacer x={0.5} />
          {props.isMandatory && <StyledMandatory>*</StyledMandatory>}{" "}
        </StyledLabel>
      )}
      <StyledBackground
        $isError={props.error !== undefined}
        $isInputFocus={isInputFocus}
      >
        <Select<Option<T>>
          onChange={handleChange}
          options={capitalizeOptions(props.options)}
          placeholder={props.placeholder && capitalize(props.placeholder)}
          value={(props.value && capitalizeValueOption(props.value)) || null}
          isSearchable={false}
          noOptionsMessage={() =>
            capitalize(t("selectInput.noOptions.message"))
          }
          isOptionDisabled={(option) => option.disabled || false}
          onFocus={() => setIsInputFocus(true)}
          onBlur={() => setIsInputFocus(false)}
          styles={customStyles}
          menuPlacement="auto"
          menuPosition="fixed"
        />
        {props.error && (
          <div>
            <Spacer y={0.5} />
            <StyledErrorMessage>{props.error}</StyledErrorMessage>
          </div>
        )}
      </StyledBackground>
      {!props.withoutSpacerBottom && <Spacer y={1.5} />}
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{ $width: number | undefined }>`
  width: ${({ $width }) => ($width ? `${$width}rem` : "100%")};
`;

const StyledMandatory = styled.div`
  color: red;
`;

const StyledBackground = styled.div<{
  $isError: boolean;
  $isInputFocus: boolean;
}>`
  transition: all 0.4s ease-out;
  border: solid 0.25rem transparent;
  border-radius: 0.75rem;
  border-color: ${({ $isError, $isInputFocus }) =>
    !$isError && $isInputFocus && colors.primary33};
  &:hover {
    transition: all 0.4s ease-out;
    border-color: ${({ $isError }) => !$isError && colors.primary33};
  }
`;

const StyledLabel = styled.label`
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  font-family: Inter;
  color: ${colors.grey};
  border: solid 0.25rem transparent;
`;

const StyledErrorMessage = styled.div`
  color: ${colors.alertMessage};
  font-size: 1rem;
  line-height: 1rem;
  &::first-letter {
    text-transform: uppercase;
  }
`;
