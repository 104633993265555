import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { QueryStatus, authAxios } from "../utils";
import { RootState } from "../redux/store";
import { GetCompaniesApi } from "./companiesApi";

export interface CompaniesState {
  companiesListStatus: QueryStatus;
  companiesList: GetCompaniesApi[];
}

const initialState: CompaniesState = {
  companiesListStatus: "idle",
  companiesList: [],
};

export const companiesListAsync = createAsyncThunk(
  "companiesList/call",
  async () => {
    const axios = authAxios();
    const response = await axios.get<GetCompaniesApi[]>(`companies`);
    return response.data.sort((a, b) => a.name.localeCompare(b.name));
  },
);

export const companiesSlice = createSlice({
  name: "companies",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(companiesListAsync.pending, (state) => {
        state.companiesListStatus = "processing";
      })
      .addCase(companiesListAsync.fulfilled, (state, action) => {
        state.companiesList = action.payload;
        state.companiesListStatus = "success";
      })
      .addCase(companiesListAsync.rejected, (state) => {
        state.companiesListStatus = "failed";
      });
  },
});

export const selectCompaniesList = (state: RootState) =>
  state.companies.companiesList;
export const selectCompaniesListStatus = (state: RootState) =>
  state.companies.companiesListStatus;

export default companiesSlice.reducer;
