import { ChangeEvent } from "react";
import React from "react";

export interface FileInputProps {
  handleFile?: (file: File | null) => void;
  children?: string | JSX.Element | JSX.Element[];
}

export const FileInputButton = (props: FileInputProps) => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleClick = () => {
    hiddenFileInput.current && hiddenFileInput.current.click();
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target.files ? event?.target?.files[0] : null;
    props.handleFile && props.handleFile(fileUploaded);
  };

  return (
    <div onClick={handleClick}>
      <input
        type="file"
        style={{ display: "none" }}
        ref={hiddenFileInput}
        onChange={(e) => handleChange(e)}
      />
      {props.children}
    </div>
  );
};
