import styled from "styled-components";
import { colors } from "../../styles/figmaColors";
import { typographies } from "../../styles/figmaTypographies";
import { TextCapitalized } from "./text/TextCapitalized";
import { Spacer } from "./Spacer";

export interface NavigationCardProps {
  title: string;
  subtitle: string;
  element?: JSX.Element | null;
  onClick: () => void;
}

export const NavigationCard = (props: NavigationCardProps) => {
  const { title, subtitle, element, onClick } = props;
  return (
    <StyledCard onClick={onClick}>
      <Spacer y={1.5} />
      <StyledFlex>
        <Spacer x={1} />
        <StyledContent>
          <StyledColumn>
            <StyledTitle>
              <TextCapitalized>{title}</TextCapitalized>
            </StyledTitle>
            <Spacer y={0.5} />
            <StyledSubtitle>
              <TextCapitalized>{subtitle}</TextCapitalized>
            </StyledSubtitle>
          </StyledColumn>
          {element && (
            <StyledElement>
              <>{element}</>
            </StyledElement>
          )}
        </StyledContent>
        <Spacer x={1} />
      </StyledFlex>
      <Spacer y={1.5} />
    </StyledCard>
  );
};

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors["colors/button/secondary/default"]};
  border: 0.063rem solid ${colors["colors/borders/input/default"]};
  border-radius: 0.5rem;
  box-shadow: 0rem 0.25rem 0.5rem 0rem rgba(83, 86, 84, 0.05);
  &:hover {
    box-shadow: 0rem 0.25rem 1rem 0rem rgba(51, 51, 51, 0.15);
    cursor: pointer;
  }
`;

const StyledFlex = styled.div`
  display: flex;
`;

const StyledElement = styled.div`
  display: flex;
  align-self: center;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const StyledTitle = styled.div`
  ${typographies["Body/XL"]};
`;

const StyledSubtitle = styled.div`
  ${typographies["Body/XS"]};
`;
