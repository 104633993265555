import styled from "styled-components";
import { colors } from "../styles/colors";
import { Spacer } from "./Spacer";

export interface RuleMenuProps {
  sections: Array<{
    route: string;
    iconSrc: JSX.Element;
    solidIconSrc: JSX.Element;
    title: string;
  }>;
  selectedRoute?: string;
  label?: string;
  onChangeRoute: (route: string) => void;
}

interface StyledLogoContainerProps {
  $filled: boolean;
}

export const RuleMenu = (props: RuleMenuProps) => {
  const selectedIndex = props.sections.findIndex(
    (section) => section.route === props.selectedRoute
  );

  return (
    <StyledContainer>
      <Spacer y={4} />
      <StyledLabel>{props.label}</StyledLabel>
      <StyledMenu>
        {props.sections.map((section, index) => (
          <StyledIconContainer key={section.route}>
            <StyledIconBar>
              <StyledIcon
                $filled={index <= selectedIndex}
                onClick={() => props.onChangeRoute(section.route)}
              >
                {index <= selectedIndex
                  ? section.iconSrc
                  : section.solidIconSrc}
                <Spacer x={1} />
              </StyledIcon>
              <StyledTitle>{section.title}</StyledTitle>
            </StyledIconBar>
            {index !== props.sections.length - 1 && (
              <StyledColumn $filled={index < selectedIndex} />
            )}
          </StyledIconContainer>
        ))}
      </StyledMenu>
    </StyledContainer>
  );
};

const StyledIconBar = styled.div`
  display: flex;
  line-height: 2rem;
  heigth: 2rem;
`;

const StyledContainer = styled.div`
  display: flex;
  max-width: 23rem;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding-left: 2rem;
`;

const StyledMenu = styled.div`
  display: flex;
  padding-left: 2rem;
  flex-direction: column;
`;

const StyledColumn = styled.div<StyledLogoContainerProps>`
  margin-left: 0.85rem;
  display: flex;
  height: 5rem;
  border: 0.15rem solid
    ${({ $filled }) => ($filled ? colors.primary : colors.white)};
  justify-content: center;
  width: 0;
`;

const StyledIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: default;
`;

const StyledIcon = styled.div<StyledLogoContainerProps>`
  width: 2rem;
  height: 2rem;
  border-radius: 1.125rem;
  line-height: 2rem;
  font-size: 1.5rem;
  color: white;
  text-align: center;
  font-family: inter;
  cursor: default;
  background-color: ${({ $filled }) =>
    $filled ? colors.primary : colors.white};
`;

const StyledLabel = styled.div`
  display: flex;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  padding-bottom: 2rem;
`;

const StyledTitle = styled.div`
  display: flex;
  padding-left: 0.75rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  align-items: center;
  color: ${colors.contentTertiary};
`;
