import { HTMLAttributes } from "react";
import styled from "styled-components";
import { colors } from "../../styles/figmaColors";
import { Spacer } from "../../components/Spacer";
import { TextCapitalized } from "../../components/text/TextCapitalized";
import { typographies } from "../../styles/figmaTypographies";
import { ClearOutlined, ErrorOutlined } from "../materialUi/materialUi";

type messageTooltipType =
  | "NEUTRAL"
  | "ERROR"
  | "WARNING"
  | "INFORMATIVE"
  | "SUCCESS";

const messageTooltipProps = {
  ERROR: {
    backgroundColor: colors["colors/system/error/error_ultraLight"],
    color: colors["colors/system/error/error_normal"],
  },
  WARNING: {
    backgroundColor: colors["colors/system/warning/warning_ultraLight"],
    color: colors["colors/system/warning/warning_normal"],
  },
  INFORMATIVE: {
    backgroundColor: colors["colors/system/informative/informative_ultraLight"],
    color: colors["colors/system/informative/informative_normal"],
  },
  SUCCESS: {
    backgroundColor: colors["colors/system/success/success_ultraLight"],
    color: colors["colors/system/success/success_normal"],
  },
  NEUTRAL: {
    backgroundColor: colors["colors/surfaces/background/background_level2"],
    color: colors["colors/text/black"],
  },
};

export interface MessageTooltipProps extends HTMLAttributes<HTMLButtonElement> {
  title: string;
  text?: string | JSX.Element | null;
  type: messageTooltipType;
  onClose?: () => void;
  button?: JSX.Element;
}

export const MessageTooltip = (props: MessageTooltipProps) => {
  const messageTooltip = messageTooltipProps[props.type];

  return (
    <StyledContainer
      $color={messageTooltip.color}
      $backgroundColor={messageTooltip.backgroundColor}
    >
      <StyledContent $color={messageTooltip.color}>
        <StyledErrorOutlinedIcon>
          <ErrorOutlined />
        </StyledErrorOutlinedIcon>
        <StyledRow>
          <Spacer x={1} />
          <StyledText>
            <StyledTitle>
              <TextCapitalized>{props.title}</TextCapitalized>
            </StyledTitle>
            {props.text && (
              <>
                <Spacer y={0.5} />
                <TextCapitalized>{props.text}</TextCapitalized>
              </>
            )}
          </StyledText>
          <Spacer x={0.25} />
        </StyledRow>
        {props.onClose && (
          <StyledClearIcon onClick={props.onClose}>
            <ClearOutlined />
          </StyledClearIcon>
        )}
      </StyledContent>
      {props.button && (
        <>
          <Spacer y={1} />
          <StyledButton>{props.button}</StyledButton>
        </>
      )}
    </StyledContainer>
  );
};

interface StyledContainerArgs {
  $color: string;
  $backgroundColor?: string;
}

const StyledButton = styled.div`
  display: flex;
  width: 100%;
`;

const StyledClearIcon = styled.div`
  & svg {
    height: 1.25rem;
    width: 1.25rem;
    fill: ${colors["colors/text/lightGrey"]};
  }
  cursor: pointer;
`;

const StyledErrorOutlinedIcon = styled.div`
  display: flex;
  align-items: start;
  & svg {
    height: 1.5rem;
    width: 1.5rem;
  }
`;

const StyledContent = styled.div<StyledContainerArgs>`
  display: flex;
  fill: ${({ $color }) => $color};
  width: 100%;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledContainer = styled.div<StyledContainerArgs>`
  display: flex;
  align-items: flex-start;
  padding: 1rem;
  border: 0.0625rem solid transparent;
  border-radius: 0.5rem;
  flex-direction: column;
  width: 100%;
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor ?? "transparent"};
  color: ${({ $color }) => $color};
`;

const StyledTitle = styled.div`
  ${typographies["Body/L"]};
  display: flex;
  align-items: center;
`;

const StyledText = styled.div`
  ${typographies["Body/S"]}
  display: flex;
  flex-direction: column;
  word-break: break-word;
`;
