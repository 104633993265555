import { useTranslation } from "react-i18next";
import { HTMLAttributes, JSX } from "react";
import styled from "styled-components";
import { typographies } from "../../../styles/figmaTypographies";
import { Spacer } from "../../components/Spacer";
import { TextCapitalized } from "../text/TextCapitalized";
import { colors } from "../../../styles/figmaColors";

export interface SideBarItemProps extends HTMLAttributes<HTMLDivElement> {
  label: string;
  icon: JSX.Element;
  version: "S" | "L";
  isSelected: boolean;
  isNotification: boolean;
}

export const SideBarItem = ({
  label,
  icon,
  version,
  isSelected,
  isNotification,
  ...divProps
}: SideBarItemProps) => {
  const { t } = useTranslation();

  return (
    <StyledContainer $version={version} $isSelected={isSelected} {...divProps}>
      <Spacer x={0.75} />
      <StyledIconContainer version={version}>{icon}</StyledIconContainer>
      <Spacer y={1} />
      {isNotification && (
        <StyledNotication>
          <StyledSideBarNotification />
        </StyledNotication>
      )}
      {version === "L" && (
        <>
          <Spacer x={0.5} />
          <TextCapitalized>
            <StyledText>{t(label)}</StyledText>
          </TextCapitalized>
        </>
      )}
    </StyledContainer>
  );
};

interface StyledContainerProps {
  $version: "S" | "L";
  $isSelected: boolean;
}

const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  align-items: center;
  width: ${({ $version }) => ($version === "S" ? "3rem" : "100%")};
  white-space: nowrap;
  border-radius: 0.25rem;
  height: 3rem;
  &:hover {
    cursor: pointer;
    background-color: #6a6a6a33; // Fadel said that he is too lazy to make a Semantic color for this
  }
  background-color: ${({ $isSelected }) => $isSelected && "#6a6a6a33"};
  & svg path {
    fill: white;
  }
`;
interface StyledIconContainerProps {
  version: "S" | "L";
}
const StyledIconContainer = styled.div<StyledIconContainerProps>`
  height: ${(props) => (props.version === "S" ? "1.5rem" : "1.25rem")};
  width: ${(props) => (props.version === "S" ? "1.5rem" : "1.25rem")};
  & svg {
    fill: white;
    width: ${(props) => (props.version === "S" ? "1.5rem" : "1.25rem")};
    height: ${(props) => (props.version === "S" ? "1.5rem" : "1.25rem")};
  }
`;

const StyledText = styled.div`
  color: ${colors["colors/text/white"]};
  ${typographies["Body/S"]};
`;

const StyledNotication = styled.div`
  height: 2rem;
`;

const StyledSideBarNotification = styled.div`
  display: flex;
  border-radius: 1rem;
  width: 0.375rem;
  height: 0.375rem;
  background-color: ${colors["colors/accent/500"]};
`;
