import styled from "styled-components";

export interface TabProps {
  children?: string | JSX.Element | JSX.Element[];
}

export const Tab = (props: TabProps) => {
  return (
    <StyledContainer>
      <StyledChildrenContainer>{props.children}</StyledChildrenContainer>
      <StyledSeparator />
    </StyledContainer>
  );
};

const StyledSeparator = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: solid #d9d9d9 1px;
`;

const StyledChildrenContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
`;

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
`;
