import { HTMLAttributes, useState } from "react";
import styled from "styled-components";
import { colors } from "../styles/colors";
import { Spacer } from "./Spacer";
export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  title: string;
  width?: number;
  height?: number;
  type?: "submit" | "button";
  disabled?: boolean;
  withoutBackground?: boolean;
  color?: string;
  borderColor?: string;
  LeftElement?:
    | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    | JSX.Element
    | null;
  RightElement?:
    | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    | JSX.Element
    | null;
  fontWeight?: number;
  fontSize?: number;
  backgroundColor?: string;
  borderRadius?: number;
  hasStrokeSvg?: boolean;
  underline?: boolean;
  colorHover?: string;
  noColorHover?: boolean;
  titleId?: string;
}

export const Button = (props: ButtonProps) => {
  const {
    title,
    width,
    height,
    type,
    disabled,
    withoutBackground,
    color,
    borderColor,
    LeftElement,
    RightElement,
    fontWeight,
    fontSize,
    backgroundColor,
    borderRadius,
    hasStrokeSvg,
    underline,
    colorHover,
    noColorHover,
    titleId,
    ...buttonProps
  } = props;
  const [isInputFocus, setIsInputFocus] = useState<boolean>(false);

  return (
    <StyledButton
      {...buttonProps}
      $width={width}
      $height={height}
      type={type || "submit"}
      $inactive={disabled}
      $withoutBackground={withoutBackground}
      $color={color}
      $borderColor={borderColor}
      $fontWeight={fontWeight}
      $fontSize={fontSize}
      $backgroundColor={backgroundColor}
      $hasStrokeSvg={hasStrokeSvg}
      $borderRadius={borderRadius}
      $underline={underline}
      $colorHover={colorHover}
      $noColorHover={noColorHover}
      $isInputFocus={isInputFocus}
      $hasElementsAround={LeftElement && RightElement ? true : false}
      onFocus={() => {
        setIsInputFocus(true);
      }}
      onBlur={(e) => {
        props.onBlur && props.onBlur(e);
        setIsInputFocus(false);
      }}
    >
      {LeftElement && (
        <>
          {LeftElement} <Spacer x={0.5} />
        </>
      )}
      <StyledTitle id={titleId}>{title}</StyledTitle>
      {RightElement && (
        <>
          {RightElement} <Spacer x={0.5} />
        </>
      )}
    </StyledButton>
  );
};

interface StyledButtonArgs {
  $width?: number;
  $height?: number;
  $inactive?: boolean;
  $withoutBackground?: boolean;
  $color?: string;
  $borderColor?: string;
  $fontWeight?: number;
  $fontSize?: number;
  $backgroundColor?: string;
  $borderRadius?: number;
  $hasStrokeSvg?: boolean;
  $underline?: boolean;
  $colorHover?: string;
  $noColorHover?: boolean;
  $isInputFocus?: boolean;
  $hasElementsAround?: boolean;
}

const StyledButton = styled.button<StyledButtonArgs>`
  & svg path {
    fill: ${({ $withoutBackground }) => $withoutBackground && colors.black};
    stroke: ${({ $withoutBackground, $hasStrokeSvg }) =>
      $hasStrokeSvg && $withoutBackground && colors.black};
  }
  &:hover {
    background-color: ${({
      $inactive,
      $withoutBackground,
      $colorHover,
      $noColorHover,
    }) =>
      $colorHover && !$inactive
        ? $colorHover
        : $colorHover && $inactive
        ? ""
        : $withoutBackground || $inactive
        ? ""
        : $noColorHover
        ? ""
        : colors.disabled};
    color: ${({ $withoutBackground }) => $withoutBackground && colors.disabled};
    & svg path {
      fill: ${({ $withoutBackground, $noColorHover }) =>
        $withoutBackground ? colors.disabled : !$noColorHover && colors.white};
      stroke: ${({ $withoutBackground, $hasStrokeSvg }) =>
        $hasStrokeSvg
          ? $withoutBackground
            ? colors.disabled
            : colors.white
          : ""};
    }
    text-decoration: ${({ $underline }) =>
      $underline ? `underline ${colors.disabled}` : ""};
  }
  &:active {
    background-color: ${({
      $inactive,
      $withoutBackground,
      $colorHover,
      $noColorHover,
    }) =>
      $withoutBackground || $inactive || $noColorHover
        ? ""
        : $colorHover
        ? $colorHover
        : `${colors.disabled80}`};
  }
  text-decoration: ${({ $underline }) => ($underline ? `underline` : "")};
  width: ${({ $width }) => ($width ? `${$width}rem` : "100%")};
  height: ${({ $height }) => ($height ? `${$height}rem` : "2.5rem")};
  cursor: ${({ $inactive }) => ($inactive ? "not-allowed" : "pointer")};
  border-radius: ${({ $borderRadius }) =>
    $borderRadius ? `${$borderRadius}rem` : "0.5rem"};
  background-color: ${({ $backgroundColor, $inactive, $withoutBackground }) =>
    $backgroundColor
      ? $backgroundColor
      : $withoutBackground
      ? "transparent"
      : $inactive
      ? colors.disabled
      : colors.black};
  color: ${({ $withoutBackground, $color }) =>
    $color ? $color : $withoutBackground ? colors.black : colors.white};
  font-family: Inter;
  text-align: center;
  font-weight: ${({ $fontWeight }) => ($fontWeight ? `${$fontWeight}` : "600")};
  font-size: ${({ $fontSize }) =>
    $fontSize ? `${$fontSize}rem` : " 0.875rem"};
  display: block;
  border: 2px solid transparent;
  border-color: ${({ $isInputFocus, $borderColor, $noColorHover }) =>
    $isInputFocus && !$noColorHover
      ? colors.primary80
      : $borderColor
      ? $borderColor
      : "none"};
  display: flex;
  align-items: center;
  justify-content: ${({ $hasElementsAround }) =>
    $hasElementsAround ? "space-between" : "center"};
  &:focus {
    outline: none;
  }
`;

const StyledTitle = styled.div`
  &::first-letter {
    text-transform: uppercase;
  }
`;
