import styled from "styled-components";
import { ToastContainer as RawToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactNode } from "react";
import { Spacer } from "./Spacer";

export interface ToastValidProps {
  text?: string;
  icon?: ReactNode;
}

export const Toast = (props: ToastValidProps) => (
  <StyledContainer>
    <StyledContent>
      <StyledIcon>{props.icon}</StyledIcon>
      <Spacer x={1} />
      <StyledText>{props.text}</StyledText>
    </StyledContent>
  </StyledContainer>
);

export const ToastContainer = () => {
  return (
    <RawToastContainer
      position="top-center"
      autoClose={2000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      limit={1}
      closeButton={false}
    />
  );
};

const StyledContainer = styled.div`
  display: flex;
  max-width: fit-content;
  align-items: flex-start;
  flex-direction: column;
`;

const StyledIcon = styled.div`
  font-weight: medium;
  & svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const StyledText = styled.div`
  font-size: 0.875rem;
  font-family: Inter;
  &::first-letter {
    text-transform: uppercase;
  }
  cursor: default;
`;

const StyledContent = styled.div`
  display: flex;
  align-items: center;
`;
